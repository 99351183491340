import deleteAttributeApi from "../../processes/api/attributes/deleteAttributeApi";
import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import {
  addOrUpdateAttribute,
  changeShowModal,
  deleteAttribute,
} from "../../redux/actions";
import { attributesNamesT } from "../../redux/reducers/types";
import { optionsTag } from "../../utils/constants";
import { deleteAttributeInState } from "./deleteAttributeInState";

type deleteAttributeEventT = {
  id: number;
  name: string;
  enabled: boolean;
  apiName?: attributesNamesT;
  dispatch: any;
  nameAttr: attributesNamesT;
};
export const deleteAttributeEvent = async ({
  id,
  dispatch,
  nameAttr,
}: deleteAttributeEventT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
  const updatedAttr = await deleteAttributeApi({
    id: id,
    url: optionsTag[nameAttr].delete.url,
    request: optionsTag[nameAttr].delete.request,
  });
  if (updatedAttr) {
    dispatch(
      deleteAttribute({
        attrId: updatedAttr.id,
        attrName: nameAttr,
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};
export const deleteAttributeEventOption = async ({
  id,
  dispatch,
  apiName,
  nameAttr,
}: deleteAttributeEventT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
  const updatedAttr = await deleteAttributeApi({
    id: id,
    url: optionsTag[apiName!].delete.url,
    request: optionsTag[apiName!].delete.request,
  });
  if (updatedAttr) {
    dispatch(
      deleteAttribute({
        attrId: updatedAttr.id,
        attrName: nameAttr,
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};