import { useEffect } from "react";
import { attributesNamesT, rowsT } from "../../../redux/reducers/types";
import getAttributesApi from "../../../processes/api/attributes/getAttributesApi";
import { optionsTag } from "../../../utils/constants";
import { setAttribute } from "../../../redux/actions";

export const useAttributesWithApi = (
  idProject: string,
  idTab: attributesNamesT,
  project: any,
  dispatch: any
) => {
  useEffect(() => {
    async function getAttrWithApi() {
      if (project) {
        const keys: any = Object.keys(project.attributesList);
        let allAttrIsCaching = false;
        keys.forEach((k: any) => {
          if (project.attributesList[k].cache === true) {
            allAttrIsCaching = true;
          }
        });

        if (idTab && idProject && project && !allAttrIsCaching) {
          const ruleForCollections =
            project.attributesList.options.count === 0 &&
            project.attributesList.stages.count === 0;

          keys.forEach(async (key: attributesNamesT) => {
            const check =
              (key === "collections" && !ruleForCollections) ||
              key !== "collections";

            if (check) {
              const attr: any = project.attributesList[key];
              let info;
              if (attr && !attr.cache) {
                if (key === "options") {
                  const res: any = [];
                  const materialOptions = await getAttributesApi({
                    url: optionsTag[key].get.url,
                    request: optionsTag[key].get.request,
                    filter: `?projectId=${idProject}&page=1&pageSize=500`,
                  });
                  const geometryOptions = await getAttributesApi({
                    url: optionsTag["geometryOptions"].get.url,
                    request: optionsTag["geometryOptions"].get.request,
                    filter: `?projectId=${idProject}&page=1&pageSize=500`,
                  });
                  materialOptions.rows.map((item: any) => {
                    res.push({
                      ...item,
                      typeValue: "material",
                    });
                  });
                  geometryOptions.rows.map((item: any) => {
                    res.push({
                      ...item,
                      typeValue: "geometry",
                    });
                  });
                  info = {
                    count: materialOptions.count + geometryOptions.count,
                    rows: res,
                  };
                } else {
                  info = await getAttributesApi({
                    url: optionsTag[key].get.url,
                    request: optionsTag[key].get.request,
                    filter: `?projectId=${idProject}&page=1&pageSize=500`,
                  });
                }

                if (info) {
                  const count = key === "products" ? info.length : info.count;
                  const rows = key === "products" ? info : info.rows;
                  const updatedRows: rowsT[] = [];
                  rows.map((row: any) => {
                    updatedRows.push({
                      ...row,
                      cache: false,
                      items: [],
                    });
                  });

                  dispatch(
                    setAttribute({
                      projectId: Number(idProject),
                      attrName: key,
                      attrValues: {
                        count: count,
                        cache: true,
                        rows: updatedRows,
                      },
                    })
                  );
                }
              }
            }
          });
        }
      }
    }

    getAttrWithApi();
  }, [idProject, idTab, project, dispatch]);
};
