import { useDispatch, useSelector } from "react-redux";
import { ArrowLink } from "../../../../assets/svg/ArrowLink";
import { Close } from "../../../../assets/svg/Close";
import { CloseSecond } from "../../../../assets/svg/CloseSecond";
import { changeShowModal, setJobs } from "../../../../redux/actions";
import { RenderItem } from "../RenderItem/RenderItem";
import s from "./RenderProduct.module.scss";
import { useEffect } from "react";
import getRenderJobsApi from "../../../../processes/api/products/getRenderJobsApi";
import { getJobsByProductId } from "../../../../redux/selectors/selectors";
function formatDate(isoString: any) {
  // Создаем объект Date из строки в формате ISO 8601
  const date = new Date(isoString);

  // Функция для добавления ведущего нуля
  const pad = (num: any) => num.toString().padStart(2, "0");

  // Получаем компоненты даты
  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1); // Месяцы начинаются с 0
  const year = pad(date.getUTCFullYear() % 100); // Получаем последние 2 цифры года
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());

  // Форматируем дату и время
  return `${day}.${month}.${year} ${hours}:${minutes}`;
}
export const RenderProduct = ({ activeModal, setActiveModal, id }: any) => {
  const dispatch = useDispatch();
  const isActive = activeModal === "render";

  const allJobs = useSelector(getJobsByProductId(id));
  async function getJobs() {
    const jobs = await getRenderJobsApi(id);
    dispatch(
      setJobs({
        jobs: jobs.data.rows,
        productId: id,
      })
    );
  }

  useEffect(() => {
    if (isActive) {
      getJobs();
    }
  }, [activeModal]);
  return (
    <div
      className={`${s.wrapper} ${activeModal !== false && s.hide} ${
        isActive && s.active
      }`}
    >
      <div className={s.head}>
        <div className={s.title}>Bulk rendering tool</div>
        <div
          className={`${s.btn} ${isActive && s.close}`}
          onClick={() => {
            if (isActive) {
              setActiveModal(false);
            } else {
              setActiveModal("render");
            }
          }}
        >
          {isActive ? (
            <>
              <CloseSecond />
              <span>Close</span>
            </>
          ) : (
            <>
              <ArrowLink />
              <span>Open</span>
            </>
          )}
        </div>
      </div>
      {isActive && (
        <div className={s.content}>
          {allJobs &&
            allJobs.map((job: any, index: number) => {
              let options = ``;
              let variants = ``;

              job.config.availableGeometryOptions.map((item: any) => {
                let localVariants = ``;
                options += ` | ${item.proxyName ? item.proxyName : item.name}`;

                item.variants.map((i: any, index: number) => {
                  localVariants += `${i.name}, `;
                });
                variants += ` | ${localVariants.slice(0, -2)}`;
              });
              job.config.availableOptions.map((item: any) => {
                let localVariants = ``;
                options += ` | ${item.proxyName ? item.proxyName : item.name}`;

                item.variants.map((i: any, index: number) => {
                  localVariants += `${i.name}, `;
                });
                variants += ` | ${localVariants.slice(0, -2)}`;
              });
              job.config.availableStages.map((item: any) => {
                let localVariants = ``;
                options += ` | ${item.proxyName ? item.proxyName : item.name}`;

                item.cameras.map((i: any, index: number) => {
                  if (index === item.cameras.length) {
                    localVariants += `${i.name}`;
                  } else {
                    localVariants += `${i.name}, `;
                  }
                });
                variants += ` | ${localVariants.slice(0, -2)}`;
              });
              return (
                <RenderItem
                  key={`job_${index}`}
                  combination={job.configurationsTotal}
                  created={formatDate(job.createdAt)}
                  id={job.id}
                  options={options.substring(2)}
                  status="init"
                  variants={variants.substring(2)}
                />
              );
            })}
        </div>
      )}
      {isActive && (
        <div
          className={s.footer}
          onClick={() =>
            dispatch(
              changeShowModal({
                nameModal: "createRender",
                stateModal: true,
                otherParams: { id },
              })
            )
          }
        >
          <div className={s.create}>Create render job1</div>
        </div>
      )}
    </div>
  );
};
