import { deleteAttributeEvent } from "../../../features/functions/deleteAttributeEvent";
import { deleteItemsAttributeEvent } from "../../../features/functions/deleteItemsAttributeEvent";
import { duplicateAttributeEvent } from "../../../features/functions/duplicateAttributeEvent";
import { duplicateItemsAttributeEvent } from "../../../features/functions/duplicateItemsAttributeEvent";
import { loadImageFromURL } from "../../../features/functions/loadImageFromURL";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";
import { toggleEnableAttributeOption } from "../../../features/functions/toggleEnableAttribute";
import { toggleEnableItemsAttribute } from "../../../features/functions/toggleEnableItemsAttribute";
import { changeShowModal } from "../../../redux/actions";
import { groupNamesT, optionsTag } from "../../../utils/constants";

const handleEnableAttribute = async ({ optionData, dispatch }: any) => {
  const keyName: any =
    optionData.typeValue === "material" ? "options" : "geometryOptions";
  await toggleEnableAttributeOption({
    id: optionData.id,
    name: optionData.name,
    mount: optionData.mountPoint,
    nameAttr: "options",
    apiName: keyName,
    enabled: !optionData.enabled,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};
const handleAddItemAttribute = async ({ optionData, dispatch }: any) => {
  const keyName: groupNamesT =
    optionData.typeValue === "material" ? "variants" : "geometryVariants";
  dispatch(
    changeShowModal({
      nameModal: "addVariant",
      stateModal: true,
      otherParams: {
        attrId: optionData.id,
        typeValue: keyName,
        name: "",
        image: undefined,
      },
    })
  );
};

const handleEnableItemAttribute = async ({
  item,
  optionData,
  dispatch,
}: any) => {
  const keyName: any =
    optionData.typeValue === "material" ? "variants" : "geometryVariants";
  await toggleEnableItemsAttribute({
    id: item.id,
    attrId: optionData.id,
    name: item.name,
    nameAttr: "options",
    tagName: keyName,
    enabled: !item.enabled,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};
const handleDeleteItemAttribute = async ({
  item,
  optionData,
  dispatch,
}: any) => {
  const keyName: any =
    optionData.typeValue === "material" ? "variants" : "geometryVariants";
  await deleteItemsAttributeEvent({
    id: optionData.id,
    itemId: item.id,
    attrName: "options",
    attrTag: keyName,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};

const handleDuplicateItemAttribute = async ({
  item,
  optionData,
  dispatch,
}: any) => {
  const keyName: groupNamesT =
    optionData.typeValue === "material" ? "variants" : "geometryVariants";
  const optionIdName = keyName === "variants" ? "optionId" : "geometryOptionId";
  await duplicateItemsAttributeEvent({
    name: item.name,
    itemId: item.id,
    attrId: optionData.id,
    count: optionData.items.length,
    otherValues: {
      optionId: item[optionIdName],
      image: await loadImageFromURL(item.image),
    },
    nameAttr: "options",
    url: optionsTag[keyName].create.url,
    request: optionsTag[keyName].create.request,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};
const handleEditAttribute = async ({ item, optionData, dispatch }: any) => {
  const keyName: groupNamesT =
    optionData.typeValue === "material" ? "variants" : "geometryVariants";
  const optionIdName = keyName === "variants" ? "optionId" : "geometryOptionId";

  dispatch(
    changeShowModal({
      nameModal: "addVariant",
      stateModal: true,
      otherParams: {
        attrId: item[optionIdName],
        name: item.name,
        typeValue: keyName,
        image: item.image,
        id: item.id,
      },
    })
  );
};

export {
  handleEnableAttribute,
  handleAddItemAttribute,
  handleEnableItemAttribute,
  handleDeleteItemAttribute,
  handleDuplicateItemAttribute,
  handleEditAttribute,
};
