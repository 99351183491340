import { apiRoutesValueT } from "../constants";

export type productsRoutesNameT =
  | "GET_PRODUCTS"
  | "UPDATE_PRODUCT"
  | "CREATE_PRODUCT"
  | "DELETE_PRODUCT"
  | "RETRIEVE_PRODUCT"
  | "MASS_RENDER"
  | "GET_JOBS"
  | "START_RENDER";
type productsRoutesT = Record<productsRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const productsRoutes: productsRoutesT = {
  GET_PRODUCTS: {
    url: `${api}/products`,
    request: "get",
  },
  CREATE_PRODUCT: {
    url: `${api}/products`,
    request: "post",
  },
  UPDATE_PRODUCT: {
    url: `${api}/products`,
    request: "put",
  },
  DELETE_PRODUCT: {
    url: `${api}/products`,
    request: "delete",
  },
  RETRIEVE_PRODUCT: {
    url: `${api}/products`,
    request: "get",
  },
  MASS_RENDER: {
    url: `${api}/massrender/jobs`,
    request: "post",
  },
  START_RENDER: {
    url: `${api}/massrender/jobs`,
    request: "post",
  },
  GET_JOBS: {
    url: `${api}/massrender/jobs`,
    request: "get",
  },
};
