import { changeShowModal } from "../../../redux/actions";

const handleEditAttribute = async ({ item, dispatch, projectId }: any) => {
  dispatch(
    changeShowModal({
      nameModal: "addEnvironment",
      stateModal: true,
      otherParams: {
        prjectId: projectId,
        name: item.name,
        file: item.resource,
        mount: item.mountPoint,
        id: item.id,
      },
    })
  );
};

export { handleEditAttribute };
