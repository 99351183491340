import { FC } from "react";

export const Table: FC = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84769 1.71694C9.8105 1.67971 9.76633 1.65018 9.71771 1.63004C9.66909 1.60991 9.61698 1.59957 9.56436 1.59961H7.16436C7.05827 1.59961 6.95653 1.64175 6.88151 1.71677C6.8065 1.79178 6.76436 1.89352 6.76436 1.99961C6.76436 2.1057 6.72221 2.20744 6.6472 2.28245C6.57218 2.35747 6.47044 2.39961 6.36436 2.39961C6.31183 2.39961 6.25981 2.40996 6.21128 2.43006C6.16275 2.45016 6.11866 2.47962 6.08151 2.51677C6.04437 2.55391 6.01491 2.59801 5.9948 2.64654C5.9747 2.69507 5.96436 2.74708 5.96436 2.79961V3.19961C5.96436 3.3057 6.0065 3.40744 6.08151 3.48245C6.15653 3.55747 6.25827 3.59961 6.36436 3.59961H10.3644C10.4704 3.59961 10.5722 3.55747 10.6472 3.48245C10.7222 3.40744 10.7644 3.3057 10.7644 3.19961V2.79961C10.7644 2.74708 10.754 2.69507 10.7339 2.64654C10.7138 2.59801 10.6843 2.55391 10.6472 2.51677C10.6101 2.47962 10.566 2.45016 10.5174 2.43006C10.4689 2.40996 10.4169 2.39961 10.3644 2.39961C10.2583 2.39961 10.1565 2.35747 10.0815 2.28245C10.0065 2.20744 9.96436 2.1057 9.96436 1.99961C9.96444 1.89365 9.92248 1.79199 9.84769 1.71694Z"
        fill="#31B27E"
      />
      <path
        d="M5.23245 2.40039H4.76445C4.44619 2.40039 4.14097 2.52682 3.91592 2.75186C3.69088 2.97691 3.56445 3.28213 3.56445 3.60039V13.2004C3.56445 13.5187 3.69088 13.8239 3.91592 14.0489C4.14097 14.274 4.44619 14.4004 4.76445 14.4004H11.9645C12.2827 14.4004 12.5879 14.274 12.813 14.0489C13.038 13.8239 13.1645 13.5187 13.1645 13.2004V3.60039C13.1645 3.28213 13.038 2.97691 12.813 2.75186C12.5879 2.52682 12.2827 2.40039 11.9645 2.40039H11.4965C11.5405 2.52572 11.5645 2.66039 11.5645 2.80039V3.20039C11.5645 3.51865 11.438 3.82388 11.213 4.04892C10.9879 4.27396 10.6827 4.40039 10.3645 4.40039H6.36445C6.04619 4.40039 5.74097 4.27396 5.51592 4.04892C5.29088 3.82388 5.16445 3.51865 5.16445 3.20039V2.80039C5.16445 2.66439 5.18712 2.52906 5.23245 2.40039Z"
        fill="#31B27E"
      />
    </svg>
  );
};
