import { useEffect } from "react";
import getAttributesApi from "../../../processes/api/attributes/getAttributesApi";
import { groupNamesT, optionsTag } from "../../../utils/constants";
import { setItemsAttribute } from "../../../redux/actions";

export const getActualData = async (optionData: any, dispatch: any) => {
  if (optionData && !optionData.cache) {
    const keyName: groupNamesT =
      optionData.typeValue === "material" ? "variants" : "geometryVariants";
    const keyId: any =
      optionData.typeValue === "material" ? "optionId" : "geometryOptionId";
    const info = await getAttributesApi({
      url: optionsTag[keyName].get.url,
      request: optionsTag[keyName].get.request,
      filter: `?${keyId}=${optionData.id}&page=1&pageSize=500`,
    });
    if (info) {
      dispatch(
        setItemsAttribute({
          itemId: optionData.id,
          typeValue: optionData.typeValue,
          attrName: "options",
          attrValues: info.rows,
        })
      );
    }
  }
};
export const useUpdateActualData = ({ optionData, dispatch }: any) => {
  useEffect(() => {
    getActualData(optionData, dispatch);
  }, []);
};
