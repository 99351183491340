import { FC, useEffect, useState } from "react";
import s from "./RenderOption.module.scss";
import { CloseSecond } from "../../../../../assets/svg/CloseSecond";

type RenderOptionT = {
  item: any;
  index: any;
  activeLayers: any;
  setActiveLayers: any;
};
export const RenderOption: FC<RenderOptionT> = ({
  item,
  index,
  activeLayers,
  setActiveLayers,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={s.selectItem}>
      <div className={s.headSelect} onClick={() => setIsOpen(!isOpen)}>
        <div className={s.name}>
          {item.proxyName ? item.proxyName : item.name}
        </div>
        <div className={s.add}>
          <CloseSecond />
          <span>Select values</span>
        </div>
      </div>
      <div className={`${s.optionSelect} ${isOpen && s.active}`}>
        {item[item.typeRows].map((i: any, indx: number) => {
          const isItemActive =
            item.activeItems && item.activeItems.includes(i.id);
          return (
            <div
              className={`${s.opt} ${isItemActive && s.disable}`}
              key={`optionSelect_${index}_${indx}`}
              onClick={() => {
                const updLayers = activeLayers.map((layer: any) => {
                  const activeLayer = layer;
                  const activeItems = activeLayer.activeItems
                    ? activeLayer.activeItems
                    : [];
                  if (
                    layer.id === item.id &&
                    layer.typeRows === item.typeRows
                  ) {
                    activeLayer.activeItems = [...activeItems, i.id];
                  }
                  return activeLayer;
                });
                setActiveLayers(updLayers);
              }}
            >
              {i.name}
            </div>
          );
        })}
      </div>
      <div className={s.optionsList}>
        {item.activeItems &&
          item.activeItems.map((i: any, indx: number) => {
            const hideClickEvent = item.activeItems.length > 1;
            const actualRow = item[item.typeRows].find(
              (it: any) => it.id === i
            );
            return (
              <div
                className={`${s.option} ${!hideClickEvent && s.disable}`}
                key={`optionsList_${index}_${indx}`}
                onClick={() => {
                  const updLayers = activeLayers.map((layer: any) => {
                    const activeLayer = layer;

                    if (
                      layer.id === item.id &&
                      layer.typeRows === item.typeRows
                    ) {
                      const filtered = activeLayer.activeItems.filter(
                        (l: any) => l !== i
                      );
                      activeLayer.activeItems = filtered;
                    }
                    return activeLayer;
                  });
                  setActiveLayers(updLayers);
                }}
              >
                <span>
                  {item[item.typeRows].find((it: any) => it.id === i).name}
                </span>
                {hideClickEvent && <CloseSecond />}
              </div>
            );
          })}
      </div>
    </div>
  );
};
