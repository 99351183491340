import { TYPE_REDUCER } from "../../utils/constants";
import {
  NameModals,
  attributeItemsNamesT,
  attributesNamesT,
  projectsListT,
} from "../reducers/types";

type changeShowModalT = {
  nameModal: NameModals;
  stateModal: Boolean;
  otherParams?: any;
  [key: string]: any;
};
export const changeShowModal = ({ ...data }: changeShowModalT) => {
  return {
    type: TYPE_REDUCER.SET_MODAL,
    payload: data,
  };
};

type setAllProjectsT = {
  projects: projectsListT[];
};
export const setAllProjects = ({ ...data }: setAllProjectsT) => {
  return {
    type: TYPE_REDUCER.ADD_PROJECTS,
    payload: data,
  };
};

type addNewProjectT = {
  id: number;
  name: string;
};
export const addNewProject = ({ ...data }: addNewProjectT) => {
  return {
    type: TYPE_REDUCER.ADD_NEW_PROJECT,
    payload: data,
  };
};
type deleteProjectT = {
  id: number;
};
export const deleteProject = ({ ...data }: deleteProjectT) => {
  return {
    type: TYPE_REDUCER.DELETE_PROJECT,
    payload: data,
  };
};
type updateProjectT = {
  id: number;
  name: string;
};
export const updateProject = ({ ...data }: updateProjectT) => {
  return {
    type: TYPE_REDUCER.UPDATE_PROJECTS,
    payload: data,
  };
};

type setActiveProjectT = {
  id: number | undefined;
};
export const setActiveProject = ({ ...data }: setActiveProjectT) => {
  return {
    type: TYPE_REDUCER.SET_ACTIVE_PROJECT,
    payload: data,
  };
};

type setActiveTabT = {
  id: string;
};

export const setActiveTab = ({ ...data }: setActiveTabT) => {
  return {
    type: TYPE_REDUCER.SET_ACTIVE_TAB,
    payload: data,
  };
};

type setAttributeT = {
  attrName: attributesNamesT;
  attrValues: any;
  projectId: number;
};
export const setAttribute = ({ ...data }: setAttributeT) => {
  return {
    type: TYPE_REDUCER.SET_ATTRIBUTE,
    payload: data,
  };
};

type addOrUpdateAttributeT = {
  attrName: attributesNamesT | attributeItemsNamesT;
  attrValue: any;
  attrId: any;
  type: "add" | "update";
};
export const addOrUpdateAttribute = ({ ...data }: addOrUpdateAttributeT) => {
  return {
    type: TYPE_REDUCER.ADD_OR_UPDATE_ATTRIBUTE,
    payload: data,
  };
};

type deleteAttributeT = {
  attrName: attributesNamesT;
  attrId: any;
};
export const deleteAttribute = ({ ...data }: deleteAttributeT) => {
  return {
    type: TYPE_REDUCER.DELETE_ATTRIBUTE,
    payload: data,
  };
};

type setItemsAttributeT = {
  attrName: attributesNamesT;
  attrValues: any;
  typeValue?: any;
  itemId: number;
};
export const setItemsAttribute = ({ ...data }: setItemsAttributeT) => {
  return {
    type: TYPE_REDUCER.SET_ITEMS_ATTRIBUTE,
    payload: data,
  };
};

type setJobsT = {
  jobs: any[];
  productId: any;
};
export const setJobs = ({ ...data }: setJobsT) => {
  return {
    type: TYPE_REDUCER.SET_JOBS,
    payload: data,
  };
};
type addOrUpdateItemsAttributeT = {
  attrName: attributesNamesT;
  attrValue: any;
  itemId: number;
  attrId: any;
  typeValue?: any;
  type: "add" | "update";
};
export const addOrUpdateItemsAttribute = ({
  ...data
}: addOrUpdateItemsAttributeT) => {
  return {
    type: TYPE_REDUCER.ADD_OR_UPDATE_ITEMS_ATTRIBUTE,
    payload: data,
  };
};

type deleteItemAttributeT = {
  attrName: attributesNamesT | attributeItemsNamesT;
  attrId: any;
  itemId: any;
};
export const deleteItemAttribute = ({ ...data }: deleteItemAttributeT) => {
  return {
    type: TYPE_REDUCER.DELETE_ITEM_ATTRIBUTE,
    payload: data,
  };
};

type setCategoriesT = {
  categories: any;
};
export const setCategories = ({ ...data }: setCategoriesT) => {
  return {
    type: TYPE_REDUCER.SET_CATEGORIES,
    payload: data,
  };
};
