import { apiRoutesValueT } from "../constants";

export type loginRoutesNameT = "LOGIN" | "REFRESH";
type loginRoutesT = Record<loginRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const loginRoutes: loginRoutesT = {
  LOGIN: {
    url: `${api}/login`,
    request: "post",
  },
  REFRESH: {
    url: `${api}/refresh`,
    request: "post",
  },
};

