import { FC, useState } from "react";
import s from "./CoodrinateCameraComponent.module.scss";
import { InputWrapper } from "../../../../../shared/InputWrapper/InputWrapper";
type CoodrinateCameraComponentT = {
  setValues: any;
  values: any;
};
type setCoordsFuncT = {
  type: "rotation" | "position" | "productRotation";
  xyz: "x" | "y" | "z";
  value: any;
};
export const CoodrinateCameraComponent: FC<CoodrinateCameraComponentT> = ({
  setValues,
  values,
}) => {
  const [coords, setCoords] = useState({
    position: values.position
      ? values.position
      : {
          x: "",
          y: "",
          z: "",
        },
    rotation: values.rotation
      ? values.rotation
      : {
          x: "",
          y: "",
          z: "",
        },
    productRotation: values.productRotation
      ? values.productRotation
      : {
          x: "",
          y: "",
          z: "",
        },
  });

  function setCoordsFunc({ type, xyz, value }: setCoordsFuncT) {
    setCoords({
      ...coords,
      [type]: {
        ...coords[type],
        [xyz]: value,
      },
    });
    setValues({
      ...values,
      [type]: {
        ...coords[type],
        [xyz]: value,
      },
    });
  }

  return (
    <div className={s.content}>
      <div className={s.wrapper}>
        <div className={s.coord}>
          <div className={s.coordTitle}>
            Position
            <span className={s.req}>*</span>
          </div>
          <div className={s.coordWrapper}>
            <InputWrapper
              setValue={(value: any) => {
                setCoordsFunc({ type: "position", xyz: "x", value });
              }}
              value={coords.position.x}
              type="text"
              isRequired={true}
              addClassName={s.position}
              placeholder="X"
            />
            <InputWrapper
              setValue={(value: any) =>
                setCoordsFunc({ type: "position", xyz: "y", value })
              }
              value={coords.position.y}
              type="text"
              isRequired={true}
              addClassName={s.position}
              placeholder="Y"
            />
            <InputWrapper
              setValue={(value: any) =>
                setCoordsFunc({ type: "position", xyz: "z", value })
              }
              value={coords.position.z}
              type="text"
              isRequired={true}
              addClassName={s.position}
              placeholder="Z"
            />
          </div>
        </div>
        <div className={s.coord}>
          <div className={s.coordTitle}>
            Rotation
            <span className={s.req}>*</span>
          </div>
          <div className={s.coordWrapper}>
            <InputWrapper
              setValue={(value: any) =>
                setCoordsFunc({ type: "rotation", xyz: "x", value })
              }
              value={coords.rotation.x}
              type="text"
              isRequired={true}
              addClassName={s.rotation}
              placeholder="X"
            />
            <InputWrapper
              setValue={(value: any) =>
                setCoordsFunc({ type: "rotation", xyz: "y", value })
              }
              value={coords.rotation.y}
              type="text"
              isRequired={true}
              addClassName={s.rotation}
              placeholder="Y"
            />
            <InputWrapper
              setValue={(value: any) =>
                setCoordsFunc({ type: "rotation", xyz: "z", value })
              }
              value={coords.rotation.z}
              type="text"
              isRequired={true}
              addClassName={s.rotation}
              placeholder="Z"
            />
          </div>
        </div>
      </div>
      <div className={`${s.coord} ${s.singleCoord}`}>
        <div className={s.coordTitle}>
          Product Rotation
          <span className={s.req}>*</span>
        </div>
        <div className={s.coordWrapper}>
          <InputWrapper
            setValue={(value: any) =>
              setCoordsFunc({ type: "productRotation", xyz: "x", value })
            }
            value={coords.productRotation.x}
            type="text"
            isRequired={true}
            addClassName={s.rotation}
            placeholder="X"
          />
          <InputWrapper
            setValue={(value: any) =>
              setCoordsFunc({ type: "productRotation", xyz: "y", value })
            }
            value={coords.productRotation.y}
            type="text"
            isRequired={true}
            addClassName={s.rotation}
            placeholder="Y"
          />
          <InputWrapper
            setValue={(value: any) =>
              setCoordsFunc({ type: "productRotation", xyz: "z", value })
            }
            value={coords.productRotation.z}
            type="text"
            isRequired={true}
            addClassName={s.rotation}
            placeholder="Z"
          />
        </div>
      </div>
    </div>
  );
};
