import { FC } from "react";

export const Logout: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.371C9 16.2041 9.06507 16.0441 9.1809 15.9262C9.29674 15.8082 9.45384 15.7419 9.61765 15.7419H14.5588C14.6134 15.7419 14.6658 15.7198 14.7044 15.6805C14.743 15.6412 14.7647 15.5879 14.7647 15.5323V5.46774C14.7647 5.41213 14.743 5.3588 14.7044 5.31948C14.6658 5.28016 14.6134 5.25806 14.5588 5.25806H9.61765C9.45384 5.25806 9.29674 5.19179 9.1809 5.07383C9.06507 4.95586 9 4.79586 9 4.62903C9 4.4622 9.06507 4.30221 9.1809 4.18424C9.29674 4.06627 9.45384 4 9.61765 4H14.5588C15.3544 4 16 4.65755 16 5.46774V15.5323C16 15.9215 15.8482 16.2949 15.5779 16.5701C15.3076 16.8454 14.941 17 14.5588 17H9.61765C9.45384 17 9.29674 16.9337 9.1809 16.8158C9.06507 16.6978 9 16.5378 9 16.371Z"
        fill="#6C757D"
      />
      <path
        d="M13 11.447C13 11.6712 12.913 11.8863 12.7582 12.0448C12.6034 12.2034 12.3934 12.2925 12.1744 12.2925H8.16536C8.14637 12.5935 8.12243 12.8928 8.09436 13.1938L8.06959 13.4517C8.06036 13.5488 8.02838 13.6423 7.97636 13.7242C7.92434 13.806 7.85381 13.8738 7.77076 13.9217C7.68771 13.9697 7.59458 13.9964 7.49929 13.9997C7.40399 14.0029 7.30934 13.9826 7.22336 13.9404C5.71417 13.1996 4.34793 12.1859 3.19036 10.9481L3.16559 10.9219C3.05933 10.8084 3 10.6572 3 10.5C3 10.3428 3.05933 10.1916 3.16559 10.0781L3.19036 10.0519C4.34792 8.81405 5.71416 7.80038 7.22336 7.05965C7.30934 7.01744 7.40399 6.99709 7.49929 7.00034C7.59458 7.00358 7.68771 7.03032 7.77076 7.07828C7.85381 7.12624 7.92434 7.19402 7.97636 7.27585C8.02838 7.35768 8.06036 7.45116 8.06959 7.54835L8.09436 7.80623C8.12243 8.10638 8.14637 8.40653 8.16536 8.70753H12.1744C12.3934 8.70753 12.6034 8.79661 12.7582 8.95518C12.913 9.11374 13 9.32879 13 9.55304V11.447Z"
        fill="#6C757D"
      />
    </svg>
  );
};
