import s from "./Preloader.module.scss";

const Preloader = () => {
  return (
    <div className={s.spinner}>
      <div className={s.wrap}>
        <div className={s.svg}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
          >
            <path
              d="M49.4546 95.747C49.4546 86.2469 55.7039 78.5455 65.4546 78.5455C75.2053 78.5455 81.4546 86.2469 81.4546 95.747C81.4546 105.247 66.558 128 65.4546 128C64.3511 128 49.4546 105.247 49.4546 95.747Z"
              fill="#2A8EFA"
            />
            <path
              d="M78.5459 32.253C78.5459 41.7531 72.2966 49.4545 62.5459 49.4545C52.7952 49.4545 46.5459 41.7531 46.5459 32.253C46.5459 22.7528 61.4425 0 62.5459 0C63.6493 0 78.5459 22.7528 78.5459 32.253Z"
              fill="#FF4F79"
            />
            <path
              d="M95.747 78.5453C86.2469 78.5453 78.5455 72.296 78.5455 62.5453C78.5455 52.7946 86.2469 46.5453 95.747 46.5453C105.247 46.5453 128 61.4418 128 62.5453C128 63.6487 105.247 78.5453 95.747 78.5453Z"
              fill="#37CC8F"
            />
            <path
              d="M32.253 49.4547C41.7531 49.4547 49.4545 55.704 49.4545 65.4547C49.4545 75.2054 41.7531 81.4547 32.253 81.4547C22.7528 81.4547 1.77135e-07 66.5582 1.90254e-07 65.4547C2.03374e-07 64.3513 22.7528 49.4547 32.253 49.4547Z"
              fill="#EAC159"
            />
            <path
              d="M29.5017 20.8159C32.6264 24.0133 33.1741 28.6368 30.0763 31.8066C26.9785 34.9764 22.4601 34.416 19.3354 31.2186C16.2107 28.0213 13.4596 15.521 13.8102 15.1623C14.1608 14.8036 26.377 17.6186 29.5017 20.8159Z"
              fill="#2A8EFA"
            />
            <path
              d="M29.5017 104.275C32.6264 101.078 33.1741 96.4541 30.0763 93.2843C26.9785 90.1145 22.4601 90.6749 19.3354 93.8723C16.2107 97.0697 13.4596 109.57 13.8102 109.929C14.1608 110.287 26.377 107.472 29.5017 104.275Z"
              fill="#37CC8F"
            />
            <path
              d="M95.5877 20.8159C92.463 24.0133 91.9153 28.6368 95.0131 31.8066C98.1108 34.9764 102.629 34.416 105.754 31.2186C108.879 28.0213 111.63 15.521 111.279 15.1623C110.929 14.8036 98.7124 17.6186 95.5877 20.8159Z"
              fill="#EAC159"
            />
            <path
              d="M95.5877 104.275C92.463 101.078 91.9153 96.4541 95.0131 93.2843C98.1108 90.1145 102.629 90.6749 105.754 93.8723C108.879 97.0697 111.63 109.57 111.279 109.929C110.929 110.287 98.7124 107.472 95.5877 104.275Z"
              fill="#FF4F79"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
