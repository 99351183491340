import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalCreateRender.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "../../../assets/svg/Close";

import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import { getItemAttributeById } from "../../../redux/selectors/selectors";
import { CloseSecond } from "../../../assets/svg/CloseSecond";
import { RenderOption } from "./components/RenderOption/RenderOption";
import massRenderApi from "../../api/products/massRenderApi";
import getRenderJobsApi from "../../api/products/getRenderJobsApi";
import { setJobs } from "../../../redux/actions";
import { CheckboxWrapper } from "../../../shared/CheckboxWrapper/CheckboxWrapper";

type ModalCreateRenderT = {
  closeFunc: any;
  data: any;
};
export const ModalCreateRender: FC<ModalCreateRenderT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const productData = useSelector(
    getItemAttributeById({ name: "products", id: data.id })
  );
  const [allData, setAllData]: any = useState([]);
  const [combination, setCombination]: any = useState(0);
  const [activeLayers, setActiveLayers]: any = useState([]);
  const [rewrite, setRewrite]: any = useState(false);

  useEffect(() => {
    const updLayer = allData.map((layer: any) => {
      return {
        ...layer,
        activeItems: layer[layer.typeRows].map((row: any) => row.id),
      };
    });
    setActiveLayers(updLayer);
  }, [allData]);
  useEffect(() => {
    if (productData) {
      setAllData([
        ...productData.availableGeometryOptions.map((item: any) => ({
          ...item,
          typeRows: "variants",
          category: "geometryOptions",
        })),
        ...productData.availableOptions.map((item: any) => ({
          ...item,
          typeRows: "variants",
          category: "options",
        })),
        ...productData.availableStages.map((item: any) => ({
          ...item,
          typeRows: "cameras",
          category: "stages",
        })),
      ]);
    }
  }, [productData]);

  useEffect(() => {
    if (activeLayers.length >= 1) {
      let cameraCount = 0;
      let variantsCount = 1;
      const options = activeLayers.filter(
        (option: any) =>
          option.category === "geometryOptions" || option.category === "options"
      );
      const stages = activeLayers.filter(
        (option: any) => option.category === "stages"
      );

      stages.map((stage: any) => {
        stage.activeItems.map((option: any) => {
          const value = stage.cameras.find(
            (camera: any) => camera.id === option
          );
          cameraCount += value.rotationSteps;
        });
      });
      options.map((opt: any) => {
        const count = opt.activeItems.length;
        variantsCount = variantsCount * count;
      });

      setCombination(cameraCount * variantsCount);
    }
  }, [activeLayers]);

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function createRender() {
    const res: any = {
      product: {
        id: data.id,
        stages: [],
        options: [],
        geometryOptions: [],
      },
    };
    activeLayers.map((layer: any) => {
      const thisArr = res.product[layer.category];
      res.product[layer.category] = [
        ...thisArr,
        {
          id: layer.id,
          [layer.typeRows]: layer.activeItems.map((i: any) => ({ id: i })),
        },
      ];
    });
    console.log("res: ", res);
    const info = await massRenderApi(res, data.id, rewrite);
    dispatch(
      setJobs({
        jobs: [info.data],
        productId: data.id,
      })
    );
    closeFunc();
  }
  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>Create render job</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.layers}>
          <div className={s.label}>Layers</div>
          <div className={s.line}></div>
          <div className={s.items}>
            {allData.length >= 1 &&
              allData.map((item: any, index: number) => {
                const itemIsActive = activeLayers.find(
                  (l: any) => l.id === item.id && l.typeRows === item.typeRows
                );
                return (
                  <div
                    className={`${s.item} ${itemIsActive && s.active}`}
                    key={`layer_${index}`}
                    onClick={() => {
                      // if (itemIsActive) {
                      //   // delete
                      //   setActiveLayers(
                      //     activeLayers.filter(
                      //       (l: any) =>
                      //         !(
                      //           l.id === item.id && l.typeRows === item.typeRows
                      //         )
                      //     )
                      //   );
                      // } else {
                      //   // add
                      //   setActiveLayers([...activeLayers, item]);
                      // }
                    }}
                  >
                    {item.proxyName ? item.proxyName : item.name}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={s.options}>
          {activeLayers.map((item: any, index: number) => {
            return (
              <RenderOption
                key={`options_${index}`}
                activeLayers={activeLayers}
                index={index}
                item={item}
                setActiveLayers={setActiveLayers}
              />
            );
          })}
        </div>
        <div className={s.rewrite}>
          <CheckboxWrapper
            name="Overwrite job"
            setValue={setRewrite}
            value={rewrite}
          />
        </div>
        <div className={s.combo}>
          Available number of variations: <b>{combination}</b>
        </div>
        <div className={s.btns}>
          <div className={s.create}>
            <ButtonWrapper
              clickEvent={createRender}
              text="Create render job"
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
