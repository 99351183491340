import { useDispatch, useSelector } from "react-redux";
import { Plus } from "../../../../../assets/svg/Plus";
import { InputWrapper } from "../../../../../shared/InputWrapper/InputWrapper";
import { SelectWrapper } from "../../../../../shared/SelectWrapper/SelectWrapper";
import createCategories from "../../../../api/caterories/createCategories";
import s from "../../ModalAddProduct.module.scss";
import { FC, useState, useEffect } from "react";
import { setCategories, setItemsAttribute } from "../../../../../redux/actions";
import {
  getActiveProject,
  getCategories,
} from "../../../../../redux/selectors/selectors";
import getCategoriesApi from "../../../../api/caterories/getCategoriesApi";
import { optionsTag } from "../../../../../utils/constants";
import getAttributesApi from "../../../../api/attributes/getAttributesApi";
import deleteAttributeApi from "../../../../api/attributes/deleteAttributeApi";
import createAttributeApi from "../../../../api/attributes/createAttributeApi";
import { ChooseFileWrapper } from "../../../../../shared/ChooseFileWrapper/ChooseFileWrapper";
import { popUp } from "../../../../../features/popUp/PopUp";

type CategoryInModalT = {
  setResult: any;
  categoriesValue: any;
  id: any;
};
export const CategoryInModal: FC<CategoryInModalT> = ({
  setResult,
  id,
  categoriesValue,
}) => {
  const dispatch = useDispatch();
  const projectId: any = useSelector(getActiveProject);
  const categoriesList: any[] = useSelector(getCategories);

  const [listValue, setlistValue]: any = useState(categoriesValue);
  const [listData, setListData] = useState([]);
  const [creating, setCreating] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [thumbnailValue, setThumbnailValue] = useState();

  useEffect(() => {
    const newArr: any = [];
    categoriesList.map((item: any) => {
      newArr.push({ label: item.name, value: item.id });
    });
    setListData(newArr);
  }, [categoriesList]);

  useEffect(() => {
    setResult(listValue);
  }, [listValue]);
  async function deleteCategory(idCategory: any) {
    const newAttr = await deleteAttributeApi({
      values: {
        categories: [idCategory],
      },
      id: "categories",
      url: `${optionsTag["products"].delete.url}/${id}`,
      request: optionsTag["products"].delete.request,
    });
    if (newAttr) {
      // loadItemsProductInState({ dispatch });
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${id}?page=1&pageSize=500`,
      });
      if (info) {
        dispatch(
          setItemsAttribute({
            itemId: id,
            attrName: "products",
            attrValues: info,
          })
        );
      }
    }
  }

  const addCategoryHandler = async (idCategory: any) => {
    const newArr: any = [idCategory];
    const newAttr = await createAttributeApi({
      values: {
        categories: newArr,
      },
      urlParam: `${id}/categories`,
      typeSend: "json",
      url: optionsTag["products"].create.url,
      request: optionsTag["products"].create.request,
    });
    if (newAttr) {
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${id}?page=1&pageSize=500`,
      });
      if (info) {
        dispatch(
          setItemsAttribute({
            itemId: id,
            attrName: "products",
            attrValues: info,
          })
        );
      }
    }
  };
  async function createCategoryHandler() {
    if (newValue && thumbnailValue) {
      const newCategory = await createCategories({
        name: newValue,
        image: thumbnailValue,
        projectId,
      });
      if (newCategory) {
        const categories = await getCategoriesApi(projectId);
        dispatch(setCategories({ categories: categories.rows }));
        setlistValue([...listValue, newCategory.id]);
        setCreating(false);
        if (id) {
          addCategoryHandler(newCategory.id);
        }
      }
    } else {
      popUp({ message: "Please add a picture and a title", type: "failure" });
    }
  }
  return (
    <div className={s.category}>
      <SelectWrapper
        name="Category (Optional)"
        placeholder="Select category rom the list"
        setValue={setlistValue}
        value={listValue}
        list={listData}
        addClassName={s.categorySelect}
        deleteEvent={(value: any) => {
          if (id) {
            deleteCategory(value);
          }
        }}
        addEvent={(value: any) => {
          if (id) {
            addCategoryHandler(value);
          }
        }}
      />
      {!creating ? (
        <div className={s.add} onClick={() => setCreating(true)}>
          <div className={s.plus}>
            <Plus />
          </div>
          <div className={s.categoryText}>Create category</div>
        </div>
      ) : (
        <div className={s.categoryContent}>
          <InputWrapper
            name="Category name"
            setValue={setNewValue}
            value={newValue}
            type="text"
            isRequired={true}
            placeholder="add new category"
          />
          <div
            className={s.plus}
            onClick={() => {
              createCategoryHandler();
            }}
          >
            <Plus />
          </div>
          <ChooseFileWrapper
            name="Thumbnail"
            placeholder="PNG"
            setValue={setThumbnailValue}
            value={thumbnailValue}
            // linkImage={imageFIle && imageFIle}
            isRequired={true}
            accept=".png"
          />
          <div className={s.hint}>
            <div className={s.red}>Please note: </div>
            The thumbnail will be displayed only in the configurator
          </div>
        </div>
      )}
    </div>
  );
};
