import getAttributesApi from "../../processes/api/attributes/getAttributesApi";
import { setAttribute } from "../../redux/actions";
import { rowsT } from "../../redux/reducers/types";
import { optionsTag } from "../../utils/constants";

type loadItemsProductInStateT = {
  dispatch: any;
};
export const loadItemsProductInState = async ({
  dispatch,
}: loadItemsProductInStateT) => {
  const pathname = window.location.pathname;
  const parts: any = pathname.split("/");
  const idProject = parts[1];
  const info = await getAttributesApi({
    url: optionsTag["products"].get.url,
    request: optionsTag["products"].get.request,
    filter: `?projectId=${idProject}&page=1&pageSize=500`,
  });
  if (info) {
    const count = info.length;
    const updatedRows: rowsT[] = [];
    info.map((row: any) => {
      updatedRows.push({
        ...row,
        cache: true,
      });
    });
    dispatch(
      setAttribute({
        projectId: Number(idProject),
        attrName: "products",
        attrValues: {
          count: count,
          cache: true,
          rows: updatedRows,
        },
      })
    );
  }
};
