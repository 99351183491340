import axios from "axios";
import { apiRoutes } from "../../../utils/constants";
import { popUp } from "../../../features/popUp/PopUp";
import { deleteAuthToken } from "../../sessionStorage/deleteAuthToken";

const startRenderApi = async (id: number) => {
  // popUp({ type: "info", message: "Loading..." });
  // return await axios
  popUp({ type: "info", message: "Loading..." });

  return await axios[apiRoutes.START_RENDER.request](
    `${apiRoutes.START_RENDER.url}/${id}/start`,
    {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response: any) => {
      console.log("response: ", response);
      if (response) {
        // popUp({
        //   type: "success",
        //   message: "Login successful",
        // });
        return response;
      } else {
        popUp({
          type: "failure",
          message: "Oops! There was a problem in mass Render Api",
        });
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      const dataError = error.response.data.errors;
      deleteAuthToken();
      // window.location.href = "/login";
      if (dataError) {
        dataError.map((err: any) => {
          popUp({ type: "failure", message: err.message });
        });
      } else {
        popUp({ type: "failure", message: "Oh, something went wrong!" });
      }
    });
};

export default startRenderApi;
