import { useState } from "react";
import { Logo } from "../../assets/svg/Logo";
import { LogoWithText } from "../../assets/svg/LogoWithText";
import { InputWrapper } from "../../shared/InputWrapper/InputWrapper";
import s from "./LoginWidget.module.scss";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import loginApi from "../../processes/api/login/loginApi";
import { Route, useNavigate } from "react-router-dom";
import { addSecondsToCurrentTime } from "../../features/functions/addSecondsToCurrentTime";
import { secondsToMilis } from "../../features/functions/secondsToMilis";
import { setAuthToken } from "../../processes/sessionStorage/setAuthToken";
import { PAGES } from "../../utils/constants";

export const LoginWidget = () => {
  const [mailValue, setMailValue] = useState("admin");
  const [passValue, setPassValue] = useState("RmxJ8UjtE3aY4czn");
  const navigate = useNavigate();

  async function loginEvent() {
    if (mailValue && passValue) {
      const info = await loginApi({
        username: mailValue,
        password: passValue,
      });

      if (info) {
        const token = info.data.token;
        const tokenLife = info.data.tokenExpiresIn - 30;

        const refreshToken = info.data.refreshToken;
        const refreshTokenLife = info.data.refreshTokenExpiresIn;
        const currentTime = addSecondsToCurrentTime(0);
        const tokenLifeTime = addSecondsToCurrentTime(
          secondsToMilis(tokenLife)
        );
        const refreshTokenLifeTime = addSecondsToCurrentTime(
          secondsToMilis(refreshTokenLife)
        );

        const data = {
          token,
          refreshToken,
          currentTime,
          tokenLifeTime,
          refreshTokenLifeTime,
        };

        setAuthToken(JSON.stringify(data));
        navigate(PAGES.PROJECTS);
      }
    }
  }
  return (
    <div className={s.wrapper}>
      <div className={s.logo}>
        <LogoWithText />
      </div>
      <div className={s.content}>
        <div className={s.title}>Login into admin panel</div>
        <InputWrapper
          name="email"
          setValue={setMailValue}
          value={mailValue}
          placeholder="Enter email"
          type="email"
        />
        <InputWrapper
          name="password"
          setValue={setPassValue}
          value={passValue}
          placeholder="Enter password"
          type="password"
        />
        <ButtonWrapper type="primary" text="Login" clickEvent={loginEvent} />
      </div>
    </div>
  );
};
