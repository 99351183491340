import {
  deleteAttributeEvent,
  deleteAttributeEventOption,
} from "../../../features/functions/deleteAttributeEvent";
import { duplicateAttributeEvent } from "../../../features/functions/duplicateAttributeEvent";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";
import {
  toggleEnableAttribute,
  toggleEnableAttributeOption,
} from "../../../features/functions/toggleEnableAttribute";
import { changeShowModal } from "../../../redux/actions";
import { optionsTag } from "../../../utils/constants";

const handleEnableAttribute = async ({ item, dispatch }: any) => {
  const keyName: any =
    item.typeValue === "material" ? "options" : "geometryOptions";
  await toggleEnableAttributeOption({
    id: item.id,
    name: item.name,
    mount: item.mountPoint,
    apiName: keyName,
    nameAttr: "options",
    enabled: !item.enabled,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};

const handleDeleteAttribute = async ({ item, dispatch }: any) => {
  const keyName: any =
    item.typeValue === "material" ? "options" : "geometryOptions";

  await deleteAttributeEventOption({
    id: item.id,
    name: item.name,
    nameAttr: "options",
    apiName: keyName,
    enabled: !item.enabled,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};

const handleDuplicateAttribute = ({
  item,
  projectId,
  dispatch,
  optionsData,
}: any) => {
  const keyName = item.typeValue === "material" ? "options" : "geometryOptions";
  duplicateAttributeEvent({
    name: item.name,
    count: optionsData.count,
    attrId: item.id,
    otherValues: { typeValue: item.typeValue },
    prjectId: projectId,
    nameAttr: "options",
    url: optionsTag[keyName].create.url,
    request: optionsTag[keyName].create.request,
    dispatch,
  });
};
const handleEditAttribute = async ({ item, dispatch, projectId }: any) => {
  dispatch(
    changeShowModal({
      nameModal: "addOption",
      stateModal: true,
      otherParams: {
        prjectId: projectId,
        name: item.name,
        typeValue: item.typeValue,
        id: item.id,
        mount: item.mountPoint,
        path: item.relativePath,
        requirements: item.requirements,
      },
    })
  );
};

export {
  handleEnableAttribute,
  handleDeleteAttribute,
  handleDuplicateAttribute,
  handleEditAttribute,
};
