import { FC } from "react";

export const Logo: FC = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="step 1">
        <g id="symbol:loaderAnim 1 ">
          <g id="base">
            <path
              id="Ellipse 1"
              d="M56.7273 79.8735C56.7273 75.1234 59.852 71.2727 64.7273 71.2727C69.6026 71.2727 72.7273 75.1234 72.7273 79.8735C72.7273 84.6236 65.279 96 64.7273 96C64.1756 96 56.7273 84.6236 56.7273 79.8735Z"
              fill="#2A8EFA"
            />
            <path
              id="Ellipse 2"
              d="M71.2728 48.1265C71.2728 52.8766 68.1482 56.7273 63.2728 56.7273C58.3975 56.7273 55.2728 52.8766 55.2728 48.1265C55.2728 43.3764 62.7211 32 63.2728 32C63.8246 32 71.2728 43.3764 71.2728 48.1265Z"
              fill="#FF4F79"
            />
            <path
              id="Ellipse 3"
              d="M79.8735 71.2727C75.1234 71.2727 71.2727 68.148 71.2727 63.2727C71.2727 58.3974 75.1234 55.2727 79.8735 55.2727C84.6236 55.2727 96 62.721 96 63.2727C96 63.8244 84.6236 71.2727 79.8735 71.2727Z"
              fill="#37CC8F"
            />
            <path
              id="Ellipse 4"
              d="M48.1265 56.7273C52.8766 56.7273 56.7273 59.852 56.7273 64.7273C56.7273 69.6026 52.8766 72.7273 48.1265 72.7273C43.3764 72.7273 32 65.279 32 64.7273C32 64.1756 43.3764 56.7273 48.1265 56.7273Z"
              fill="#EAC159"
            />
          </g>
          <g id="leaves">
            <path
              id="Ellipse 5"
              d="M46.7508 42.408C48.3132 44.0067 48.587 46.3185 47.0382 47.9034C45.4893 49.4883 43.23 49.2081 41.6677 47.6094C40.1053 46.0107 38.7298 39.7606 38.9051 39.5812C39.0804 39.4019 45.1885 40.8093 46.7508 42.408Z"
              fill="#2A8EFA"
            />
            <path
              id="Ellipse 7"
              d="M46.7508 84.1375C48.3132 82.5388 48.587 80.2271 47.0382 78.6422C45.4893 77.0573 43.23 77.3375 41.6677 78.9361C40.1053 80.5348 38.7298 86.785 38.9051 86.9643C39.0804 87.1437 45.1885 85.7362 46.7508 84.1375Z"
              fill="#37CC8F"
            />
            <path
              id="Ellipse 6"
              d="M79.794 42.408C78.2316 44.0067 77.9578 46.3185 79.5066 47.9034C81.0555 49.4883 83.3148 49.2081 84.8771 47.6094C86.4395 46.0107 87.815 39.7606 87.6397 39.5812C87.4644 39.4019 81.3563 40.8093 79.794 42.408Z"
              fill="#EAC159"
            />
            <path
              id="Ellipse 8"
              d="M79.794 84.1375C78.2316 82.5388 77.9578 80.2271 79.5066 78.6422C81.0555 77.0573 83.3148 77.3375 84.8771 78.9361C86.4395 80.5348 87.815 86.785 87.6397 86.9643C87.4644 87.1437 81.3563 85.7362 79.794 84.1375Z"
              fill="#FF4F79"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
