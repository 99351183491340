import { useEffect } from "react";
import getAttributesApi from "../../../processes/api/attributes/getAttributesApi";
import { groupNamesT, optionsTag } from "../../../utils/constants";
import { setItemsAttribute } from "../../../redux/actions";
export const useUpdateActualData = ({ environmentData, dispatch }: any) => {
  useEffect(() => {
    const getActualData = async () => {
      if (environmentData && !environmentData.cache) {
        const keyId: any = "environmentOptionId";
        const info = await getAttributesApi({
          url: optionsTag["environmentVariants"].get.url,
          request: optionsTag["environmentVariants"].get.request,
          filter: `?${keyId}=${environmentData.id}&page=1&pageSize=500`,
        });
        if (info) {
          dispatch(
            setItemsAttribute({
              itemId: environmentData.id,
              attrName: "environment",
              attrValues: info.rows,
            })
          );
        }
      }
    };
    getActualData();
  }, []);
};
