import { Delete } from "../../assets/svg/Delete";
import { Duplicate } from "../../assets/svg/Duplicate";
import { Edit } from "../../assets/svg/Edit";
import s from "./EventBtn.module.scss";
import { FC } from "react";

type EventBtnT = {
  clickEvent: any;
  type: "delete" | "duplicate" | "edit";
};
const textObg = {
  delete: "Delete",
  duplicate: "Duplicate",
  edit: "Edit",
};
export const EventBtn: FC<EventBtnT> = ({ ...props }) => {
  const { clickEvent, type } = props;
  return (
    <div
      className={`${s.edit} ${s[type]}`}
      onClick={(event: any) => {
        event.stopPropagation();
        clickEvent();
      }}
    >
      {type === "edit" && <Edit />}
      {type === "delete" && <Delete />}
      {type === "duplicate" && <Duplicate />}
      <span>{textObg[type]}</span>
    </div>
  );
};
