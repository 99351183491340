import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddProject.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  addNewProject,
  changeShowModal,
  setAllProjects,
  updateProject,
} from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import createProjectApi from "../../api/projects/createProjectApi";
import updateProjectApi from "../../api/projects/updateProjectApi";
import getProjectsApi from "../../api/projects/getProjectsApi";
import { ChooseFileWrapper } from "../../../shared/ChooseFileWrapper/ChooseFileWrapper";

type ModalAddProjectT = {
  closeFunc: any;
  data: any;
};
export const ModalAddProject: FC<ModalAddProjectT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const text = data.id ? "Edit project name" : "Add new project";
  const [fileValue, setfileValue] = useState();

  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState(data.name);
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditProject() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    if (data.name === nameValue) {
      popUp({ type: "failure", message: "The name must be different" });
    } else if (nameValue === "") {
      popUp({ type: "failure", message: "The name field must not be empty" });
    } else {
      // редагуємо
      if (data.id) {
        const dataValues: any = {
          name: nameValue,
        };
        if (fileValue) {
          dataValues.instance = fileValue;
        }
        const updatedProject = await updateProjectApi(dataValues, data.id);
        if (updatedProject) {
          dispatch(updateProject({ id: data.id, name: nameValue }));

          popUp({
            type: "success",
            message: "project updated!",
          });
        }
      } else {
        // створюємо
        const dataValues: any = {
          name: nameValue,
        };
        if (fileValue) {
          dataValues.instance = fileValue;
        }
        const newProject = await createProjectApi(dataValues);
        if (newProject) {
          dispatch(addNewProject({ id: newProject.id, name: nameValue }));
          popUp({
            type: "success",
            message: "project has been successfully created",
          });
        }
      }
      // const projects = await getProjectsApi();
      // dispatch(setAllProjects({ projects: projects.rows }));
      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <InputWrapper
          name="name"
          setValue={setNameValue}
          value={nameValue}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />
        <ChooseFileWrapper
          name="Instance"
          placeholder="ZIP"
          setValue={setfileValue}
          value={fileValue}
          linkImage={data.image && data.image}
          isRequired={false}
          accept=".zip"
        />
        <ButtonWrapper
          clickEvent={addOrEditProject}
          text="Save project"
          type="primary"
        />
      </div>
    </div>
  );
};
