import createAttributeApi from "../../processes/api/attributes/createAttributeApi";
import { addOrUpdateAttribute, changeShowModal } from "../../redux/actions";
import { attributesNamesT } from "../../redux/reducers/types";
import { requestT } from "../../utils/constants";
function cloneAndAppendTo(str: any, count: number) {
  // Создаем копию строки
  const clone = str;

  // Добавляем копию строки в конец
  const result = clone + ` (${count + 1})`;

  return result;
}
type duplicateAttributeEventT = {
  name: string;
  nameAttr: attributesNamesT;
  prjectId: number;
  attrId: number;
  count: number;
  dispatch: any;
  otherValues?: any;
  url: string;
  request: requestT;
};
export const duplicateAttributeEvent = async ({
  nameAttr,
  attrId,
  dispatch,
  url,
  request,
  otherValues = {},
}: duplicateAttributeEventT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
  const newAttr = await createAttributeApi({
    values: {},
    urlParam: `duplicate/${attrId}`,
    typeSend: "json",
    url,
    request,
  });
  if (newAttr) {
    const additionalParams =
      nameAttr === "products"
        ? {
            availableStages: [],
            availableOptions: [],
            cache: false,
          }
        : {};
    dispatch(
      addOrUpdateAttribute({
        attrId: newAttr.id,
        attrName: nameAttr,
        attrValue: {
          ...newAttr,
          ...additionalParams,
          ...otherValues,
          items: [],
        },
        type: "add",
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};
