import { useDispatch, useSelector } from "react-redux";
import s from "./CategoryProduct.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import {
  getActiveProject,
  getCategories,
} from "../../../../redux/selectors/selectors";
import { Close } from "../../../../assets/svg/Close";
import { Plus } from "../../../../assets/svg/Plus";
import createAttributeApi from "../../../../processes/api/attributes/createAttributeApi";
import { optionsTag } from "../../../../utils/constants";
import { loadItemsProductInState } from "../../../../features/functions/loadItemsProductInState";
import deleteAttributeApi from "../../../../processes/api/attributes/deleteAttributeApi";
import getAttributesApi from "../../../../processes/api/attributes/getAttributesApi";
import { changeShowModal, setItemsAttribute } from "../../../../redux/actions";
import { Edit } from "../../../../assets/svg/Edit";

type CategoryProductT = {
  productData: any;
};
export const CategoryProduct: FC<CategoryProductT> = ({ productData }) => {
  const dispatch = useDispatch();
  const projectId: any = useSelector(getActiveProject);
  const categoriesList: any[] = useSelector(getCategories);
  const [categories, setCategories]: any = useState([]);
  //   const [categories, setCategories] = useState(productData.categories);
  const wrapperRef: any = useRef(null);
  const [open, setOpen] = useState(false);

  function closeFunc() {
    setOpen(false);
  }

  useEffect(() => {
    const activeIds = productData.categories.map((item: any) => item.id);
    setCategories(activeIds);
  }, [productData.categories]);
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addCategoryHandler(id: any) {
    const newArr: any = [...categories, id];
    const newAttr = await createAttributeApi({
      values: {
        categories: newArr,
      },
      urlParam: `${productData.id}/categories`,
      typeSend: "json",
      url: optionsTag["products"].create.url,
      request: optionsTag["products"].create.request,
    });
    if (newAttr) {
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${productData.id}?page=1&pageSize=500`,
      });
      if (info) {
        dispatch(
          setItemsAttribute({
            itemId: productData.id,
            attrName: "products",
            attrValues: info,
          })
        );
      }
    }
    setCategories(newArr);
  }

  async function deleteCategoryHandler(id: any) {
    const newAttr = await deleteAttributeApi({
      values: {
        categories: [id],
      },
      id: "categories",
      url: `${optionsTag["products"].delete.url}/${productData.id}`,
      request: optionsTag["products"].delete.request,
    });
    if (newAttr) {
      // loadItemsProductInState({ dispatch });
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${productData.id}?page=1&pageSize=500`,
      });
      if (info) {
        dispatch(
          setItemsAttribute({
            itemId: productData.id,
            attrName: "products",
            attrValues: info,
          })
        );
      }
    }
    const filteredNumbers = categories.filter((num: any) => num !== id);
    setCategories(filteredNumbers);
  }
  return (
    <div className={s.categories}>
      {/* productData */}
      <div className={s.info}>
        <div className={s.titleAttr}>Categories</div>
        <div className={s.activeCategoryWrapper}>
          {categories.map((item: any, index: number) => {
            const activeItem = categoriesList.find((i: any) => i.id === item);
            return (
              <>
                {activeItem && (
                  <div
                    className={s.activeCategories}
                    key={`activeCategories_${index}`}
                  >
                    <div
                      className={s.edit}
                      onClick={() => {
                        dispatch(
                          changeShowModal({
                            nameModal: "addCategory",
                            stateModal: true,
                            otherParams: {
                              projectId: projectId,
                              productId: productData.id,
                              id: activeItem.id,
                              name: activeItem.name,
                              image: activeItem.image
                            },
                          })
                        );
                      }}
                    >
                      <Edit />
                    </div>
                    {activeItem.name}
                    <div
                      className={s.close}
                      onClick={() => deleteCategoryHandler(item)}
                    >
                      <Close />
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      <div className={s.addWrapper} ref={wrapperRef}>
        <div className={s.categoriesBtn} onClick={() => setOpen(true)}>
          <Plus />
          Add category
        </div>
        <div
          className={`${s.categoriesContent} ${open && s.active} customScroll`}
        >
          {categoriesList.map((item: any, index: number) => {
            return (
              <div
                className={`${s.categoriesItem} ${
                  categories.includes(item.id) && s.disable
                }`}
                key={`categoriesItem_${index}`}
                onClick={() => addCategoryHandler(item.id)}
              >
                {item.name}
              </div>
            );
          })}
          <div
            className={s.create}
            onClick={() => {
              dispatch(
                changeShowModal({
                  nameModal: "addCategory",
                  stateModal: true,
                  otherParams: {
                    projectId: projectId,
                    productId: productData.id,
                    name: "",
                  },
                })
              );
            }}
          >
            <Plus />
            Create new
          </div>
        </div>
      </div>
    </div>
  );
};
