import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddProduct.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  addOrUpdateAttribute,
  changeShowModal,
  setItemsAttribute,
} from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import updateAttributeApi from "../../api/attributes/updateAttributeApi";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { optionsTag } from "../../../utils/constants";
import { ChooseFileWrapper } from "../../../shared/ChooseFileWrapper/ChooseFileWrapper";
import { CategoryInModal } from "./components/CategoryInModal/CategoryInModal";
import getAttributesApi from "../../api/attributes/getAttributesApi";
import { CheckboxWrapper } from "../../../shared/CheckboxWrapper/CheckboxWrapper";

type ModalAddProductT = {
  closeFunc: any;
  data: any;
};
export const ModalAddProduct: FC<ModalAddProductT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  console.log("data: ", data);
  const {
    prjectId,
    id,
    name,
    file,
    optionalFile,
    playcanvas,
    imageFIle,
    layeredRender = false,
    mount,
    categories = [],
  } = data;
  const text = id ? "Edit product" : "Add new product";

  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState(name);
  const [fileValue, setfileValue] = useState();
  const [categoriesValue, setCategoriesValue] = useState(categories);
  const [optionalFileValue, setOptionalFileValue] = useState();
  const [optionalImageValue, setOptionalImageValue] = useState();
  const [optionalZipValue, setOptionalZipValue] = useState();
  const [pathValue, setPathValue] = useState(data.path);
  const [mountValue, setMountValue] = useState(mount);
  const [layered, setLayered] = useState(layeredRender);
  const [activeTab, setActiveTab] = useState(0);

  const errorsIfNullValue = [
    {
      value: nameValue,
      message: "The name field must not be empty",
    },
    // {
    //   value: fileValue,
    //   message: "The file field must not be empty",
    // },
    // {
    //   value: mountValue,
    //   message: "The mount point field must not be empty",
    // },
  ];

  const addCategoryHandler = async (id: any) => {
    const newArr: any = categoriesValue;
    const newAttr = await createAttributeApi({
      values: {
        categories: newArr,
      },
      urlParam: `${id}/categories`,
      typeSend: "json",
      url: optionsTag["products"].create.url,
      request: optionsTag["products"].create.request,
    });
    if (newAttr) {
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${id}?page=1&pageSize=500`,
      });
      if (info) {
        dispatch(
          setItemsAttribute({
            itemId: id,
            attrName: "products",
            attrValues: info,
          })
        );
      }
    }
  };

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditAttribute() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    let errorCounter: number = 0;
    errorsIfNullValue.map((error: any) => {
      if (!error.value) {
        errorCounter++;
        popUp({ type: "failure", message: error.message });
      }
    });

    if (errorCounter === 0) {
      // редагуємо
      if (id) {
        const values: any = {
          name: nameValue,
          mountPoint: mountValue,
          categories: categoriesValue,
          layeredRender: layered,
        };
        if (pathValue) {
          values.relativePath = pathValue;
        }
        if (fileValue && typeof fileValue !== "string") {
          values.resource = fileValue;
        }
        if (optionalFileValue && typeof optionalFileValue !== "string") {
          values.glbBundle = optionalFileValue;
        }
        if (optionalImageValue && typeof optionalImageValue !== "string") {
          values.imgCover = optionalImageValue;
        }
        if (optionalZipValue && typeof optionalZipValue !== "string") {
          values.playcanvas = optionalZipValue;
        }

        const updatedOption = await updateAttributeApi({
          id: id,
          values,
          typeSend: "formData",
          url: optionsTag["products"].update.url,
          request: optionsTag["products"].update.request,
        });
        if (updatedOption) {
          dispatch(
            addOrUpdateAttribute({
              attrId: updatedOption.id,
              attrName: "products",
              attrValue: updatedOption,
              type: "update",
            })
          );

          popUp({
            type: "success",
            message: "products updated!",
          });
        }
      } else {
        // створюємо

        const values: any = {
          name: nameValue,
          categories: categoriesValue,
          resource: fileValue,
          projectId: prjectId,
          mountPoint: mountValue,
          layeredRender: layered,
        };
        if (pathValue) {
          values.relativePath = pathValue;
        }
        if (optionalFileValue) {
          values.glbBundle = optionalFileValue;
        }
        if (optionalImageValue) {
          values.imgCover = optionalImageValue;
        }
        if (optionalZipValue) {
          values.playcanvas = optionalZipValue;
        }

        const newAttr = await createAttributeApi({
          values: values,
          typeSend: "formData",
          url: optionsTag["products"].create.url,
          request: optionsTag["products"].create.request,
        });
        if (newAttr) {
          dispatch(
            addOrUpdateAttribute({
              attrId: newAttr.id,
              attrName: "products",
              attrValue: {
                ...newAttr,
                items: [],
                cache: false,
                availableStages: [],
                availableOptions: [],
                categories: [],
              },
              type: "add",
            })
          );
          addCategoryHandler(newAttr.id);
          popUp({
            type: "success",
            message: "products has been successfully created",
          });
        }
      }

      // const projects = await getProjectsApi();
      // dispatch(setAllProjects({ projects: projects.rows }));
      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={`${s.wrapper} customScroll`} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <InputWrapper
          name="name"
          setValue={setNameValue}
          value={nameValue}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />
        <div className={s.tabs}>
          <div
            className={`${s.tab} ${s.tabLeft} ${activeTab === 0 && s.active}`}
            onClick={() => setActiveTab(0)}
          >
            Base model
          </div>
          <div
            className={`${s.tab} ${activeTab === 1 && s.active}`}
            onClick={() => setActiveTab(1)}
          >
            Image cover
          </div>
          <div
            className={`${s.tab} ${s.tabRight} ${activeTab === 2 && s.active}`}
            onClick={() => setActiveTab(2)}
          >
            GLB-bundle (Optional)
          </div>
          <div
            className={`${s.tab} ${s.tabRight} ${activeTab === 3 && s.active}`}
            onClick={() => setActiveTab(3)}
          >
            Playcanvas (Optional)
          </div>
        </div>
        <div className={s.tabContent}>
          <div className={`${s.tabFirst} ${activeTab === 0 && s.active}`}>
            <ChooseFileWrapper
              name="Model"
              placeholder="PAK"
              setValue={setfileValue}
              value={fileValue}
              linkImage={file && file}
              isRequired={false}
              accept=".pak"
            />
          </div>
          <div className={`${s.tabSecond} ${activeTab === 1 && s.active}`}>
            <ChooseFileWrapper
              name="Image"
              placeholder="PNG"
              setValue={setOptionalImageValue}
              value={optionalImageValue}
              linkImage={imageFIle && imageFIle}
              isRequired={false}
              accept=".png"
            />
            <div className={s.hint}>
              <div className={s.red}>Please note: </div>
              The image will be displayed only in the configurator
            </div>
          </div>
          <div className={`${s.tabThird} ${activeTab === 2 && s.active}`}>
            <ChooseFileWrapper
              name="Model"
              placeholder="GLB"
              setValue={setOptionalFileValue}
              value={optionalFileValue}
              linkImage={optionalFile && optionalFile}
              isRequired={false}
              accept=".glb"
            />
          </div>
          <div className={`${s.tabThird} ${activeTab === 3 && s.active}`}>
            <ChooseFileWrapper
              name="Zip"
              placeholder="ZIP"
              setValue={setOptionalZipValue}
              value={optionalZipValue}
              textPreview={playcanvas && playcanvas}
              isRequired={false}
              accept=".zip"
            />
          </div>
        </div>
       
        <CheckboxWrapper
          name="Layered Render"
          setValue={setLayered}
          value={layered}
        />
        <InputWrapper
          name="Mount point"
          setValue={setMountValue}
          value={mountValue}
          type="text"
          isRequired={false}
          placeholder="Mount Point"
        />
        <InputWrapper
          name="Relative Path"
          setValue={setPathValue}
          value={pathValue}
          type="text"
          isRequired={false}
          placeholder="Path Point"
          hint="Relative path example: products/leather"
        />
        <CategoryInModal
          setResult={(value: any) => {
            setCategoriesValue(value);
          }}
          categoriesValue={categoriesValue}
          id={id}
        />
        <ButtonWrapper
          clickEvent={addOrEditAttribute}
          text="Save product"
          type="primary"
        />
      </div>
    </div>
  );
};
