import { FC } from "react";
import s from "../../ProjectMenu.module.scss";
import { ButtonWrapper } from "../../../../shared/ButtonWrapper/ButtonWrapper";
import {
  changeShowModal,
  deleteProject,
  setActiveProject,
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { NoneData } from "../../../../shared/NoneData/NoneData";
import { EventBtn } from "../../../../shared/EventBtn/EventBtn";
import { useNavigate } from "react-router-dom";
import {
  getActiveProject,
  getActiveTab,
  getAllProjects,
} from "../../../../redux/selectors/selectors";
import { PAGES } from "../../../../utils/constants";
import deleteProjectApi from "../../../../processes/api/projects/deleteProjectApi";
import { popUp } from "../../../../features/popUp/PopUp";

export const ProjectList: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeProject = useSelector(getActiveProject);
  const activeTab = useSelector(getActiveTab);
  const projectList: any[] = useSelector(getAllProjects);

  return (
    <div className={`${s.content} customScroll`}>
      {projectList.length >= 1 ? (
        projectList.map((item: any, index: number) => {
          return (
            <div
              className={`${s.item} ${activeProject === item.id && s.active}`}
              key={`project_${item.id}`}
              onClick={() => {
                //@ts-ignore
                window.loadCategories = false;
                const tab = activeTab ? `/${activeTab}` : PAGES.OPTIONS;
                navigate(`/${item.id}${tab}`);
              }}
            >
              <div className={s.name}>{item.name}</div>
              <div className={s.btns}>
                <EventBtn
                  type="edit"
                  clickEvent={() =>
                    dispatch(
                      changeShowModal({
                        nameModal: "addProject",
                        stateModal: true,
                        otherParams: {
                          id: item.id,
                          name: item.name,
                        },
                      })
                    )
                  }
                />
                <EventBtn
                  type="delete"
                  clickEvent={() =>
                    dispatch(
                      changeShowModal({
                        nameModal: "deleteProject",
                        stateModal: true,
                        otherParams: {
                          deleteEvent: (idPage: any) => {
                            dispatch(
                              changeShowModal({
                                nameModal: "loader",
                                stateModal: true,
                              })
                            );

                            deleteProjectApi(item.id);
                            dispatch(deleteProject({ id: item.id }));
                            if (idPage == item.id) {
                              dispatch(setActiveProject({ id: undefined }));
                              navigate(PAGES.PROJECTS);
                            }
                            popUp({
                              message: `project ${item.name} has been successfully deleted`,
                              type: "success",
                            });
                            dispatch(
                              changeShowModal({
                                nameModal: "loader",
                                stateModal: false,
                              })
                            );
                          },
                        },
                      })
                    )
                  }
                />
              </div>
            </div>
          );
        })
      ) : (
        <NoneData text="/// No projects available yet ///" />
      )}
    </div>
  );
};
