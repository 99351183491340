import deleteAttributeApi from "../../processes/api/attributes/deleteAttributeApi";
import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import {
  addOrUpdateAttribute,
  changeShowModal,
  deleteAttribute,
  deleteItemAttribute,
} from "../../redux/actions";
import {
  attributeItemsNamesT,
  attributesNamesT,
} from "../../redux/reducers/types";
import { optionsTag } from "../../utils/constants";
import { deleteAttributeInState } from "./deleteAttributeInState";

type deleteItemsAttributeEventT = {
  id: number;
  dispatch: any;
  attrName: attributesNamesT;
  attrTag: attributesNamesT | attributeItemsNamesT;
  itemId: number;
};
export const deleteItemsAttributeEvent = async ({
  id,
  dispatch,
  attrName,
  attrTag,
  itemId,
}: deleteItemsAttributeEventT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));

  const updatedAttr = await deleteAttributeApi({
    id: itemId,
    url: optionsTag[attrTag].delete.url,
    request: optionsTag[attrTag].delete.request,
  });
  if (updatedAttr) {
    dispatch(
      deleteItemAttribute({
        attrId: id,
        attrName,
        itemId,
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};
