import { FC } from "react";

export const Plus: FC = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.512 6.88V5.176H5.096V0.808H6.896V5.176H11.48V6.88H6.896V11.272H5.096V6.88H0.512Z"
        fill="#343A40"
      />
    </svg>
  );
};
