import { FC, useEffect } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { useNavigate } from "react-router-dom";
import { checkTokenAndRefresh } from "../../features/functions/checkTokenAndRefresh";
import { MainLayout } from "../../widgets/MainLayout/MainLayout";

export const App: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <MainLayout>
        <div className={s.wrapper}></div>
      </MainLayout>
      <MobileHint />
    </>
  );
};
