import { apiRoutesValueT } from "../constants";

export type categoriesRoutesNameT =
  | "GET_CATEGORIES"
  | "UPDATE_CATEGORY"
  | "CREATE_CATEGORY"
  | "DELETE_CATEGORY"
  | "RETRIEVE_CATEGORY";
type categoriesRoutesT = Record<categoriesRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const categoriesRoutes: categoriesRoutesT = {
  GET_CATEGORIES: {
    url: `${api}/categories`,
    request: "get",
  },
  CREATE_CATEGORY: {
    url: `${api}/categories`,
    request: "post",
  },
  UPDATE_CATEGORY: {
    url: `${api}/categories`,
    request: "put",
  },
  DELETE_CATEGORY: {
    url: `${api}/categories`,
    request: "delete",
  },
  RETRIEVE_CATEGORY: {
    url: `${api}/categories`,
    request: "get",
  },
};
