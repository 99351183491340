import { FC, useEffect, useState } from "react";
import s from "./AnotherOptionSelect.module.scss";
import { useSelector } from "react-redux";
import { getAttributeByName } from "../../../../../redux/selectors/selectors";
import { SelectWrapper } from "../../../../../shared/SelectWrapper/SelectWrapper";
import { AnotherVariant } from "../AnotherVariant/AnotherVariant";

type AnotherOptionSelectT = {
  dependencies: any;
  setDependencies: any;
  defaultDep: any;
};
export const AnotherOptionSelect: FC<AnotherOptionSelectT> = ({
  //@ts-ignore
  dependencies,
  setDependencies,
  defaultDep,
}) => {
  const optionsData = useSelector(getAttributeByName("options"));
  const [listData, setListData]: any = useState([]);
  const [listValue, setListValue] = useState([]);

  useEffect(() => {
    if (defaultDep) {
      const newList: any = [];
      defaultDep.map((dep: any) => {
        newList.push(dep.geometryOptionId);
      });
      setListValue(newList);
    }
  }, []);
  useEffect(() => {
    const res: any = [];
    listValue.map((list: any) => {
      const option = listData.find((data: any) => data.value === list);
      if (option.activeItem) {
        res.push({
          geometryOptionId: option.value,
          geometryVariantId: option.activeItem.id,
        });
      }
    });
    setDependencies(res);
  }, [listData, listValue]);
  useEffect(() => {
    if (optionsData) {
      const arr: any = [];
      optionsData.rows.map((item: any) => {
        if (item.typeValue === "geometry" && item.items.length >= 1) {
          const activeItem: any = defaultDep
            ? defaultDep.find((dep: any) => dep.geometryOptionId === item.id)
            : undefined;
          arr.push({
            label: item.name,
            value: item.id,
            items: item.items,
            activeItem: activeItem
              ? item.items.find(
                  (value: any) => value.id === activeItem.geometryVariantId
                )
              : undefined,
          });
        }
      });
      setListData(arr);
    }
  }, [optionsData]);
  return (
    <div className={s.wrapper}>
      <SelectWrapper
        name="Include another geometry option for rendering"
        placeholder="geometry option"
        setValue={setListValue}
        value={listValue}
        list={listData}
        addClassName={s.categorySelect}
        deleteEvent={(value: any) => {
          console.log("value: ", value);
        }}
        addEvent={(value: any) => {
          console.log("value: ", value);
        }}
      />
      {listValue.map((list: any, index: number) => {
        const option = listData.find((data: any) => data.value === list);
        return (
          <AnotherVariant
            key={`list_${index}`}
            option={option}
            setListData={setListData}
            listData={listData}
          />
        );
      })}
    </div>
  );
};
