import { FC, useId } from "react";
import s from "./InputWrapper.module.scss";
import InputMask from "react-input-mask";

type InputWrapperT = {
  name?: string;
  setValue: any;
  value: any;
  mask?: any;
  hint?: string;
  maskChar?: any;
  type: "text" | "email" | "password" | "number";
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  addClassName?: any;
};
export const InputWrapper: FC<InputWrapperT> = ({ ...props }) => {
  const {
    isRequired = false,
    name,
    setValue,
    hint,
    value,
    placeholder = "",
    addClassName = false,
    type,
    mask,
    maskChar,
    disabled = false,
  } = props;
  let classWrapper = `${s.wrapper}`;
  addClassName
    ? (classWrapper += ` ${addClassName}`)
    : (classWrapper = `${s.wrapper}`);
  const inputId = useId();

  // const beforeMaskedValueChangeEvent = (
  //   newState: any,
  //   oldState: any,
  //   userInput: any
  // ) => {
  //   let newValue = newState.value;
  //   var selection = newState.selection;
  //   var cursorPosition = selection ? selection.start : null;
  //   console.log("selection.start: ", selection.start);
  //   console.log("userInput: ", userInput);
  //   const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  //   // keep minus if entered by user
  //   // && userInput !== "-"
  //   console.log("newValue: ", newValue);
  //   if (userInput) {
  //     if (numbers.includes(newValue[0])) {
  //       console.log('newValue[0]: ', newValue[0]);
  //       const symbol = userInput === "+" && selection.start === 1 ? "+" : "-";
  //       newValue = newValue.substring(0, 0) + symbol + newValue.substring(1);
  //     }
  //     if (numbers.includes(newValue[10])) {
  //       const symbol = userInput === "+" && selection.start === 11 ? "+" : "-";
  //       newValue = newValue.substring(0, 10) + symbol + newValue.substring(11);
  //     }
  //     if (numbers.includes(newValue[20])) {
  //       const symbol = userInput === "+" && selection.start === 21 ? "+" : "-";
  //       newValue = newValue.substring(0, 20) + symbol + newValue.substring(21);
  //     }
  //   }

  //   return {
  //     value: newValue,
  //     selection,
  //   };
  // };

  const state = {
    formatChars: {
      "9": "[0-9,-,+]",
      a: "[A-Za-z]",
      "*": "[-,0,1,2,3,4,5,6,7,8,9]",
    },
    // beforeMaskedValueChange: beforeMaskedValueChangeEvent,
  };
  return (
    <div className={classWrapper}>
      <label htmlFor={inputId}>
        {name && (
          <>
            {name}
            {isRequired && <span className={s.req}>*</span>}
          </>
        )}
      </label>
      {mask ? (
        <InputMask
          className={s.position}
          type={type}
          mask={mask}
          {...state}
          maskChar={maskChar}
          id={inputId}
          placeholder={placeholder}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
      ) : (
        <input
          type={type}
          disabled={disabled}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          id={inputId}
          placeholder={placeholder}
        />
      )}
      {hint && <div className={s.hint}>{hint}</div>}
    </div>
  );
};
