import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddCategory.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  addNewProject,
  changeShowModal,
  setAllProjects,
  setCategories,
  setItemsAttribute,
  updateProject,
} from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import createCategories from "../../api/caterories/createCategories";
import getCategoriesApi from "../../api/caterories/getCategoriesApi";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { optionsTag } from "../../../utils/constants";
import getAttributesApi from "../../api/attributes/getAttributesApi";
import { ChooseFileWrapper } from "../../../shared/ChooseFileWrapper/ChooseFileWrapper";
import updateCategories from "../../api/caterories/updateCategories";

type ModalAddCategoryT = {
  closeFunc: any;
  data: any;
};
export const ModalAddCategory: FC<ModalAddCategoryT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const text = "Add category";
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState(data.name);
  const [thumbnailValue, setThumbnailValue] = useState();

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditProject() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    if (nameValue === "" || !thumbnailValue) {
      popUp({
        type: "failure",
        message: "The name and thumbnail field must not be empty",
      });
    } else {
      if (data.id) {
        // редагування

        const values: any = {
          name: nameValue,
          projectId: data.projectId,
          id: data.id,
        };
        if (thumbnailValue && typeof thumbnailValue !== "string") {
          values.image = thumbnailValue;
        }
        console.log("values: ", values);

        const newCategory = await updateCategories(values);
        if (newCategory) {
          const categories = await getCategoriesApi(data.projectId);
          dispatch(setCategories({ categories: categories.rows }));

          // const newArr: any = [newCategory.id];
          // const newAttr = await createAttributeApi({
          //   values: {
          //     categories: newArr,
          //   },
          //   urlParam: `${data.productId}/categories`,
          //   typeSend: "json",
          //   url: optionsTag["products"].create.url,
          //   request: optionsTag["products"].create.request,
          // });
          // if (newAttr) {
          const info = await getAttributesApi({
            url: optionsTag["products"].get.url,
            request: optionsTag["products"].get.request,
            filter: `/${data.productId}?page=1&pageSize=500`,
          });
          if (info) {
            closeFunc();
            dispatch(
              setItemsAttribute({
                itemId: data.productId,
                attrName: "products",
                attrValues: info,
              })
            );
          }
          // }
        }
      } else {
        // створення
        const newCategory = await createCategories({
          name: nameValue,
          image: thumbnailValue,
          projectId: data.projectId,
        });
        if (newCategory) {
          const categories = await getCategoriesApi(data.projectId);
          dispatch(setCategories({ categories: categories.rows }));

          const newArr: any = [newCategory.id];
          const newAttr = await createAttributeApi({
            values: {
              categories: newArr,
            },
            urlParam: `${data.productId}/categories`,
            typeSend: "json",
            url: optionsTag["products"].create.url,
            request: optionsTag["products"].create.request,
          });
          if (newAttr) {
            const info = await getAttributesApi({
              url: optionsTag["products"].get.url,
              request: optionsTag["products"].get.request,
              filter: `/${data.productId}?page=1&pageSize=500`,
            });
            if (info) {
              closeFunc();
              dispatch(
                setItemsAttribute({
                  itemId: data.productId,
                  attrName: "products",
                  attrValues: info,
                })
              );
            }
          }
        }
      }
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <InputWrapper
          name="name"
          setValue={setNameValue}
          value={nameValue}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />
        <ChooseFileWrapper
          name="Thumbnail"
          placeholder="PNG"
          setValue={setThumbnailValue}
          value={thumbnailValue}
          linkImage={data.image && data.image}
          isRequired={true}
          accept=".png"
        />
        <div className={s.hint}>
          <div className={s.red}>Please note: </div>
          The thumbnail will be displayed only in the configurator
        </div>
        <ButtonWrapper
          clickEvent={addOrEditProject}
          text="Save category"
          type="primary"
        />
      </div>
    </div>
  );
};
