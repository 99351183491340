import { useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useDispatch } from "react-redux";
import store from "../../redux/store";

function WebSocketComponent() {
  //@ts-ignore
  const [pingInterval, setPingInterval]: any = useState(null);
  //@ts-ignore
  const [pingTimeout, setPingTimeout]: any = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const socketUrl = `wss://renderadmin-dev.vivid3d.tech?token=zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP`;
    // const socketUrl = `wss://renderapi-3d.vivid3d.tech/?token=zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP`;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    const baseReconnectInterval = 1000;
    const createWebSocket = () => {
      const socket = new ReconnectingWebSocket(socketUrl);
      socket.onopen = () => {
        console.log("Соединение установлено");
        // dispatch(setWsConnected({ status: true }));
        reconnectAttempts = 0;
        startPingInterval();
      };

      socket.onmessage = (event: any) => {
        // const hash = store.getState().ui.renderHash;
        // const serverStatus = store.getState().ui.serverStatus;
        const data = JSON.parse(event.data);
        const type = data.type;
        console.log("data: ", data);
        if (type === "pong") {
          stopPingInterval();
          startPingInterval();
        } else if (type === "massrenderStatsResponse") {
          // const test = {
          //   type: "massrenderStatsResponse",
          //   renderJob: {
          //     id: 25,
          //     layersTotal: 0,
          //     configurationsTotal: 81,
          //     renderStats: {
          //       layers: { pending: 0, success: 0, processing: 0 },
          //       configurations: { pending: 34, success: 45, processing: 2 },
          //     },
          //     createdAt: "2024-05-09T08:32:13.338Z",
          //     updatedAt: "2024-05-09T08:33:46.504Z",
          //   },
          // };
        }
      };

      socket.onclose = () => {
        reconnectAttempts++;

        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            stopPingInterval();
            createWebSocket();
          }, baseReconnectInterval);
        } else {
          console.log(
            "Достигнуто максимальное количество попыток переподключения"
          );
          stopPingInterval();
          socket.close();
        }
        console.log("Соединение закрыто");
      };

      return socket;
    };

    const sendPing = () => {
      if (socket) {
        socket.send(JSON.stringify({ type: "ping", projectId: 1 }));
      }
    };

    const socket: any = createWebSocket();

    const startPingInterval = () => {
      const intervalId = setInterval(sendPing, 4000);
      setPingInterval(intervalId);

      const timeoutId = setTimeout(() => {
        console.log("Сервер не ответил на ping, разрываем соединение");
        stopPingInterval();
        socket.close();
      }, 12000);
      setPingTimeout(timeoutId);
    };
    const stopPingInterval = () => {
      setPingInterval((prev: any) => {
        clearInterval(prev);
        return prev;
      });
      setPingTimeout((prev: any) => {
        clearTimeout(prev);
        return prev;
      });
    };

    return () => {
      // if (socket.readyState === 1) {
      stopPingInterval();
      socket.close();
      // }
    };
  }, []);

  return <></>;
}

export default WebSocketComponent;
