import { projectsListT } from "../../redux/reducers/types";

export const addOrUpdateItemAttribute = ({
  projectsList,
  attrName,
  attrValue,
  attrId,
  itemId,
  projectId,
  type,
  typeValue,
}: any) => {
  const newProjects: projectsListT[] = [];
  if (type === "update") {
    projectsList.map((project: projectsListT) => {
      if (project.id === projectId) {
        //@ts-ignore
        const allCurentOptions = project.attributesList[attrName];
        const actualRows: any = [];
        allCurentOptions.rows.map((i: any) => {
          const condition = typeValue
            ? typeValue === i.typeValue && i.id === attrId
            : i.id === attrId;
          if (condition) {
            const allCurentItems = i.items;
            const actualItems: any = [];
            allCurentItems.map((item: any) => {
              if (item.id === itemId) {
                actualItems.push({
                  ...item,
                  ...attrValue,
                });
              } else {
                actualItems.push(item);
              }
            });
            actualRows.push({
              ...i,
              cache: true,
              items: actualItems,
            });
          } else {
            actualRows.push(i);
          }
        });
        newProjects.push({
          ...project,
          attributesList: {
            ...project.attributesList,
            [attrName]: {
              ...allCurentOptions,
              rows: actualRows,
            },
          },
        });
      } else {
        newProjects.push(project);
      }
    });
  } else if (type === "add") {
    projectsList.map((project: projectsListT) => {
      if (project.id === projectId) {
        //@ts-ignore
        const allCurentOptions = project.attributesList[attrName];
        const actualRows: any = [];
        allCurentOptions.rows.map((i: any) => {
          const condition = typeValue
            ? typeValue === i.typeValue && i.id === attrId
            : i.id === attrId;
          console.log("i: ", i);
          console.log("condition: ", condition);
          console.log("typeValue: ", typeValue);
          if (condition) {
            const allCurentItems = i.items;
            console.log("allCurentItems: ", allCurentItems);
            console.log("attrValue: ", attrValue);
            actualRows.push({
              ...i,
              cache: attrValue.cache === false ? false : true,
              items: [...allCurentItems, attrValue],
            });
          } else {
            actualRows.push(i);
          }
        });
        console.log("actualRows: ", actualRows);

        newProjects.push({
          ...project,
          attributesList: {
            ...project.attributesList,
            [attrName]: {
              ...allCurentOptions,
              rows: actualRows,
            },
          },
        });
      } else {
        newProjects.push(project);
      }
    });
  }

  return newProjects;
};
