import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../../processes/sessionStorage/getAuthToken";
import { checkTokenAndRefresh } from "../../../features/functions/checkTokenAndRefresh";
import { PAGES } from "../../../utils/constants";

export const useAuthInterval = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const checkInterval = 30000; // 30 секунд в миллисекундах
      const authInfo: any = getAuthToken();
      let timerId: any;
  
      if (authInfo) {
        checkTokenAndRefresh();
        timerId = setInterval(() => {
          checkTokenAndRefresh();
        }, checkInterval);
      } else {
        navigate(PAGES.LOGIN);
        timerId = setInterval(() => {}, checkInterval);
      }
  
      return () => {
        clearTimeout(timerId);
      };
    }, [navigate]);
  };