import axios from "axios";
import { popUp } from "../../../features/popUp/PopUp";
import { deleteAuthToken } from "../../sessionStorage/deleteAuthToken";

const CreateCombinationApi = async (
  productId: any,
  id: number,
  data: any,
  type: any
) => {
  // popUp({ type: "info", message: "Loading..." });
  // return await axios

  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/products/${productId}/${type}/${id}/dependencies`,
      JSON.stringify({
        dependencies: data
      }),
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response: any) => {
      console.log("response: ", response);
      if (response) {
        // popUp({
        //   type: "success",
        //   message: "Login successful",
        // });
        return response;
      } else {
        popUp({
          type: "failure",
          message: "Oops! There was a problem in mass Render Api",
        });
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      const dataError = error.response.data.errors;
      deleteAuthToken();
      // window.location.href = "/login";
      if (dataError) {
        dataError.map((err: any) => {
          popUp({ type: "failure", message: err.message });
        });
      } else {
        popUp({ type: "failure", message: "Oh, something went wrong!" });
      }
    });
};

export default CreateCombinationApi;
