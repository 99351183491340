import { deleteItemsAttributeEvent } from "../../../features/functions/deleteItemsAttributeEvent";
import { duplicateItemsAttributeEvent } from "../../../features/functions/duplicateItemsAttributeEvent";
import { loadImageFromURL } from "../../../features/functions/loadImageFromURL";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";
import { toggleEnableItemsAttribute } from "../../../features/functions/toggleEnableItemsAttribute";
import { changeShowModal } from "../../../redux/actions";
import { groupNamesT, optionsTag } from "../../../utils/constants";

const handleAddItemAttribute = async ({ environmentData, dispatch }: any) => {
  dispatch(
    changeShowModal({
      nameModal: "addEnvironmentVariant",
      stateModal: true,
      otherParams: {
        attrId: environmentData.id,
        name: "",
        image: undefined,
      },
    })
  );
};

const handleEnableItemAttribute = async ({
  item,
  environmentData,
  dispatch,
}: any) => {
  await toggleEnableItemsAttribute({
    id: item.id,
    attrId: environmentData.id,
    name: item.name,
    // otherValues: { image: item.image },
    nameAttr: "environment",
    tagName: "environmentVariants",
    enabled: !item.enabled,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};
const handleDeleteItemAttribute = async ({
  item,
  environmentData,
  dispatch,
}: any) => {
  await deleteItemsAttributeEvent({
    id: environmentData.id,
    itemId: item.id,
    attrName: "environment",
    attrTag: "environmentVariants",
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};

const handleDuplicateItemAttribute = async ({
  item,
  environmentData,
  dispatch,
}: any) => {
  await duplicateItemsAttributeEvent({
    name: item.name,
    itemId: item.id,
    attrId: environmentData.id,
    count: environmentData.items.length,
    otherValues: {
      optionId: item["environmentOptionId"],
      image: await loadImageFromURL(item.image),
    },
    nameAttr: "environment",
    url: optionsTag["environmentVariants"].create.url,
    request: optionsTag["environmentVariants"].create.request,
    dispatch,
  });
  loadItemsProductInState({ dispatch });
};
const handleEditAttribute = async ({
  item,
  environmentData,
  dispatch,
}: any) => {
  dispatch(
    changeShowModal({
      nameModal: "addEnvironmentVariant",
      stateModal: true,
      otherParams: {
        attrId: item["environmentOptionId"],
        name: item.name,
        image: item.image,
        id: item.id,
      },
    })
  );
};

export {
  handleAddItemAttribute,
  handleEnableItemAttribute,
  handleDeleteItemAttribute,
  handleDuplicateItemAttribute,
  handleEditAttribute,
};
