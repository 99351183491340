import { projectsListT } from "../../redux/reducers/types";

export const deleteAttributeInState = ({
  projectsList,
  attrName,
  attrId,
  projectId,
}: any) => {
  const newProjects: projectsListT[] = [];
  projectsList.map((project: projectsListT) => {
    if (project.id === projectId) {
      const newCurentOption: any = [];
      //@ts-ignore
      const allCurentOptions = project.attributesList[attrName];
      allCurentOptions.rows.map((attr: any) => {
        if (attr.id === attrId) {
        } else {
          newCurentOption.push(attr);
        }
      });
      newProjects.push({
        ...project,
        attributesList: {
          ...project.attributesList,
          [attrName]: {
            count: allCurentOptions.count - 1,
            cache: true,
            rows: newCurentOption,
          },
        },
      });
    } else {
      newProjects.push(project);
    }
  });

  return newProjects;
};
