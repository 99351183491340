import { FC } from "react";

export const ArrowLink: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.33299 3.66699H12.333M12.333 3.66699V9.66699M12.333 3.66699L3.84766 12.1523"
        stroke="#343A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
