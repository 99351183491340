import { apiRoutesValueT } from "../constants";

export type enironmentVariantRoutesNameT =
  | "GET_ENVIRONMENT_VARIANTS"
  | "UPDATE_ENVIRONMENT_VARIANT"
  | "CREATE_ENVIRONMENT_VARIANT"
  | "DELETE_ENVIRONMENT_VARIANT"
  | "RETRIEVE_ENVIRONMENT_VARIANT";
type enironmentVariantRoutesT = Record<
  enironmentVariantRoutesNameT,
  apiRoutesValueT
>;

const api = process.env.REACT_APP_API_URL;

export const enironmentVariantRoutes: enironmentVariantRoutesT = {
  GET_ENVIRONMENT_VARIANTS: {
    url: `${api}/environmentVariants`,
    request: "get",
  },
  CREATE_ENVIRONMENT_VARIANT: {
    url: `${api}/environmentVariants`,
    request: "post",
  },
  UPDATE_ENVIRONMENT_VARIANT: {
    url: `${api}/environmentVariants`,
    request: "put",
  },
  DELETE_ENVIRONMENT_VARIANT: {
    url: `${api}/environmentVariants`,
    request: "delete",
  },
  RETRIEVE_ENVIRONMENT_VARIANT: {
    url: `${api}/environmentVariants`,
    request: "get",
  },
};
