import {
  categoriesRoutes,
  categoriesRoutesNameT,
} from "./apiRoutes/categoriesRoutes";
import {
  collectionsRoutes,
  collectionsRoutesNameT,
} from "./apiRoutes/collectionsRoutes";
import { optionsRoutes, optionsRoutesNameT } from "./apiRoutes/optionsRoutes";
import { loginRoutes, loginRoutesNameT } from "./apiRoutes/loginRoutes";
import { projectRoutes, projectRoutesNameT } from "./apiRoutes/projectRoutes";
import {
  attributeItemsNamesT,
  attributesNamesT,
} from "../redux/reducers/types";
import { stagesRoutes, stagesRoutesNameT } from "./apiRoutes/stagesRoutes";
import {
  productsRoutes,
  productsRoutesNameT,
} from "./apiRoutes/productsRoutes";
import {
  variantsRoutesNameT,
  variantsRoutes,
} from "./apiRoutes/variantsRoutes";

import { camerasRoutesNameT, camerasRoutes } from "./apiRoutes/camerasRoutes";
import {
  enironmentOptionRoutes,
  enironmentOptionRoutesNameT,
} from "./apiRoutes/enironmentOptionRoutes";
import {
  enironmentVariantRoutes,
  enironmentVariantRoutesNameT,
} from "./apiRoutes/enironmentVariantRoutes";

export const TYPE_REDUCER = {
  SET_MODAL: "Set modal in state",
  ADD_PROJECTS: "Add all projects",
  DELETE_PROJECT: "delete project",
  UPDATE_PROJECTS: "update project by name",
  ADD_NEW_PROJECT: "add new project",
  SET_ACTIVE_PROJECT: "set active project",
  SET_ACTIVE_TAB: "set active tab",
  SET_ATTRIBUTE: "set attribute by name",
  ADD_OR_UPDATE_ATTRIBUTE: "add or update attribute",
  DELETE_ATTRIBUTE: "delete attribute",
  SET_ITEMS_ATTRIBUTE: "set items attr",
  ADD_OR_UPDATE_ITEMS_ATTRIBUTE: "add or update items attribute",
  DELETE_ITEM_ATTRIBUTE: "delete item in Attribute",
  SET_CATEGORIES: "set all categories",
  SET_JOBS: "set jobs",
};
export type requestT = "get" | "post" | "put" | "delete" | "patch";

export type apiRoutesNamesT =
  | loginRoutesNameT
  | projectRoutesNameT
  | optionsRoutesNameT
  | stagesRoutesNameT
  | collectionsRoutesNameT
  | productsRoutesNameT
  | variantsRoutesNameT
  | enironmentOptionRoutesNameT
  | enironmentVariantRoutesNameT
  | camerasRoutesNameT
  | categoriesRoutesNameT;
export type apiRoutesValueT = {
  url: string;
  request: requestT;
};
export type apiRoutesT = Record<apiRoutesNamesT, apiRoutesValueT>;

type PagesNameT =
  | "PROJECTS"
  | "LOGIN"
  | "OPTIONS"
  | "STAGES"
  | "COLLECTIONS"
  | "ENVIRONMENT"
  | "PRODUCTS";
type PAGES_T = Record<PagesNameT, string>;
export const PAGES: PAGES_T = {
  PROJECTS: "/",
  LOGIN: "/login",
  OPTIONS: "/options",
  STAGES: "/stages",
  COLLECTIONS: "/collections",
  ENVIRONMENT: "/environment",
  PRODUCTS: "/products",
};
export const apiRoutes: apiRoutesT = {
  ...loginRoutes,
  ...projectRoutes,
  ...optionsRoutes,
  ...stagesRoutes,
  ...collectionsRoutes,
  ...enironmentOptionRoutes,
  ...enironmentVariantRoutes,
  ...productsRoutes,
  ...variantsRoutes,
  ...camerasRoutes,
  ...categoriesRoutes,
};
export type groupNamesT =
  | attributesNamesT
  | attributeItemsNamesT
  | additionalNamesT;

export type additionalNamesT =
  | "geometryOptions"
  | "geometryVariants"
  | "environmentVariants";
type optionsTagT = Record<groupNamesT, optionTagValue>;
type tagNamesT = "get" | "update" | "create" | "delete";

type ConditionalRecord<K extends string, T> = {
  [P in K as P extends "massRender" | "getJobs" | "startRender" ? never : P]: T;
} & {
  massRender?: T;
  getJobs?: T;
  startRender?: T;
};

type optionTagValue = ConditionalRecord<tagNamesT, apiRoutesValueT>;
export const optionsTag: optionsTagT = {
  options: {
    get: apiRoutes.GET_OPTIONS,
    update: apiRoutes.UPDATE_OPTION,
    create: apiRoutes.CREATE_OPTION,
    delete: apiRoutes.DELETE_OPTION,
  },
  environment: {
    get: apiRoutes.GET_ENVIRONMENT_OPTIONS,
    update: apiRoutes.UPDATE_ENVIRONMENT_OPTION,
    create: null!,
    delete: null!,
  },
  environmentVariants: {
    get: apiRoutes.GET_ENVIRONMENT_VARIANTS,
    update: apiRoutes.UPDATE_ENVIRONMENT_VARIANT,
    create: apiRoutes.CREATE_ENVIRONMENT_VARIANT,
    delete: apiRoutes.DELETE_ENVIRONMENT_VARIANT,
  },
  geometryOptions: {
    get: apiRoutes.GET_GEOMETRY_OPTIONS,
    update: apiRoutes.UPDATE_GEOMETRY_OPTION,
    create: apiRoutes.CREATE_GEOMETRY_OPTION,
    delete: apiRoutes.DELETE_GEOMETRY_OPTION,
  },
  stages: {
    get: apiRoutes.GET_STAGES,
    update: apiRoutes.UPDATE_STAGE,
    create: apiRoutes.CREATE_STAGE,
    delete: apiRoutes.DELETE_STAGE,
  },
  collections: {
    get: apiRoutes.GET_COLLECTIONS,
    update: apiRoutes.UPDATE_COLLECTION,
    create: apiRoutes.CREATE_COLLECTION,
    delete: apiRoutes.DELETE_COLLECTION,
  },
  products: {
    get: apiRoutes.GET_PRODUCTS,
    update: apiRoutes.UPDATE_PRODUCT,
    create: apiRoutes.CREATE_PRODUCT,
    delete: apiRoutes.DELETE_PRODUCT,
    massRender: apiRoutes.MASS_RENDER,
    getJobs: apiRoutes.GET_JOBS,
    startRender: apiRoutes.START_RENDER,
  },

  //items
  variants: {
    get: apiRoutes.GET_VARIANTS,
    update: apiRoutes.UPDATE_VARIANT,
    create: apiRoutes.CREATE_VARIANT,
    delete: apiRoutes.DELETE_VARIANT,
  },
  geometryVariants: {
    get: apiRoutes.GET_GEOMETRY_VARIANTS,
    update: apiRoutes.UPDATE_GEOMETRY_VARIANT,
    create: apiRoutes.CREATE_GEOMETRY_VARIANT,
    delete: apiRoutes.DELETE_GEOMETRY_VARIANT,
  },
  cameras: {
    get: apiRoutes.GET_CAMERAS,
    update: apiRoutes.UPDATE_CAMERA,
    create: apiRoutes.CREATE_CAMERA,
    delete: apiRoutes.DELETE_CAMERA,
  },
  categories: {
    get: apiRoutes.GET_CATEGORIES,
    update: apiRoutes.UPDATE_CATEGORY,
    create: apiRoutes.CREATE_CATEGORY,
    delete: apiRoutes.DELETE_CATEGORY,
  },
};
