import { useDispatch, useSelector } from "react-redux";
import { ArrowLink } from "../../../../assets/svg/ArrowLink";
import { CloseSecond } from "../../../../assets/svg/CloseSecond";
import { setJobs } from "../../../../redux/actions";
import s from "./AllRenders.module.scss";
import { useEffect } from "react";
import getRenderJobsApi from "../../../../processes/api/products/getRenderJobsApi";
import { getJobsByProductId } from "../../../../redux/selectors/selectors";

export const AllRenders = ({ activeModal, setActiveModal, id }: any) => {
  const dispatch = useDispatch();
  const isActive = activeModal === "renderAll";
  const allJobs = useSelector(getJobsByProductId(id));
  async function getJobs() {
    const jobs = await getRenderJobsApi(id);
    dispatch(
      setJobs({
        jobs: jobs.data.rows,
        productId: id,
      })
    );
  }

  useEffect(() => {
    if (isActive) {
      getJobs();
    }
  }, [activeModal]);
  return (
    <div
      className={`${s.wrapper} ${activeModal !== false && s.hide} ${
        isActive && s.active
      }`}
    >
      <div className={s.head}>
        <div className={s.title}>View all renders</div>
        <div
          className={`${s.btn} ${isActive && s.close}`}
          onClick={() => {
            if (isActive) {
              setActiveModal(false);
            } else {
              setActiveModal("renderAll");
            }
          }}
        >
          {isActive ? (
            <>
              <CloseSecond />
              <span>Close</span>
            </>
          ) : (
            <>
              <ArrowLink />
              <span>Open</span>
            </>
          )}
        </div>
      </div>
      {isActive && <div className={s.content}>123</div>}
    </div>
  );
};
