import { useEffect, useState } from "react";
import { ArrowLink } from "../../../../assets/svg/ArrowLink";
import { CloseSecond } from "../../../../assets/svg/CloseSecond";
import s from "./CombiningOptions.module.scss";
import { CombinationList } from "./components/CombinationList/CombinationList";
import { optionsTag } from "../../../../utils/constants";
import getAttributesApi from "../../../../processes/api/attributes/getAttributesApi";
import { markDuplicates } from "./functions/markDuplicates";
import { getUniqueObjects } from "./functions/getUniqueObjects";

export const CombiningOptions = ({ activeModal, setActiveModal, id }: any) => {
  const isActive = activeModal === "CombiningOptions";
  const [data, setData] = useState([]);
  const [listOptions, setListOptions] = useState([]);
  const [allOptionsProductData, setAllOptionsProductData]: any = useState([]);
  const [unusedData, setUnusedData]: any = useState([]);

  useEffect(() => {
    const allList: any = [];
    listOptions.map((l: any) => {
      allList.push(...l.data);
    });
    const uniqueObjects: any = getUniqueObjects(allList, data);
    setUnusedData(uniqueObjects);
  }, [listOptions]);
  useEffect(() => {
    async function getProduct() {
      const info = await getAttributesApi({
        url: optionsTag["products"].get.url,
        request: optionsTag["products"].get.request,
        filter: `/${id}?page=1&pageSize=500`,
      });
      const result: any = [];
      info.availableOptions.map((item: any) => {
        result.push({
          ...item,
          typeValue: "material",
        });
      });
      info.availableGeometryOptions.map((item: any) => {
        result.push({
          ...item,
          typeValue: "geometry",
        });
      });
      setAllOptionsProductData(result);
    }
    getProduct();
  }, []);
  useEffect(() => {
    if (allOptionsProductData) {
      const options: any = [];
      allOptionsProductData.map((option: any) => {
        const typeVar =
          option.typeValue === "material" ? "optionId" : "geometryOptionId";
        options.push({
          // id: option[typeVar],
          id: option.id,
          type: option.typeValue,
          name: option.proxyName ? option.proxyName : option.name,
          isRepeat: false,
        });
      });
      setData(options);
    }
  }, [allOptionsProductData]);

  useEffect(() => {
    const startList: any = [];
    data.map((option: any, index: number) => {
      const activeData = allOptionsProductData.find(
        (val: any) => val.id === option.id && val.typeValue === option.type
      );
      const newData = [option];
      if (activeData.dependencies.length >= 1) {
        activeData.dependencies.map((i: any) => {
          const activeList = data.find(
            (opt: any) => opt.id === i.id && opt.type === i.type
          );
          if (activeList) {
            newData.push(activeList);
          }
        });
      }
      startList.push({
        index: index + 1,
        data: newData,
      });
    });
    const updatedDataArray: any = markDuplicates(startList);
    setListOptions(updatedDataArray);
  }, [data]);

  return (
    <div
      className={`${s.wrapper} ${activeModal !== false && s.hide} ${
        isActive && s.active
      }`}
    >
      <div className={s.head}>
        <div className={s.title}>
          {isActive ? "Combining options" : "Options"}
        </div>
        <div className={s.wrap}>
          {!isActive && <div className={s.hint}>Combining options</div>}
          <div
            className={`${s.btn} ${isActive && s.close}`}
            onClick={() => {
              if (isActive) {
                setActiveModal(false);
              } else {
                setActiveModal("CombiningOptions");
              }
            }}
          >
            {isActive ? (
              <>
                <CloseSecond />
                <span>Close</span>
              </>
            ) : (
              <>
                <ArrowLink />
                <span>Open</span>
              </>
            )}
          </div>
        </div>
      </div>
      {isActive && (
        <div className={s.content}>
          <div className={`${s.items} customScroll`}>
            {listOptions.map((list: any, index: number) => {
              return (
                <CombinationList
                  key={`list_${index}`}
                  data={data}
                  listOptions={listOptions}
                  option={list}
                  setListOptions={setListOptions}
                  id={id}
                />
              );
            })}
          </div>
          <div className={s.unused}>
            <div className={s.label}>Unused options</div>
            <div className={s.unusedItems}>
              {unusedData.map((item: any, index: number) => {
                return (
                  <div
                    className={s.item}
                    key={`unUsedItems_${index}`}
                    onClick={() => {
                      const updatedList = [
                        ...listOptions,
                        {
                          index: listOptions.length + 1,
                          data: [item],
                        },
                      ];
                    const updatedDataArray: any = markDuplicates(updatedList);
                      setListOptions(updatedDataArray);
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
