import axios from "axios";
import { apiRoutes, requestT } from "../../../utils/constants";
import { popUp } from "../../../features/popUp/PopUp";
import { getAuthToken } from "../../sessionStorage/getAuthToken";
type deleteAttributeApiT = {
  id: any;
  url: string;
  request: requestT;
  values?: {
    name?: string;
    enabled?: boolean;
    projectId?: number;
    [key: string]: any;
  };
};
const deleteAttributeApi = async ({
  id,
  url,
  request,
  values,
}: deleteAttributeApiT) => {
  const authInfo: any = getAuthToken();
  const data: any = JSON.parse(authInfo);

  // return await axios[request](`${url}/${id}`, {
  //   headers: {
  //     // "Content-Type": "multipart/form-data",
  //     Authorization: `Bearer ${data.token}`,
  //     "Content-Type": "application/json",
  //   },
  // })
  let actualValues: any = JSON.stringify({ ...values });
  return await fetch(`${url}/${id}`, {
    method: request, // или другой метод запроса
    body: actualValues,
    headers: {
      Authorization: `Bearer ${data.token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json()) // или другой метод обработки ответа
    .then((response: any) => {
      if (response) {
        return response;
      } else {
        popUp({
          type: "failure",
          message: "Oops! There was a problem in deleteAttributeApi",
        });
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      const dataError = error.response.data.errors;
      if (dataError) {
        dataError.map((err: any) => {
          popUp({ type: "failure", message: err.message });
        });
      } else {
        popUp({ type: "failure", message: "Oh, something went wrong!" });
      }
    });
};

export default deleteAttributeApi;
