import { FC } from "react";
import s from "./EnvironmentEditAttr.module.scss";
import { BackArrow } from "../../assets/svg/BackArrow";
import { ToggleBtn } from "../../shared/ToggleBtn/ToggleBtn";
import { useDispatch, useSelector } from "react-redux";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import { getItemAttributeById } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";

import { useUpdateActualData } from "./hooks/useUpdateActualData";
import {
  handleAddItemAttribute,
  handleDeleteItemAttribute,
  handleDuplicateItemAttribute,
  handleEditAttribute,
  handleEnableItemAttribute,
} from "./functions/environmentEditAttrEvents";

type EnvironmentEditAttrT = {
  setEditAttr: any;
  editAttr: any;
};

export const EnvironmentEditAttr: FC<EnvironmentEditAttrT> = ({ ...props }) => {
  const { setEditAttr, editAttr } = props;
  const dispatch = useDispatch();
  const environmentData = useSelector(
    getItemAttributeById({ name: "environment", id: editAttr.id })
  );

  useUpdateActualData({ environmentData, dispatch });
  if (!environmentData) {
    setEditAttr(false);
  }

  return (
    <>
      {environmentData && (
        <div className={s.edit}>
          <div className={s.head}>
            <div className={s.back} onClick={() => setEditAttr(false)}>
              <BackArrow />
              Back to environments list
            </div>
            <div className={s.content}>
              <div className={s.name}>{environmentData.name}</div>
            </div>
            <div className={s.add}>
              <ButtonWrapper
                text="Add new variant"
                clickEvent={() =>
                  handleAddItemAttribute({ environmentData, dispatch })
                }
                type="outlane"
                icon={true}
              />
            </div>
          </div>
          <div className={s.items}>
            {environmentData.items.length >= 1 ? (
              <>
                {environmentData.items.map((item: any, index: number) => {
                  return (
                    <ItemStandart
                      key={`item_attr_env_${index}`}
                      eneble={item.enabled}
                      img={item.image}
                      id={item.id}
                      title={item.name}
                      setEneble={async () =>
                        handleEnableItemAttribute({
                          item,
                          environmentData,
                          dispatch,
                        })
                      }
                      deleteEvent={async () =>
                        handleDeleteItemAttribute({
                          item,
                          environmentData,
                          dispatch,
                        })
                      }
                      duplicateEvent={async () =>
                        handleDuplicateItemAttribute({
                          item,
                          environmentData,
                          dispatch,
                        })
                      }
                      editEvent={() =>
                        handleEditAttribute({ item, environmentData, dispatch })
                      }
                    />
                  );
                })}
              </>
            ) : (
              <NoneData text="/// No variants available yet ///" />
            )}
          </div>
        </div>
      )}
    </>
  );
};
