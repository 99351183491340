import { apiRoutesValueT } from "../constants";

export type projectRoutesNameT =
  | "GET_PROJECTS"
  | "UPDATE_PROJECT"
  | "CREATE_PROJECT"
  | "DELETE_PROJECT"
  | "RETRIEVE_PROJECT";
type projectRoutesT = Record<projectRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const projectRoutes: projectRoutesT = {
  GET_PROJECTS: {
    url: `${api}/projects`,
    request: "get",
  },
  CREATE_PROJECT: {
    url: `${api}/projects`,
    request: "post",
  },
  UPDATE_PROJECT: {
    url: `${api}/projects`,
    request: "put",
  },
  DELETE_PROJECT: {
    url: `${api}/projects`,
    request: "delete",
  },
  RETRIEVE_PROJECT: {
    url: `${api}/projects`,
    request: "get",
  },
};

