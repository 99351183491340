interface DataObject {
  id: number;
  type: string;
  name: string;
  isRepeat: boolean;
}

export function getUniqueObjects(
  existingData: DataObject[],
  allData: DataObject[]
): DataObject[] {
  // Создаем множество для хранения уникальных комбинаций id и type из существующих данных
  const existingSet = new Set(
    existingData.map((obj) => `${obj.id}-${obj.type}`)
  );

  // Фильтруем все данные, оставляя только те объекты, которых нет в существующем множестве
  const uniqueData = allData.filter(
    (obj) => !existingSet.has(`${obj.id}-${obj.type}`)
  );

  return uniqueData;
}
