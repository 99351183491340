import { FC } from "react";

export const RenderingIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 2V4M17 3.34L16 5.072M20.66 7L18.928 8M22 12H20M20.66 17L18.928 16M17 20.66L16 18.929M12 22V20M7 20.66L8 18.929M3.34 17L5.072 16M2 12H4M3.34 7L5.072 8M7 3.34L8 5.072"
        stroke="#343A40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
