import { FC } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { LoginWidget } from "../../widgets/LoginWidget/LoginWidget";

export const Login: FC = () => {
  return (
    <>
      <div className={s.wrapper}>
        <LoginWidget />
      </div>
      <MobileHint />
    </>
  );
};
