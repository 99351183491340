import { apiRoutesValueT } from "../constants";

export type enironmentOptionRoutesNameT =
  | "GET_ENVIRONMENT_OPTIONS"
  | "UPDATE_ENVIRONMENT_OPTION";
type enironmentOptionRoutesT = Record<
  enironmentOptionRoutesNameT,
  apiRoutesValueT
>;

const api = process.env.REACT_APP_API_URL;

export const enironmentOptionRoutes: enironmentOptionRoutesT = {
  GET_ENVIRONMENT_OPTIONS: {
    url: `${api}/environmentOptions`,
    request: "get",
  },
  UPDATE_ENVIRONMENT_OPTION: {
    url: `${api}/environmentOptions`,
    request: "put",
  },
};
