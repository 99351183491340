import { useEffect } from "react";
import { setActiveProject } from "../../../redux/actions";

export const useActiveProjectFromUrl = (dispatch: any) => {
  const pathname = window.location.pathname;

  useEffect(() => {
    const parts = pathname.split("/");
    const idProject = parts[1];
    if (idProject) {
      dispatch(setActiveProject({ id: Number(idProject) }));
    }
  }, [pathname, dispatch]);
};
