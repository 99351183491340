import { FC, useEffect, useState } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { useNavigate } from "react-router-dom";
import { checkTokenAndRefresh } from "../../features/functions/checkTokenAndRefresh";
import { MainLayout } from "../../widgets/MainLayout/MainLayout";
import { HeadProjectInfo } from "../../widgets/HeadProjectInfo/HeadProjectInfo";
import { AttributesTab } from "../../widgets/AttributesTab/AttributesTab";
import { OptionWidget } from "../../widgets/OptionWidget/OptionWidget";
import { useDispatch, useSelector } from "react-redux";
import { changeShowModal } from "../../redux/actions";
import { getActiveProject } from "../../redux/selectors/selectors";
import { OptionEditAttr } from "../../widgets/OptionEditAttr/OptionEditAttr";
import { EnvironmentWidget } from "../../widgets/EnvironmentWidget/EnvironmentWidget";
import { EnvironmentEditAttr } from "../../widgets/EnvironmentEditAttr/EnvironmentEditAttr";

export const Environment: FC = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(getActiveProject);
  const [editAttr, setEditAttr]: any = useState();

  return (
    <>
      <MainLayout>
        <div className={s.wrapper}>
          <HeadProjectInfo />
          {!editAttr ? (
            <div className={s.main}>
              <AttributesTab />
              <EnvironmentWidget
                projectId={projectId}
                setEditAttr={setEditAttr}
              />
            </div>
          ) : (
            <EnvironmentEditAttr setEditAttr={setEditAttr} editAttr={editAttr} />
          )}
        </div>
      </MainLayout>
      <MobileHint />
    </>
  );
};
