import { FC } from "react";
import s from "./ToggleBtn.module.scss";

type ToggleBtnT = {
  eneble: boolean;
  setEneble: any;
  hideText?: any;
};
export const ToggleBtn: FC<ToggleBtnT> = ({ ...props }) => {
  const { eneble, setEneble, hideText } = props;
  return (
    <div className={`${s.wrapper} ${hideText && s.hideText}`}>
      <div className={s.text}>{eneble ? "Enabled" : "Disabled"}</div>
      <div
        className={`${s.toggle} ${eneble && s.active} `}
        onClick={(event) => {
          event.stopPropagation();

          setEneble(eneble);
        }}
      >
        <div className={s.point}></div>
      </div>
    </div>
  );
};
