import { FC } from "react";
import s from "./EnvironmentWidget.module.scss";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeByName } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { handleEditAttribute } from "./functions/optionWidgetEvents";

export const EnvironmentWidget: FC<any> = ({ projectId, setEditAttr }) => {
  const dispatch = useDispatch();
  const environmentData = useSelector(getAttributeByName("environment"));
  //
  return (
    <div className={`${s.wrapper} customScroll`}>
      {environmentData && environmentData.rows.length ? (
        <>
          {environmentData.rows.map((item: any, index: number) => (
            <ItemStandart
              key={`environment_${index}`}
              eneble={item.enabled}
              type={item.typeValue}
              id={item.id}
              mainEvent={() => setEditAttr(item)}
              title={item.name}
              editEvent={() =>
                handleEditAttribute({ item, dispatch, projectId })
              }
            />
          ))}
        </>
      ) : (
        <NoneData text="/// No environments available yet ///" />
      )}
    </div>
  );
};
