import { FC } from "react";

export const Edit: FC = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7313 2.79153C12.7731 2.74958 12.8228 2.7163 12.8775 2.69358C12.9322 2.67087 12.9908 2.65918 13.05 2.65918C13.1093 2.65918 13.1679 2.67087 13.2226 2.69358C13.2773 2.7163 13.327 2.74958 13.3688 2.79153L16.0688 5.49153C16.1107 5.53334 16.144 5.58302 16.1667 5.63772C16.1894 5.69241 16.2011 5.75106 16.2011 5.81028C16.2011 5.86951 16.1894 5.92815 16.1667 5.98285C16.144 6.03755 16.1107 6.08723 16.0688 6.12903L7.06877 15.129C7.02549 15.1719 6.97395 15.2056 6.91727 15.228L2.41727 17.028C2.3355 17.0608 2.24591 17.0688 2.15962 17.0511C2.07333 17.0334 1.99414 16.9907 1.93185 16.9285C1.86957 16.8662 1.82693 16.787 1.80923 16.7007C1.79153 16.6144 1.79954 16.5248 1.83227 16.443L3.63227 11.943C3.65467 11.8864 3.68835 11.8348 3.73127 11.7915L12.7313 2.79153ZM11.8875 4.91028L13.95 6.97428L15.114 5.81028L13.05 3.74628L11.8875 4.91028ZM13.3148 7.61028L11.25 5.54628L5.40002 11.3963V11.6603H5.85002C5.96937 11.6603 6.08383 11.7077 6.16822 11.7921C6.25261 11.8765 6.30002 11.9909 6.30002 12.1103V12.5603H6.75002C6.80912 12.5603 6.86764 12.5719 6.92223 12.5945C6.97683 12.6172 7.02644 12.6503 7.06822 12.6921C7.11001 12.7339 7.14316 12.7835 7.16577 12.8381C7.18838 12.8927 7.20002 12.9512 7.20002 13.0103V13.4603H7.46402L13.3148 7.61028ZM4.52927 12.2678L4.43327 12.363L3.05852 15.8018L6.49727 14.427L6.59252 14.3318C6.50657 14.2998 6.43248 14.2422 6.38019 14.1669C6.3279 14.0915 6.29993 14.002 6.30002 13.9103V13.4603H5.85002C5.79093 13.4603 5.73241 13.4486 5.67782 13.426C5.62322 13.4034 5.57361 13.3703 5.53183 13.3285C5.49004 13.2867 5.45689 13.2371 5.43428 13.1825C5.41166 13.1279 5.40002 13.0694 5.40002 13.0103V12.5603H4.95002C4.85846 12.5601 4.76913 12.532 4.69395 12.4798C4.61877 12.4275 4.56132 12.3536 4.52927 12.2678Z"
        fill="#7D7D8A"
      />
    </svg>
  );
};
