import { FC, useId, useState, useEffect, useRef } from "react";
import s from "./SelectOptionProxy.module.scss";
import { ArrowBottom } from "../../assets/svg/ArrowBottom";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { v4 as uuidv4 } from "uuid";

function updateArray(array: any, id: any, newValues: any) {
  const newArray = array.map((item: any) => {
    if (item.id === id) {
      return {
        ...item,
        ...newValues,
      };
    }
    return item;
  });
  return newArray;
}

type SelectOptionProxyT = {
  name: string;
  setValue: any;
  value: any;
  list: any[];
  setListType: any;
  isRequired?: boolean;
  placeholder?: string;
  addClassName?: any;
  deleteEvent?: any;
  addEvent?: any;
};
export const SelectOptionProxy: FC<SelectOptionProxyT> = ({ ...props }) => {
  const {
    isRequired = false,
    name,
    setValue,
    value,
    placeholder = "",
    setListType,
    addClassName = false,
    list,
    deleteEvent,
    addEvent,
  } = props;
  const [open, setOpen] = useState(false);
  const [proxyNames, setProxyNames]: any = useState({});
  const [newId, setNewId]: any = useState();
  const wrapperRef: any = useRef(null);
  let classWrapper = `${s.wrapper}`;
  addClassName
    ? (classWrapper += ` ${addClassName}`)
    : (classWrapper = `${s.wrapper}`);
  const inputId = useId();
  function closeFunc() {
    setOpen(false);
  }

  useEffect(() => {
    const id = uuidv4();
    setNewId(id);
    setValue([
      ...value,
      {
        id: id,
        label: undefined,
        optionId: undefined,
        proxyName: "",
      },
    ]);
  }, []);

  // useEffect(() => {
  //   if (value) {
  //     const updValues = value.map((item: any) => {
  //       if (!item.id) {
  //         const id = uuidv4();

  //         return {
  //           ...item,
  //           id: id,
  //         };
  //       }
  //       return item;
  //     });
  //     setValue(updValues);
  //   }
  // }, []);

  // useEffect(() => {
  //   const handleClickOutside =
  //     (wrapperRef: any, closeFunc: any) => (event: any) => {
  //       if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //         closeFunc();
  //       }
  //     };
  //   document.addEventListener(
  //     "mousedown",
  //     handleClickOutside(wrapperRef, closeFunc)
  //   );
  //   return () => {
  //     document.removeEventListener(
  //       "mousedown",
  //       handleClickOutside(wrapperRef, closeFunc)
  //     );
  //   };
  // }, [wrapperRef]);

  return (
    <div className={`${classWrapper} ${open && s.active}`} ref={wrapperRef}>
      <label htmlFor={inputId}>
        {name}
        {isRequired && <span className={s.req}>*</span>}
      </label>
      <div className={s.input} onClick={() => setOpen(!open)}>
        {placeholder}
        <ArrowBottom />
      </div>
      <div className={`${s.content} customScroll`}>
        {list.map((item: any, index: number) => {
          return (
            <div
              className={`${s.item}`}
              key={`select_item_${item.label}_${index}`}
              onClick={() => {
                // const id = uuidv4();
                // setValue([
                //   ...value,
                //   {
                //     id: id,
                //     label: item.label,
                //     optionId: item.value,
                //     proxyName: "",
                //   },
                // ]);
                setListType(item.type);
                setValue(
                  updateArray(value, newId, {
                    label: item.label,
                    optionId: item.value,
                  })
                );

                if (addEvent) {
                  addEvent(item.value);
                }
                closeFunc()
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div className={s.place}></div>
      <div className={`${s.proxyWrapper} customScroll`}>
        {value &&
          value.map((item: any, index: number) => {
            const optionData = list.find((op: any) => op.label === item.label);
            if (newId !== item.id) {
              return false;
            }
            return (
              <div className={s.attrWrapper} key={`proxy_item_${index}`}>
                {optionData && (
                  <div className={s.attrText}>
                    Option: <div className={s.attrOpt}>{optionData.label}</div>
                  </div>
                )}

                <InputWrapper
                  name="Proxy Option Name:"
                  setValue={(val: any) => {
                    setValue(updateArray(value, item.id, { proxyName: val }));
                  }}
                  value={item.proxyName}
                  type="text"
                  disabled={item.disabled}
                  placeholder="Enter Proxy Name"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
