import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddCollectionItem.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  addOrUpdateItemsAttribute,
  changeShowModal,
} from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import updateAttributeApi from "../../api/attributes/updateAttributeApi";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { optionsTag } from "../../../utils/constants";

type ModalAddCollectionItemT = {
  closeFunc: any;
  data: any;
};
type valuesT = {
  name: string;
  position: string;
  rotation: string;
  FOV: string;
  steps: string;
};
export const ModalAddCollectionItem: FC<ModalAddCollectionItemT> = ({
  ...props
}) => {
  const { closeFunc, data } = props;
  const { attrId, itemId, name, position, rotation, FOV, steps } = data;
  const text = itemId ? "Edit camera" : "Add new camera";
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [values, setValues] = useState<valuesT>({
    name,
    position,
    rotation,
    FOV,
    steps,
  });
  const errorsIfNullValue = [
    {
      value: values.name,
      message: "The name field must not be empty",
    },
    {
      value: values.position,
      message: "The position field must not be empty",
    },
    {
      value: values.rotation,
      message: "The rotation field must not be empty",
    },
    {
      value: values.FOV,
      message: "The FOV field must not be empty",
    },
    {
      value: values.steps,
      message: "The steps field must not be empty",
    },
  ];

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditAttribute() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    let errorCounter: number = 0;
    errorsIfNullValue.map((error: any) => {
      if (!error.value) {
        errorCounter++;
        popUp({ type: "failure", message: error.message });
      }
    });
    if (errorCounter === 0) {
      const positionInfo = values.position.split(" | ");
      const rotationInfo = values.rotation.split(" | ");
      const dataParams = {
        name: values.name,
        stageId: Number(attrId),
        fov: Number(values.FOV),
        rotationSteps: Number(values.steps),
        position: {
          x: Number(positionInfo[0]),
          y: Number(positionInfo[1]),
          z: Number(positionInfo[2]),
        },
        rotation: {
          x: Number(rotationInfo[0]),
          y: Number(rotationInfo[1]),
          z: Number(rotationInfo[2]),
        },
      };

      // редагуємо
      if (itemId) {
        const updatedOption = await updateAttributeApi({
          id: itemId,
          values: dataParams,
          url: optionsTag["cameras"].update.url,
          request: optionsTag["cameras"].update.request,
        });
        if (updatedOption) {
          dispatch(
            addOrUpdateItemsAttribute({
              attrId: attrId,
              itemId: updatedOption.id,
              attrName: "stages",
              attrValue: updatedOption,
              type: "update",
            })
          );

          popUp({
            type: "success",
            message: `camera ${values.name} successfully changed`,
          });
        }
      } else {
        // створюємо

        const newAttr = await createAttributeApi({
          values: {
            ...dataParams,
          },
          url: optionsTag["cameras"].create.url,
          request: optionsTag["cameras"].create.request,
        });
        if (newAttr) {
          dispatch(
            addOrUpdateItemsAttribute({
              attrId: attrId,
              itemId: newAttr.id,
              attrName: "stages",
              attrValue: newAttr,
              type: "add",
            })
          );
          popUp({
            type: "success",
            message: "camera has been successfully created",
          });
        }
      }
      // const projects = await getProjectsApi();
      // dispatch(setAllProjects({ projects: projects.rows }));
      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <InputWrapper
          name="name"
          setValue={(value: any) => setValues({ ...values, name: value })}
          value={values.name}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />

        <div className={s.params}>
          <InputWrapper
            name="Position"
            setValue={(value: any) => setValues({ ...values, position: value })}
            value={values.position}
            type="text"
            isRequired={true}
            addClassName={s.position}
            mask="999.99 | 999.99 | 999.99"
            maskChar="0"
            placeholder="X | Y | Z"
          />
          <InputWrapper
            name="Rotation"
            setValue={(value: any) => setValues({ ...values, rotation: value })}
            value={values.rotation}
            type="text"
            isRequired={true}
            addClassName={s.rotation}
            mask="999.99 | 999.99 | 999.99"
            maskChar="0"
            placeholder="X | Y | Z"
          />
        </div>
        <div className={s.params}>
          <InputWrapper
            name="FOV"
            setValue={(value: any) => setValues({ ...values, FOV: value })}
            value={values.FOV}
            type="number"
            isRequired={true}
            addClassName={s.FOV}
            placeholder="Enter value"
          />
          <InputWrapper
            name="360° steps"
            setValue={(value: any) => setValues({ ...values, steps: value })}
            value={values.steps}
            type="number"
            isRequired={true}
            addClassName={s.steps}
            placeholder="Enter value"
          />
        </div>
        <ButtonWrapper
          clickEvent={addOrEditAttribute}
          text="Save camera"
          type="primary"
        />
      </div>
    </div>
  );
};
