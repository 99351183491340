import { FC, useState } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { MainLayout } from "../../widgets/MainLayout/MainLayout";
import { HeadProjectInfo } from "../../widgets/HeadProjectInfo/HeadProjectInfo";
import { AttributesTab } from "../../widgets/AttributesTab/AttributesTab";
import { useDispatch, useSelector } from "react-redux";
import { getActiveProject } from "../../redux/selectors/selectors";
import { changeShowModal } from "../../redux/actions";
import { CollectionWidget } from "../../widgets/CollectionWidget/CollectionWidget";
import { CollectionEditAttr } from "../../widgets/CollectionEditAttr/CollectionEditAttr";

export const Collections: FC = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(getActiveProject);
  const [editAttr, setEditAttr]: any = useState();
  return (
    <>
      <MainLayout>
        <div className={s.wrapper}>
          <HeadProjectInfo />
          {!editAttr ? (
            <div className={s.main}>
              <AttributesTab
                buttonName="Add new collection"
                createCollection={() =>
                  dispatch(
                    changeShowModal({
                      nameModal: "addCollection",
                      stateModal: true,
                      otherParams: {
                        prjectId: projectId,
                        name: "",
                        id: undefined,
                      },
                    })
                  )
                }
              />
              <CollectionWidget
                projectId={projectId}
                setEditAttr={setEditAttr}
              />
            </div>
          ) : (
            <CollectionEditAttr setEditAttr={setEditAttr} editAttr={editAttr} />
          )}
        </div>
      </MainLayout>
      <MobileHint />
    </>
  );
};
