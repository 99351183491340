import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddVariant.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  addOrUpdateAttribute,
  addOrUpdateItemsAttribute,
  changeShowModal,
} from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import updateAttributeApi from "../../api/attributes/updateAttributeApi";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { groupNamesT, optionsTag } from "../../../utils/constants";
import { ChooseFileWrapper } from "../../../shared/ChooseFileWrapper/ChooseFileWrapper";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";

type dataT = {
  id: number;
  name: string;
  image: any;
  typeValue: groupNamesT;
  attrId: number;
  [key: string]: any;
};
type ModalAddVariantT = {
  closeFunc: any;
  data: dataT;
};

export const ModalAddVariant: FC<ModalAddVariantT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const { image, attrId, id, name, typeValue } = data;
  const optionIdName =
    typeValue === "variants" ? "optionId" : "geometryOptionId";
  const trueType = typeValue === "variants" ? "material" : "geometry";
  const text = attrId ? "Edit variant" : "Add new variant";
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState(name);
  const [imgValue, setImgValue] = useState();

  const errorsIfNullValue = [
    {
      value: nameValue,
      message: "The name field must not be empty",
    },
    {
      value: imgValue,
      message: "The image field must not be empty",
    },
  ];
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditAttribute() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    let errorCounter: number = 0;
    errorsIfNullValue.map((error: any) => {
      if (!error.value) {
        errorCounter++;
        popUp({ type: "failure", message: error.message });
      }
    });

    if (errorCounter === 0) {
      // редагуємо
      if (id) {
        const values: any = {
          name: nameValue,
          [optionIdName]: id,
        };
        if (imgValue && typeof imgValue !== "string") {
          values.image = imgValue;
        }

        const updatedOption = await updateAttributeApi({
          id: id,
          values,
          typeSend: "formData",
          url: optionsTag[typeValue].update.url,
          request: optionsTag[typeValue].update.request,
        });
        if (updatedOption) {
          dispatch(
            addOrUpdateItemsAttribute({
              attrId: data.attrId,
              itemId: updatedOption.id,
              attrName: "options",
              attrValue: updatedOption,
              typeValue: trueType,
              type: "update",
            })
          );
          popUp({
            type: "success",
            message: "variant successfully changed",
          });
        }
      } else {
        // створюємо

        const newAttr = await createAttributeApi({
          values: {
            name: nameValue,
            image: imgValue,
            [optionIdName]: attrId,
          },
          typeSend: "formData",
          url: optionsTag[typeValue].create.url,
          request: optionsTag[typeValue].create.request,
        });

        if (newAttr) {
          dispatch(
            addOrUpdateItemsAttribute({
              attrId: data.attrId,
              itemId: newAttr.id,
              attrName: "options",
              typeValue: trueType,
              attrValue: newAttr,
              type: "add",
            })
          );
          popUp({
            type: "success",
            message: "option has been successfully created",
          });
        }
      }
      // const projects = await getProjectsApi();
      // dispatch(setAllProjects({ projects: projects.rows }));
      loadItemsProductInState({ dispatch });
      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <InputWrapper
          name="name"
          setValue={setNameValue}
          value={nameValue}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />
        <ChooseFileWrapper
          name="Image"
          placeholder="PNG or JPG"
          setValue={setImgValue}
          value={imgValue}
          linkImage={image && image}
          isRequired={true}
          accept=".jpg, .jpeg, .png"
        />
        <ButtonWrapper
          clickEvent={addOrEditAttribute}
          text="Save variant"
          type="primary"
        />
      </div>
    </div>
  );
};
