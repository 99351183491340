import { FC, useId, useState, useEffect, useRef } from "react";
import s from "./SelectWrapper.module.scss";
import { CloseSecond } from "../../assets/svg/CloseSecond";
import { ArrowBottom } from "../../assets/svg/ArrowBottom";

type SelectWrapperT = {
  name: string;
  setValue: any;
  value: any;
  list: any[];
  isRequired?: boolean;
  placeholder?: string;
  addClassName?: any;
  deleteEvent?: any;
  addEvent?: any;
};
export const SelectWrapper: FC<SelectWrapperT> = ({ ...props }) => {
  const {
    isRequired = false,
    name,
    setValue,
    value,
    placeholder = "",
    addClassName = false,
    list,
    deleteEvent,
    addEvent,
  } = props;
  const [open, setOpen] = useState(false);
  const wrapperRef: any = useRef(null);
  let classWrapper = `${s.wrapper}`;
  addClassName
    ? (classWrapper += ` ${addClassName}`)
    : (classWrapper = `${s.wrapper}`);
  const inputId = useId();
  function closeFunc() {
    setOpen(false);
  }

  // useEffect(() => {
  //   const handleClickOutside =
  //     (wrapperRef: any, closeFunc: any) => (event: any) => {
  //       if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //         closeFunc();
  //       }
  //     };
  //   document.addEventListener(
  //     "mousedown",
  //     handleClickOutside(wrapperRef, closeFunc)
  //   );
  //   return () => {
  //     document.removeEventListener(
  //       "mousedown",
  //       handleClickOutside(wrapperRef, closeFunc)
  //     );
  //   };
  // }, [wrapperRef]);

  return (
    <div className={`${classWrapper} ${open && s.active}`} ref={wrapperRef}>
      <label htmlFor={inputId}>
        {name}
        {isRequired && <span className={s.req}>*</span>}
      </label>
      <div className={s.input} onClick={() => setOpen(!open)}>
        {placeholder}
        <ArrowBottom />
      </div>
      <div className={s.place}>
        {list &&
          list.length >= 1 &&
          value.map((attr: any, index: number) => {
            return (
              <div
                className={s.attr}
                key={`attr_select_${index}`}
                onClick={() => {
                  let indexToRemove = value.indexOf(attr);
                  const newArr = JSON.parse(JSON.stringify(value));
                  if (indexToRemove !== -1) {
                    newArr.splice(indexToRemove, 1);
                  }
                  setValue(newArr);
                  if (deleteEvent) {
                    deleteEvent(attr);
                  }
                }}
              >
                <span>{list.find((l: any) => l.value === attr).label}</span>
                <CloseSecond />
              </div>
            );
          })}
      </div>

      <div className={`${s.content} customScroll`}>
        {list.map((item: any, index: number) => {
          return (
            <div
              className={`${s.item} ${value.includes(item.value) && s.disable}`}
              key={`select_item_${item.label}_${index}`}
              onClick={() => {
                setValue([...value, item.value]);

                if (addEvent) {
                  addEvent(item.value);
                }
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
