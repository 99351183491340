import { FC } from "react";
import s from "./IdPlace.module.scss";
import { Table } from "../../assets/svg/Table";
import { popUp } from "../../features/popUp/PopUp";

type IdPlaceT = {
  id: any;
};
export const IdPlace: FC<IdPlaceT> = ({ ...props }) => {
  const { id } = props;
  return (
    <div
      className={s.id}
      onClick={(e: any) => {
        e.stopPropagation();
        const textarea = document.createElement("textarea");
        textarea.value = id;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        popUp({message: `id ${id} successfully added to your clipboard`, type: "info"})
      }}
    >
      id: {id}
      <Table />
    </div>
  );
};
