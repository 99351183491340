import { FC } from "react";

export const Download: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.9999 14.5996C3.15903 14.5996 3.31164 14.6628 3.42417 14.7753C3.53669 14.8879 3.5999 15.0405 3.5999 15.1996V18.1996C3.5999 18.5179 3.72633 18.8231 3.95137 19.0481C4.17642 19.2732 4.48164 19.3996 4.7999 19.3996H19.1999C19.5182 19.3996 19.8234 19.2732 20.0484 19.0481C20.2735 18.8231 20.3999 18.5179 20.3999 18.1996V15.1996C20.3999 15.0405 20.4631 14.8879 20.5756 14.7753C20.6882 14.6628 20.8408 14.5996 20.9999 14.5996C21.159 14.5996 21.3116 14.6628 21.4242 14.7753C21.5367 14.8879 21.5999 15.0405 21.5999 15.1996V18.1996C21.5999 18.8361 21.347 19.4466 20.897 19.8967C20.4469 20.3468 19.8364 20.5996 19.1999 20.5996H4.7999C4.16338 20.5996 3.55293 20.3468 3.10285 19.8967C2.65276 19.4466 2.3999 18.8361 2.3999 18.1996V15.1996C2.3999 15.0405 2.46312 14.8879 2.57564 14.7753C2.68816 14.6628 2.84077 14.5996 2.9999 14.5996Z"
        fill="#343A40"
      />
      <path
        d="M11.5759 4.09542C11.6316 4.03948 11.6978 3.9951 11.7708 3.96482C11.8437 3.93453 11.9219 3.91895 12.0009 3.91895C12.0798 3.91895 12.158 3.93453 12.231 3.96482C12.3039 3.9951 12.3701 4.03948 12.4259 4.09542L16.0259 7.69542C16.1386 7.80814 16.2019 7.96101 16.2019 8.12042C16.2019 8.27982 16.1386 8.4327 16.0259 8.54542C15.9132 8.65814 15.7603 8.72146 15.6009 8.72146C15.4415 8.72146 15.2886 8.65814 15.1759 8.54542L12.5999 5.96842V16.5204C12.5999 16.6795 12.5367 16.8322 12.4241 16.9447C12.3116 17.0572 12.159 17.1204 11.9999 17.1204C11.8407 17.1204 11.6881 17.0572 11.5756 16.9447C11.4631 16.8322 11.3999 16.6795 11.3999 16.5204V5.96842L8.82487 8.54542C8.76906 8.60123 8.7028 8.6455 8.62988 8.67571C8.55696 8.70591 8.4788 8.72146 8.39987 8.72146C8.32094 8.72146 8.24278 8.70591 8.16986 8.67571C8.09694 8.6455 8.03068 8.60123 7.97487 8.54542C7.91906 8.48961 7.87478 8.42335 7.84458 8.35043C7.81437 8.2775 7.79883 8.19935 7.79883 8.12042C7.79883 8.04149 7.81437 7.96333 7.84458 7.89041C7.87478 7.81749 7.91906 7.75123 7.97487 7.69542L11.5749 4.09542H11.5759Z"
        fill="#343A40"
      />
    </svg>
  );
};
