import { apiRoutesValueT } from "../constants";

export type collectionsRoutesNameT =
  | "GET_COLLECTIONS"
  | "UPDATE_COLLECTION"
  | "CREATE_COLLECTION"
  | "DELETE_COLLECTION"
  | "RETRIEVE_COLLECTION";
type collectionsRoutesT = Record<collectionsRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const collectionsRoutes: collectionsRoutesT = {
  GET_COLLECTIONS: {
    url: `${api}/collections`,
    request: "get",
  },
  CREATE_COLLECTION: {
    url: `${api}/collections`,
    request: "post",
  },
  UPDATE_COLLECTION: {
    url: `${api}/collections`,
    request: "put",
  },
  DELETE_COLLECTION: {
    url: `${api}/collections`,
    request: "delete",
  },
  RETRIEVE_COLLECTION: {
    url: `${api}/collections`,
    request: "get",
  },
};
