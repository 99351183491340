import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddStage.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import { addOrUpdateAttribute, changeShowModal } from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import updateAttributeApi from "../../api/attributes/updateAttributeApi";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { optionsTag } from "../../../utils/constants";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";
import { ChooseFileWrapper } from "../../../shared/ChooseFileWrapper/ChooseFileWrapper";

type ModalAddStageT = {
  closeFunc: any;
  data: any;
};
export const ModalAddStage: FC<ModalAddStageT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const { prjectId, id, name } = data;
  const text = id ? "Edit stage name" : "Add new stage";
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [nameValue, setNameValue] = useState(name);
  const [fileValue, setfileValue] = useState(data.file);
  const [mountValue, setMountValue] = useState(data.mount);
  const [pathValue, setPathValue] = useState(data.path);
  const [value, setValue] = useState("");
  const errorsIfNullValue = [
    {
      value: nameValue,
      message: "The name field must not be empty",
    },
  ];

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditAttribute() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    let errorCounter: number = 0;
    errorsIfNullValue.map((error: any) => {
      if (!error.value) {
        errorCounter++;
        popUp({ type: "failure", message: error.message });
      }
    });

    if (errorCounter === 0) {
      // редагуємо
      if (id) {
        const values: any = {
          name: nameValue,
        };
        if (pathValue) {
          values.relativePath = pathValue;
        }
        if (mountValue) {
          values.mountPoint = mountValue;
        }
        if (fileValue) {
          values.resource = fileValue;
        }
        const updatedOption = await updateAttributeApi({
          id: id,
          values,
          typeSend: "formData",
          url: optionsTag["stages"].update.url,
          request: optionsTag["stages"].update.request,
        });
        if (updatedOption) {
          dispatch(
            addOrUpdateAttribute({
              attrId: updatedOption.id,
              attrName: "stages",
              attrValue: updatedOption,
              type: "update",
            })
          );
          loadItemsProductInState({ dispatch });
          popUp({
            type: "success",
            message: "stages updated!",
          });
        }
      } else {
        // створюємо
        const values: any = {
          name: nameValue,
          projectId: prjectId,
        };
        if (pathValue) {
          values.relativePath = pathValue;
        }
        if (mountValue) {
          values.mountPoint = mountValue;
        }
        if (fileValue) {
          values.resource = fileValue;
        }
        const newAttr = await createAttributeApi({
          values,
          typeSend: "formData",
          url: optionsTag["stages"].create.url,
          request: optionsTag["stages"].create.request,
        });
        if (newAttr) {
          dispatch(
            addOrUpdateAttribute({
              attrId: newAttr.id,
              attrName: "stages",
              attrValue: {
                ...newAttr,
                items: [],
              },
              type: "add",
            })
          );
          popUp({
            type: "success",
            message: "stages has been successfully created",
          });
        }
      }
      // const projects = await getProjectsApi();
      // dispatch(setAllProjects({ projects: projects.rows }));
      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <InputWrapper
          name="name"
          setValue={setNameValue}
          value={nameValue}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />
        <ChooseFileWrapper
          name="Model"
          placeholder="PAK"
          setValue={setfileValue}
          value={fileValue}
          linkImage={data.image && data.image}
          isRequired={false}
          accept=".pak"
        />
        <InputWrapper
          name="Mount point"
          setValue={setMountValue}
          value={mountValue}
          type="text"
          isRequired={false}
          placeholder="Mount Point"
        />
        <InputWrapper
          name="Relative Path"
          setValue={setPathValue}
          value={pathValue}
          type="text"
          isRequired={false}
          placeholder="Path Point"
          hint="Relative path example: stages/leather"
        />

        <ButtonWrapper
          clickEvent={addOrEditAttribute}
          text="Save stage"
          type="primary"
        />
      </div>
    </div>
  );
};
