import { useEffect } from "react";
import { getAuthToken } from "../../../processes/sessionStorage/getAuthToken";
import { useSelector } from "react-redux";
import {
  getActiveProject,
  getCategories,
} from "../../../redux/selectors/selectors";
import getCategoriesApi from "../../../processes/api/caterories/getCategoriesApi";
import { setCategories } from "../../../redux/actions";

export const useLoadCategories = (dispatch: any) => {
  const categoriesList: any[] = useSelector(getCategories);
  const projectId: any = useSelector(getActiveProject);

  useEffect(() => {
    async function getCategories() {
      if (
        categoriesList &&
        // categoriesList.length === 0 &&
        projectId && 
        //@ts-ignore
        !window.loadCategories
      ) {
        //@ts-ignore
        window.loadCategories = true;
        const categories = await getCategoriesApi(projectId);
        dispatch(setCategories({ categories: categories.rows }));
      }
    }

    const authInfo: any = getAuthToken();

    setTimeout(() => {
      if (authInfo) {
        getCategories();
      }
    }, 0);
  }, [projectId]);
};
