import { FC, useId } from "react";
import s from "./CheckboxWrapper.module.scss";
import { Active } from "../../assets/svg/Active";

type CheckboxWrapperT = {
  name?: string;
  setValue: any;
  value: any;
  addClassName?: any;
};
export const CheckboxWrapper: FC<CheckboxWrapperT> = ({ ...props }) => {
  const { name, setValue, value, addClassName = false } = props;
  let classWrapper = `${s.wrapper}`;
  addClassName
    ? (classWrapper += ` ${addClassName}`)
    : (classWrapper = `${s.wrapper}`);

  return (
    <div
      className={`${classWrapper} ${value && s.active}`}
      onClick={() => {
        setValue(!value);
      }}
    >
      <div className={s.checkbox}>
        <Active />
      </div>
      <div className={s.name}>{name}</div>
    </div>
  );
};
