import { FC, useEffect, useState } from "react";
import s from "./StageEditAttr.module.scss";
import { BackArrow } from "../../assets/svg/BackArrow";
import { ToggleBtn } from "../../shared/ToggleBtn/ToggleBtn";
import { toggleEnableAttribute } from "../../features/functions/toggleEnableAttribute";
import { useDispatch, useSelector } from "react-redux";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import { getItemAttributeById } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { changeShowModal, setItemsAttribute } from "../../redux/actions";
import getAttributesApi from "../../processes/api/attributes/getAttributesApi";
import { optionsTag } from "../../utils/constants";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { toggleEnableItemsAttribute } from "../../features/functions/toggleEnableItemsAttribute";
import { duplicateItemsAttributeEvent } from "../../features/functions/duplicateItemsAttributeEvent";
import { loadImageFromURL } from "../../features/functions/loadImageFromURL";
import { deleteItemsAttributeEvent } from "../../features/functions/deleteItemsAttributeEvent";
import { loadItemsProductInState } from "../../features/functions/loadItemsProductInState";

type StageEditAttrT = {
  setEditAttr: any;
  editAttr: any;
};
function formatNumber(num: number) {
  console.log("num: ", num);
  // Приводим число к строке и разделяем целую и десятичную части
  let numStr = num.toString();
  let parts = numStr.split(".");

  // Определяем знак числа
  let sign = num < 0 ? "-" : "";

  // Дополняем целую часть нулями слева до достижения нужного количества символов
  let integerPart =
    sign +
    Math.abs(parseInt(parts[0]))
      .toString()
      .padStart(sign ? 3 : 4, "0");

  // Если есть десятичная часть, дополняем ее нулями справа до достижения двух символов
  let decimalPart = (parts[1] || "00").padEnd(2, "0");

  // Формируем результирующую строку
  let result = `${integerPart}.${decimalPart}`;
  console.log("result: ", result);

  return result;
}
export const StageEditAttr: FC<StageEditAttrT> = ({ ...props }) => {
  const { setEditAttr, editAttr } = props;
  const dispatch = useDispatch();
  const optionData = useSelector(
    getItemAttributeById({ name: "stages", id: editAttr.id })
  );
  useEffect(() => {
    const getActualData = async () => {
      if (optionData && !optionData.cache) {
        const info = await getAttributesApi({
          url: optionsTag["cameras"].get.url,
          request: optionsTag["cameras"].get.request,
          filter: `?stageId=${optionData.id}&page=1&pageSize=500`,
        });
        if (info) {
          dispatch(
            setItemsAttribute({
              itemId: optionData.id,
              attrName: "stages",
              attrValues: info.rows,
            })
          );
        }
      }
    };
    getActualData();
  }, []);

  if (!optionData) {
    setEditAttr(false);
  }

  return (
    <>
      {optionData && (
        <div className={s.edit}>
          <div className={s.head}>
            <div className={s.back} onClick={() => setEditAttr(false)}>
              <BackArrow />
              Back to stages list
            </div>
            <div className={s.content}>
              <div className={s.name}>{optionData.name}</div>
              <div className={s.toggle}>
                <ToggleBtn
                  eneble={optionData.enabled}
                  setEneble={async () => {
                    await toggleEnableAttribute({
                      id: optionData.id,
                      name: optionData.name,
                      nameAttr: "stages",
                      enabled: !optionData.enabled,
                      dispatch,
                    });
                    loadItemsProductInState({ dispatch });
                  }}
                />
              </div>
            </div>
            <div className={s.add}>
              <ButtonWrapper
                text="Add new camera"
                clickEvent={() => {
                  dispatch(
                    changeShowModal({
                      nameModal: "addCamera",
                      stateModal: true,
                      otherParams: {
                        attrId: optionData.id,
                        name: "",
                        itemId: "",
                        position: "",
                        rotation: "",
                        FOV: "",
                        productRotation: "",
                        steps: "",
                      },
                    })
                  );
                }}
                type="outlane"
                icon={true}
              />
            </div>
          </div>
          <div className={s.items}>
            {optionData.items.length >= 1 ? (
              <>
                {optionData.items.map((item: any, index: number) => {
                  console.log("item: ", item);
                  return (
                    <ItemStandart
                      key={`item_camera_attr_${index}`}
                      eneble={item.enabled}
                      img={item.image}
                      id={item.id}
                      setEneble={async () => {
                        await toggleEnableItemsAttribute({
                          id: item.id,
                          attrId: optionData.id,
                          otherValues: {
                            stageId: item.stageId,
                            fov: item.fov,
                            productRotation: item.productRotation,
                            rotationSteps: item.rotationSteps,
                            position: item.position,
                            rotation: item.rotation,
                          },
                          name: item.name,
                          nameAttr: "stages",
                          tagName: "cameras",
                          enabled: !item.enabled,
                          dispatch,
                        });
                        loadItemsProductInState({ dispatch });
                      }}
                      title={item.name}
                      deleteEvent={async () => {
                        deleteItemsAttributeEvent({
                          id: optionData.id,
                          itemId: item.id,
                          attrName: "stages",
                          attrTag: "cameras",
                          dispatch,
                        });
                        loadItemsProductInState({ dispatch });
                      }}
                      duplicateEvent={async () => {
                        // const loadPrevImg = async () => {
                        //   const file = await loadImageFromURL(linkImage);
                        //   setValue(file);
                        // };
                        // loadPrevImg();

                        await duplicateItemsAttributeEvent({
                          name: item.name,
                          attrId: optionData.id,
                          itemId: item.id,
                          count: optionData.items.length,
                          otherValues: {
                            stageId: item.stageId,
                            fov: item.fov,
                            productRotation: item.productRotation,
                            rotationSteps: item.rotationSteps,
                            position: item.position,
                            rotation: item.rotation,
                          },
                          nameAttr: "stages",
                          typeSend: "json",
                          url: optionsTag["cameras"].create.url,
                          request: optionsTag["cameras"].create.request,
                          dispatch,
                        });
                        loadItemsProductInState({ dispatch });
                      }}
                      editEvent={() => {
                        // const posString = `${formatNumber(
                        //   item.position.x
                        // )} | ${formatNumber(item.position.y)} | ${formatNumber(
                        //   item.position.z
                        // )}`;
                        // const rotString = `${formatNumber(
                        //   item.rotation.x
                        // )} | ${formatNumber(item.rotation.y)} | ${formatNumber(
                        //   item.rotation.z
                        // )}`;

                        dispatch(
                          changeShowModal({
                            nameModal: "addCamera",
                            stateModal: true,
                            otherParams: {
                              attrId: item.stageId,
                              name: item.name,
                              itemId: item.id,
                              position: item.position,
                              rotation: item.rotation,
                              productRotation: item.productRotation,
                              FOV: item.fov,
                              steps: item.rotationSteps,
                            },
                          })
                        );
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <NoneData text="/// No cameras available yet ///" />
            )}
          </div>
        </div>
      )}
    </>
  );
};
