import React from "react";
import ReactDOM from "react-dom/client";
import "./utils/styles/fonts.css";
import "./utils/styles/index.css";
import "./utils/styles/notiflix.css";
import { App } from "./pages/Index/Index";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Login } from "./pages/Login/Index";
import { Options } from "./pages/Options/Index";
import { PAGES } from "./utils/constants";
import { Stages } from "./pages/Stages/Index";
import { Collections } from "./pages/Collections/Index";
import { Products } from "./pages/Products/Index";
import { Environment } from "./pages/Environment/Index";
import WebSocketComponent from "./features/WebSocketComponent/WebSocketComponent";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <Router>
      <Routes>
        <Route path={PAGES.PROJECTS} element={<App />} />
        <Route path={PAGES.LOGIN} element={<Login />} />
        <Route path={`/:id${PAGES.OPTIONS}`} element={<Options />} />
        <Route path={`/:id${PAGES.STAGES}`} element={<Stages />} />
        <Route path={`/:id${PAGES.COLLECTIONS}`} element={<Collections />} />
        <Route path={`/:id${PAGES.ENVIRONMENT}`} element={<Environment />} />
        <Route path={`/:id${PAGES.PRODUCTS}`} element={<Products />} />
      </Routes>
    </Router>
    <WebSocketComponent />
  </Provider>
);
