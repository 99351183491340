import { createSelector } from "reselect";
import {
  ModalStatusT,
  NameModals,
  attributesNamesT,
  modalsListT,
  projectsListT,
  stateT,
} from "../reducers/types";

export const getStateModal =
  (nameModal: NameModals) =>
  ({ ...state }: stateT) => {
    const modalList: modalsListT = state.ui.modalsList;
    const selectedModal: ModalStatusT = modalList[nameModal];
    return selectedModal;
  };

export const getAllProjects = ({ ...state }: stateT) => {
  const projectList: projectsListT[] = state.ui.projectsList;
  return projectList;
};
export const getCategories = ({ ...state }: stateT) => {
  const categoriesList: any[] = state.ui.categories;
  return categoriesList;
};

export const getProjectById =
  (id: number | undefined) =>
  ({ ...state }: stateT) => {
    const projectList: projectsListT[] = state.ui.projectsList;
    const project = projectList.filter(
      (prjct: projectsListT) => prjct.id === Number(id)
    );
    return project[0];
  };

export const getActiveProject = ({ ...state }: stateT) => {
  const activeProject: number | undefined = state.ui.activeProject;
  return activeProject;
};
// // Создайте селектор с использованием reselect
// export const getActiveProjectSelector = createSelector(
//   getActiveProject,
//   (activeProject) => activeProject
// );

export const getActiveTab = ({ ...state }: stateT) => {
  const activeTab: string | undefined = state.ui.activeTab;
  return activeTab;
};

export const getAttributeCounts = ({ ...state }: stateT) => {
  const activeId: number | undefined = state.ui.activeProject;
  const projectsList: projectsListT[] = state.ui.projectsList;
  if (activeId && projectsList.length >= 1) {
    const activeProject: any = projectsList.find(
      (project: projectsListT) => project.id === activeId
    );
    return activeProject.attributesList;
  } else {
    return false;
  }
};

export const getAttributeByName =
  (name: attributesNamesT) =>
  ({ ...state }: stateT) => {
    const activeId: number | undefined = state.ui.activeProject;
    const projectsList: projectsListT[] = state.ui.projectsList;
    if (activeId && projectsList.length >= 1 && name) {
      const activeProject: any = projectsList.find(
        (project: projectsListT) => project.id === activeId
      );
      const attr = activeProject.attributesList[name];
      return attr;
    } else {
      return false;
    }
  };

type getItemAttributeByIdT = {
  name: attributesNamesT;
  id: number;
  typeValue?: "geometry" | "material";
};
export const getItemAttributeById =
  ({ name, id, typeValue }: getItemAttributeByIdT) =>
  ({ ...state }: stateT) => {
    const activeId: number | undefined = state.ui.activeProject;
    const projectsList: projectsListT[] = state.ui.projectsList;
    if (activeId && projectsList.length >= 1) {
      const activeProject: any = projectsList.find(
        (project: projectsListT) => project.id === activeId
      );
      const attr = activeProject.attributesList[name];
      let item;
      if (typeValue) {
        item = attr.rows.find(
          (attrbts: any) => attrbts.id === id && attrbts.typeValue === typeValue
        );
      } else {
        item = attr.rows.find((attrbts: any) => attrbts.id === id);
      }

      return item;
    } else {
      return false;
    }
  };

export const getAllItemAttribute =
  (name: attributesNamesT) =>
  ({ ...state }: stateT) => {
    const activeId: number | undefined = state.ui.activeProject;
    const projectsList: projectsListT[] = state.ui.projectsList;
    if (activeId && projectsList.length >= 1) {
      const activeProject: any = projectsList.find(
        (project: projectsListT) => project.id === activeId
      );
      const attr = activeProject.attributesList[name];
      // let item = attr.rows.find((attrbts: any) => attrbts.id === id);
      return attr.rows;
    } else {
      return false;
    }
  };

export const getJobsByProductId =
  (id: number) =>
  ({ ...state }: stateT) => {
    const jobs = state.ui.renderJobs[id];
    return jobs;
  };
