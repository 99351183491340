import { FC, useEffect } from "react";
import s from "./OptionWidget.module.scss";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeByName } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import {
  handleDeleteAttribute,
  handleDuplicateAttribute,
  handleEditAttribute,
  handleEnableAttribute,
} from "./functions/optionWidgetEvents";
import { getActualData } from "../OptionEditAttr/hooks/useUpdateActualData";

export const OptionWidget: FC<any> = ({ projectId, setEditAttr }) => {
  const dispatch = useDispatch();
  const optionsData = useSelector(getAttributeByName("options"));
  //

  useEffect(() => {
    if (optionsData) {
      optionsData.rows.map((option: any) => {
        getActualData(option, dispatch);
      });
    }
  }, [optionsData]);
  return (
    <div className={`${s.wrapper} customScroll`}>
      {optionsData && optionsData.rows.length ? (
        <>
          {optionsData.rows.map((item: any, index: number) => (
            <ItemStandart
              key={`option_${index}`}
              eneble={item.enabled}
              type={item.typeValue}
              id={item.id}
              mainEvent={() => setEditAttr(item)}
              setEneble={() => handleEnableAttribute({ item, dispatch })}
              title={item.name}
              deleteEvent={() => handleDeleteAttribute({ item, dispatch })}
              duplicateEvent={() =>
                handleDuplicateAttribute({
                  item,
                  projectId,
                  dispatch,
                  optionsData,
                })
              }
              editEvent={() =>
                handleEditAttribute({ item, dispatch, projectId })
              }
            />
          ))}
        </>
      ) : (
        <NoneData text="/// No options available yet ///" />
      )}
    </div>
  );
};
