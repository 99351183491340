import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalAddOption.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import { addOrUpdateAttribute, changeShowModal } from "../../../redux/actions";
import { InputWrapper } from "../../../shared/InputWrapper/InputWrapper";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import updateAttributeApi from "../../api/attributes/updateAttributeApi";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { optionsTag } from "../../../utils/constants";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";
import { ChooseFileWrapper } from "../../../shared/ChooseFileWrapper/ChooseFileWrapper";
import { ToggleBtn } from "../../../shared/ToggleBtn/ToggleBtn";
import { SelectWrapper } from "../../../shared/SelectWrapper/SelectWrapper";
import { getAttributeByName } from "../../../redux/selectors/selectors";
import { AnotherOptionSelect } from "./components/AnotherOptionSelect/AnotherOptionSelect";
import { uploadFile } from "../../../features/functions/uploadFile";
type ModalAddOptionT = {
  closeFunc: any;
  data: any;
};
export const ModalAddOption: FC<ModalAddOptionT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const chunkSize = 1024 * 1024; // 1 МБ
  const text = data.id ? "Edit option name" : "Add new option";
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [optionalFileValue, setOptionalFileValue] = useState();
  const [fileValue, setfileValue] = useState();
  const [mountValue, setMountValue] = useState(data.mount);
  const [pathValue, setPathValue] = useState(data.path);
  const [typeValue, setTypeValue] = useState<"material" | "geometry">(
    data.typeValue ? data.typeValue : "material"
  );
  const [nameValue, setNameValue] = useState(data.name);
  const [dependencies, setDependencies] = useState(
    data.requirements ? data.requirements : []
  );

  const errorsIfNullValue = [
    {
      value: nameValue,
      message: "The name field must not be empty",
    },
    // {
    //   value: data.id ? true : fileValue,
    //   message: "The file field must not be empty",
    // },
    // {
    //   value: mountValue,
    //   message: "The mount point field must not be empty",
    // },
  ];
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditAttribute() {
    let errorCounter: number = 0;
    errorsIfNullValue.map((error: any) => {
      if (!error.value && typeValue === "material") {
        errorCounter++;
        popUp({ type: "failure", message: error.message });
      }
    });

    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    if (errorCounter === 0) {
      // if (fileValue) {
      //   await uploadFile(fileValue, chunkSize);
      // }
      // редагуємо
      if (data.id) {
        let updatedOption;
        if (typeValue === "geometry") {
          const values: any = {
            name: nameValue,
            projectId: data.prjectId,
          };
          if (dependencies.length >= 1) {
            values.requirements = dependencies;
          } else {
            values.requirements = [];
          }
          updatedOption = await updateAttributeApi({
            id: data.id,
            typeSend: "json",
            values: values,
            url: optionsTag["geometryOptions"].update.url,
            request: optionsTag["geometryOptions"].update.request,
          });
        } else if (typeValue === "material") {
          const values: any = {
            name: nameValue,
            projectId: data.prjectId,
          };
          if (pathValue) {
            values.relativePath = pathValue;
          }
          if (mountValue) {
            values.mountPoint = mountValue;
          }
          if (fileValue && typeof fileValue !== "string") {
            values.resource = fileValue;
          }
          updatedOption = await updateAttributeApi({
            id: data.id,
            typeSend: "formData",
            values: values,
            url: optionsTag["options"].update.url,
            request: optionsTag["options"].update.request,
          });
        }

        if (updatedOption) {
          dispatch(
            addOrUpdateAttribute({
              attrId: updatedOption.id,
              attrName: "options",
              attrValue: updatedOption,
              type: "update",
            })
          );
          loadItemsProductInState({ dispatch });

          popUp({
            type: "success",
            message: "option updated!",
          });
        }
      } else {
        // створюємо

        let newAttr;
        if (typeValue === "geometry") {
          const values: any = {
            name: nameValue,
            projectId: data.prjectId,
          };
          if (dependencies.length >= 1) {
            values.requirements = dependencies;
          }
          newAttr = await createAttributeApi({
            values: values,
            typeSend: "json",
            url: optionsTag["geometryOptions"].create.url,
            request: optionsTag["geometryOptions"].create.request,
          });
        } else if (typeValue === "material") {
          const values: any = {
            name: nameValue,
            projectId: data.prjectId,
          };
          if (pathValue) {
            values.relativePath = pathValue;
          }
          if (mountValue) {
            values.mountPoint = mountValue;
          }
          if (fileValue) {
            values.resource = fileValue;
          }

          newAttr = await createAttributeApi({
            values: values,
            typeSend: "formData",
            url: optionsTag["options"].create.url,
            request: optionsTag["options"].create.request,
          });
        }

        if (newAttr) {
          dispatch(
            addOrUpdateAttribute({
              attrId: newAttr.id,
              attrName: "options",
              attrValue: {
                ...newAttr,
                typeValue,
                items: [],
              },
              type: "add",
            })
          );
          popUp({
            type: "success",
            message: "option has been successfully created",
          });
        }
      }
      // const projects = await getProjectsApi();
      // dispatch(setAllProjects({ projects: projects.rows }));
      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{text}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.type}>
          <div className={s.name}>
            Option type
            <span className={s.req}>*</span>
          </div>
          <div className={s.typeWrapper}>
            <div className={s.typeName}>Material</div>
            <ToggleBtn
              hideText={true}
              eneble={typeValue === "material" ? false : true}
              setEneble={() => {
                setTypeValue(
                  typeValue === "material" ? "geometry" : "material"
                );
              }}
            />
            <div className={s.typeName}>Geometry</div>
          </div>
        </div>
        <InputWrapper
          name="name"
          setValue={setNameValue}
          value={nameValue}
          type="text"
          isRequired={true}
          placeholder="Enter name"
        />
        {typeValue === "material" && (
          <>
            <ChooseFileWrapper
              name="Model"
              placeholder="PAK"
              setValue={setfileValue}
              value={fileValue}
              linkImage={data.image && data.image}
              isRequired={false}
              accept=".pak"
            />
            <InputWrapper
              name="Mount point"
              setValue={setMountValue}
              value={mountValue}
              type="text"
              isRequired={false}
              placeholder="Mount Point"
            />
            <InputWrapper
              name="Relative Path"
              setValue={setPathValue}
              value={pathValue}
              type="text"
              isRequired={false}
              placeholder="Path Point"
              hint="Relative path example: options/leather"
            />
          </>
        )}

        {typeValue === "geometry" && (
          <>
            <AnotherOptionSelect
              dependencies={dependencies}
              setDependencies={setDependencies}
              defaultDep={data.requirements}
            />
          </>
        )}

        <ButtonWrapper
          clickEvent={addOrEditAttribute}
          text="Save option"
          type="primary"
        />
      </div>
    </div>
  );
};
