import axios from "axios";
import { apiRoutes, requestT } from "../../../utils/constants";
import { popUp } from "../../../features/popUp/PopUp";
import { getAuthToken } from "../../sessionStorage/getAuthToken";
type createAttributeApiT = {
  values: {
    name?: string;
    enabled?: boolean;
    projectId?: number;
    [key: string]: any;
  };
  urlParam?: string;
  url: string;
  request: requestT;
  typeSend?: "json" | "formData";
  [key: string]: any;
};
const createAttributeApi = async ({ ...props }: createAttributeApiT) => {
  const { request, url, values, typeSend = "json", urlParam = '' } = props;
  const authInfo: any = getAuthToken();
  const data: any = JSON.parse(authInfo);
  const contentType =
    typeSend === "json" ? "application/json" : "multipart/form-data";
  let actualValues;
  if (typeSend === "json") {
    actualValues = JSON.stringify({ ...values });
  } else if (typeSend === "formData") {
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]: any) => {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        formData.append(key, value);
      }
    });
    actualValues = formData;
  }

  return await axios[request](`${url}/${urlParam}`, actualValues, {
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${data.token}`,
      "Content-Type": contentType,
    },
  })
    .then((response: any) => {
      if (response.data) {
        return response.data;
      } else {
        popUp({
          type: "failure",
          message: "Oops! There was a problem in createAttributeApi",
        });
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      const dataError = error.response.data.errors;
      if (dataError) {
        dataError.map((err: any) => {
          popUp({ type: "failure", message: err.message });
        });
      } else {
        popUp({ type: "failure", message: "Oh, something went wrong!" });
      }
    });
};

export default createAttributeApi;
