import { FC, useEffect, useRef, useState } from "react";
import s from "./AnotherVariant.module.scss";
import { ArrowBottom } from "../../../../../assets/svg/ArrowBottom";

type AnotherVariantT = {
  option: any;
  setListData: any;
  listData: any;
};
export const AnotherVariant: FC<AnotherVariantT> = ({
  option,
  listData,
  setListData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef: any = useRef(null);

  function closeFunc() {
    setIsOpen(false);
  }
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);
  return (
    <div className={s.list}>
      <div className={s.label}>
        <div className={s.text}>Include variant</div>
        <div className={s.optionWrapper}>
          <div className={s.option}>Option:</div>
          <div className={s.name}>{option.label}</div>
        </div>
      </div>
      <div
        className={`${s.select} ${isOpen && s.active}`}
        ref={wrapperRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={s.title}>
          <span>
            {option.activeItem
              ? option.activeItem.name
              : "Select variant from the list"}
          </span>
          <ArrowBottom />
        </div>
        <div className={s.content}>
          {option.items.map((variant: any, index: number) => {
            return (
              <div
                className={s.item}
                key={`variant_${variant.name}_${index}`}
                onClick={() => {
                  const data = listData.map((list: any) => {
                    if (list.value === option.value) {
                      return {
                        ...list,
                        activeItem: variant,
                      };
                    } else {
                      return list;
                    }
                  });
                  setListData(data);
                }}
              >
                {variant.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
