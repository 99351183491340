import { FC, useEffect, useState } from "react";
import s from "./ProductWidget.module.scss";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllItemAttribute,
  getAttributeByName,
  getItemAttributeById,
} from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { changeShowModal, setItemsAttribute } from "../../redux/actions";
import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import { toggleEnableAttribute } from "../../features/functions/toggleEnableAttribute";
import { deleteAttributeEvent } from "../../features/functions/deleteAttributeEvent";
import { duplicateAttributeEvent } from "../../features/functions/duplicateAttributeEvent";
import { optionsTag } from "../../utils/constants";
import getAttributesApi from "../../processes/api/attributes/getAttributesApi";

export const ProductWidget: FC<any> = ({
  projectId,
  setEditAttr,
  optionData,
  stageData,
}) => {
  const dispatch = useDispatch();
  const productData = useSelector(getAttributeByName("products"));
  const [checkIfLoaded, setCheckIfLoaded] = useState({
    options: false,
    stages: false,
  });

  useEffect(() => {
    type typeT = "options" | "stages";
    type paramT = { itemName: "variants" | "cameras"; filterName: any };
    type infoParamT = Record<typeT, paramT>;
    const infoParam: infoParamT = {
      options: {
        itemName: "variants",
        filterName: "optionId",
      },
      stages: {
        itemName: "cameras",
        filterName: "stageId",
      },
    };
    const getActualData = async (type: typeT, data: any) => {
      if (data.length >= 1 && !checkIfLoaded[type]) {
        setCheckIfLoaded({ ...checkIfLoaded, [type]: true });
        data.map(async (item: any) => {
          if (!item.cache) {
            const info = await getAttributesApi({
              url: optionsTag[infoParam[type].itemName].get.url,
              request: optionsTag[infoParam[type].itemName].get.request,
              filter: `?${infoParam[type].filterName}=${item.id}&page=1&pageSize=500`,
            });
            if (info) {
              dispatch(
                setItemsAttribute({
                  itemId: item.id,
                  attrName: type,
                  attrValues: info.rows,
                })
              );
            }
          }
        });
      }
      // else if (!checkIfLoaded[type]) {
      //   setCheckIfLoaded({ ...checkIfLoaded, [type]: true });
      // }
    };

    getActualData("options", optionData);
    getActualData("stages", stageData);
  }, [optionData, stageData]);

  return (
    <div className={`${s.wrapper} customScroll`}>
      {productData && productData.rows.length ? (
        <>
          {productData.rows.map((item: any, index: number) => {
            return (
              <ItemStandart
                key={`product_${index}`}
                id={item.id}
                eneble={item.enabled}
                mainEvent={() => setEditAttr(item)}
                setEneble={() => {
                  toggleEnableAttribute({
                    id: item.id,
                    name: item.name,
                    mount: item.mountPoint,
                    nameAttr: "products",
                    enabled: !item.enabled,
                    dispatch,
                  });
                }}
                title={item.name}
                deleteEvent={() => {
                  deleteAttributeEvent({
                    id: item.id,
                    name: item.name,
                    nameAttr: "products",
                    enabled: !item.enabled,
                    dispatch,
                  });
                }}
                duplicateEvent={() => {
                  duplicateAttributeEvent({
                    name: item.name,
                    count: productData.count,
                    prjectId: projectId,
                    attrId: item.id,
                    nameAttr: "products",
                    url: optionsTag["products"].create.url,
                    request: optionsTag["products"].create.request,
                    dispatch,
                  });
                }}
                editEvent={() => {
                  const activeCategories = item.categories.map(
                    (i: any) => i.id
                  );
                  dispatch(
                    changeShowModal({
                      nameModal: "addProduct",
                      stateModal: true,
                      otherParams: {
                        prjectId: projectId,
                        name: item.name,
                        mount: item.mountPoint,
                        path: item.relativePath,
                        categories: activeCategories,
                        id: item.id,
                        file: item.resource,
                        optionalFile: item.glbBundle,
                        imageFIle: item.imgCover,
                        layeredRender: item.layeredRender,
                        playcanvas: item.playcanvas
                      },
                    })
                  );
                }}
              />
            );
          })}
        </>
      ) : (
        <NoneData text="/// No products available yet ///" />
      )}
    </div>
  );
};
