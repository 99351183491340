import { FC } from "react";

export const ProjectsIcon: FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2038 14.9208C18.5743 12.9219 18.5969 10.8733 18.2732 8.86606L18.2197 8.53565C18.1522 8.11658 17.9355 7.73503 17.6087 7.4596C17.2818 7.18418 16.8661 7.03295 16.4364 7.03311H9.14949C9.13632 7.03289 9.12377 7.02757 9.11454 7.0183C9.10531 7.00903 9.10014 6.99655 9.10014 6.98355C9.10014 6.16413 8.42765 5.5 7.59708 5.5H5.67999C5.23411 5.50009 4.80372 5.66151 4.4702 5.95375C4.13668 6.24599 3.92314 6.64878 3.86996 7.08597L3.64161 8.96023C3.40432 10.9114 3.46551 12.8868 3.82312 14.8201C3.89411 15.2036 4.09222 15.553 4.38627 15.8132C4.68033 16.0734 5.05364 16.2296 5.44746 16.2574L6.71381 16.3482C9.58276 16.5506 12.4634 16.5506 15.3324 16.3482L16.7024 16.2507C17.0666 16.2249 17.4117 16.0802 17.6835 15.8395C17.9553 15.5987 18.1383 15.2756 18.2038 14.9208Z"
        fill="#343A40"
      />
    </svg>
  );
};
