import { FC } from "react";
import s from "../../ProjectMenu.module.scss";
import { ButtonWrapper } from "../../../../shared/ButtonWrapper/ButtonWrapper";
import { changeShowModal } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

export const AddProjectBtn: FC = () => {
  const dispatch = useDispatch();

  return (
    <div className={s.add}>
      <ButtonWrapper
        type="outlane"
        icon={true}
        clickEvent={() =>
          dispatch(
            changeShowModal({
              nameModal: "addProject",
              stateModal: true,
              otherParams: {
                id: undefined,
                name: "",
              },
            })
          )
        }
        text="Add new project"
      />
    </div>
  );
};
