import { FC } from "react";
import s from "./RenderItem.module.scss";
import { Delete } from "../../../../assets/svg/Delete";
import { RenderingIcon } from "../../../../assets/svg/RenderingIcon";
import startRenderApi from "../../../../processes/api/products/startRenderApi";
import getRenderJobsApi from "../../../../processes/api/products/getRenderJobsApi";
import { useDispatch } from "react-redux";
import { setJobs } from "../../../../redux/actions";

type RenderItemT = {
  options: any;
  variants: any;
  id: any;
  combination: any;
  created: any;
  status: "init" | "pending" | "success";
  text?: any;
};
const statusData = {
  init: {
    status: "Ready to render",
    render: "Render",
  },
  pending: {
    status: "Rendering...",
    render: <RenderingIcon />,
  },
  success: {
    status: "Rendered. Ready to migration",
    render: "Migrate to Threekit",
  },
};
export const RenderItem: FC<RenderItemT> = ({
  combination,
  created,
  id,
  options,
  status,
  variants,
  text,
}) => {
  const dispatch = useDispatch();


  return (
    <div className={s.wrapper}>
      <div className={s.info}>
        <div className={s.upperInfo}>
          <div className={s.item}>
            <div className={s.label}>Render layers</div>
            <div className={s.value}>{options}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Render values</div>
            <div className={s.value}>{variants}</div>
          </div>
        </div>
        <div className={s.bottomInfo}>
          <div className={s.item}>
            <div className={s.label}>ID</div>
            <div className={s.value}>{id}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}># of variations</div>
            <div className={s.value}>{combination}</div>
          </div>
          <div className={s.item}>
            <div className={s.label}>Created</div>
            <div className={s.value}>{created}</div>
          </div>
        </div>
      </div>
      <div className={s.controllers}>
        <div className={s.status}>
          <div className={`${s.point} ${s[status]}`}></div>
          <span>{statusData[status].status}</span>
        </div>
        <div className={s.btn}>
          {status !== "pending" && (
            <div className={s.delete}>
              <Delete />
              <span>Delete</span>
            </div>
          )}
          {text && (
            <div className={s.text}>
              <b>Rendering in progress...</b> {text}
            </div>
          )}
          <div
            onClick={async () => {
              await startRenderApi(id);
              const jobs = await getRenderJobsApi(id);
              dispatch(
                setJobs({
                  jobs: jobs.data.rows,
                  productId: id,
                })
              );
            }}
            className={`${s.render} ${s[status]}`}
          >
            {statusData[status].render}
          </div>
        </div>
      </div>
    </div>
  );
};
