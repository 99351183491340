import { FC, useState } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { useNavigate } from "react-router-dom";
import { checkTokenAndRefresh } from "../../features/functions/checkTokenAndRefresh";
import { MainLayout } from "../../widgets/MainLayout/MainLayout";
import { HeadProjectInfo } from "../../widgets/HeadProjectInfo/HeadProjectInfo";
import { AttributesTab } from "../../widgets/AttributesTab/AttributesTab";
import { OptionWidget } from "../../widgets/OptionWidget/OptionWidget";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveProject,
  getAllItemAttribute,
} from "../../redux/selectors/selectors";
import { changeShowModal } from "../../redux/actions";
import { ProductWidget } from "../../widgets/ProductWidget/ProductWidget";
import { ProductEditAttr } from "../../widgets/ProductEditAttr/ProductEditAttr";

export const Products: FC = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(getActiveProject);
  const [editAttr, setEditAttr]: any = useState();
  const optionData = useSelector(getAllItemAttribute("options"));
  const stageData = useSelector(getAllItemAttribute("stages"));
  return (
    <>
      <MainLayout>
        <div className={s.wrapper}>
          <HeadProjectInfo />
          {!editAttr ? (
            <div className={s.main}>
              <AttributesTab
                buttonName="Add new product"
                createCollection={() =>
                  dispatch(
                    changeShowModal({
                      nameModal: "addProduct",
                      stateModal: true,
                      otherParams: {
                        prjectId: projectId,
                        name: "",
                        mount: "",
                        path: "products/",
                        id: undefined,
                      },
                    })
                  )
                }
              />
              <ProductWidget
                projectId={projectId}
                setEditAttr={setEditAttr}
                optionData={optionData}
                stageData={stageData}
              />
            </div>
          ) : (
            <ProductEditAttr
              setEditAttr={setEditAttr}
              editAttr={editAttr}
              optionData={optionData}
              stageData={stageData}
            />
          )}
        </div>
      </MainLayout>
      <MobileHint />
    </>
  );
};
