import { FC, useEffect, useRef, useState } from "react";
import s from "./CombinationList.module.scss";
import { Close } from "../../../../../../assets/svg/Close";
import { Plus } from "../../../../../../assets/svg/Plus";
import { CloseSecond } from "../../../../../../assets/svg/CloseSecond";
import { getUniqueObjects } from "../../functions/getUniqueObjects";
import { markDuplicates } from "../../functions/markDuplicates";
import CreateCombinationApi from "../../../../../../processes/api/products/CreateCombinationApi";

type CombinationListT = {
  option: any;
  listOptions: any;
  setListOptions: any;
  data: any;
  id: any;
};
export const CombinationList: FC<CombinationListT> = ({
  data,
  listOptions,
  option,
  setListOptions,
  id,
}) => {
  const wrapperRef: any = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contentItems, setContentItems] = useState([]);
  useEffect(() => {
    const uniqueObjects: any = getUniqueObjects(option.data, data);
    setContentItems(uniqueObjects);
  }, [option]);

  function closeFunc() {
    setIsOpen(false);
  }
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  function updateApi(values: any) {
    if (values.length >= 1) {
      const dep: any = [];
      let optionId: any;
      let type;
      values.map((val: any, index: number) => {
        if (index === 0) {
          optionId = val.id;
          type = val.type === "material" ? "options" : "geometryOptions";
        } else {
          dep.push({
            id: val.id,
            type: val.type,
          });
        }
      });
      CreateCombinationApi(id, optionId, dep, type);
    }
  }
  return (
    <div className={s.item}>
      <div className={s.index}>{option.index}.</div>
      <div className={s.lists}>
        {option.data.map((list: any, index: number) => {
          return (
            <div
              className={`${s.list} ${list.isRepeat && s.repeat}`}
              key={`list${option.index}_${list.name}_${index}`}
              onClick={() => {
                const updatedList: any = [];

                listOptions.map((l: any) => {
                  if (l.index === option.index) {
                    const newArr: any = [];
                    l.data.map((val: any) => {
                      if (val.id === list.id && val.type === list.type) {
                      } else {
                        newArr.push(val);
                      }
                    });
                    updatedList.push({
                      ...l,
                      data: newArr,
                    });
                    updateApi(newArr);
                  } else {
                    updatedList.push(l);
                  }
                });
                const updatedDataArray: any = markDuplicates(updatedList);
                setListOptions(updatedDataArray);
              }}
            >
              <span>{list.name}</span>
              <CloseSecond />
            </div>
          );
        })}
        <div className={`${s.add} ${isOpen && s.active}`} ref={wrapperRef}>
          <div className={s.head} onClick={() => setIsOpen(!isOpen)}>
            <Plus />
            <span>Add combination</span>
          </div>
          <div className={s.content}>
            {contentItems.map((item: any, i: number) => {
              return (
                <div
                  key={`item_list_list${option.index}_${i}${item.name}`}
                  className={s.contentItem}
                  onClick={() => {
                    const updatedList: any = [];

                    listOptions.map((l: any) => {
                      if (l.index === option.index) {
                        const newData = l.data;
                        newData.push(item);
                        updatedList.push({
                          ...l,
                          data: newData,
                        });
                        updateApi(newData);
                      } else {
                        updatedList.push(l);
                      }
                    });
                    const updatedDataArray = markDuplicates(updatedList);
                    setListOptions(updatedDataArray);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
