import { FC, useState, useEffect, useRef } from "react";
import s from "./ModalAddProductItem.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import {
  changeShowModal,
} from "../../../redux/actions";
import { ButtonWrapper } from "../../../shared/ButtonWrapper/ButtonWrapper";
import { popUp } from "../../../features/popUp/PopUp";
import createAttributeApi from "../../api/attributes/createAttributeApi";
import { optionsTag } from "../../../utils/constants";
import { SelectOptionProxy } from "../../../shared/SelectOptionProxy/SelectOptionProxy";
import { loadItemsProductInState } from "../../../features/functions/loadItemsProductInState";
import { SelectWrapper } from "../../../shared/SelectWrapper/SelectWrapper";

type ModalAddProductItemT = {
  closeFunc: any;
  data: {
    listName: [];
    attrId: number;
    list: any[];
    type: "options" | "stages";
  };
};
function removeElementsFromArray(arr1: any, arr2: any) {
  // Создаем новый массив, чтобы не изменять оригинальные массивы
  const resultArray = arr1.filter((item: any) => !arr2.includes(item));
  return resultArray;
}

function removeElementsFromArrayOption(arr1: any, arr2: any) {
  // Создаем новый массив, чтобы не изменять оригинальные массивы
  const resultArray = arr1.filter(
    (item: any) => !arr2.find((i: any) => i.id === item.id)
  );
  return resultArray;
}
export const ModalAddProductItem: FC<ModalAddProductItemT> = ({ ...props }) => {
  const { closeFunc, data } = props;
  const { listName = [], attrId, type, list } = data;
  const textData = {
    options: {
      title: "Connect options",
      label: "Options list",
      placeholder: "Select options from the list",
    },
    stages: {
      title: "Connect stage",
      label: "Stage list",
      placeholder: "Select stages from the list",
    },
  };
  const wrapperRef: any = useRef(null);
  const dispatch = useDispatch();
  const [listValue, setlistValue] = useState(listName);
  const [listType, setListType] = useState("material");
  const [fileValue, setfileValue] = useState();

  const errorsIfNullValue = [
    {
      value: listValue.length === 0,
      message: "The Options list field must not be empty",
    },
  ];

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  async function addOrEditAttribute() {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
    let errorCounter: number = 0;
    errorsIfNullValue.map((error: any) => {
      if (error.value) {
        errorCounter++;
        popUp({ type: "failure", message: error.message });
      }
    });

    if (errorCounter === 0) {
      // створюємо
      let actualIds;
      let nameKey = listType === "material" ? "options" : "geometryOptions";
      const nameId = listType === "material" ? "optionId" : "geometryOptionId";
      
      if (type === "options") {
        actualIds = removeElementsFromArrayOption(listValue, listName);
      } else if (type === "stages") {
        actualIds = removeElementsFromArray(listValue, listName);
        nameKey = "stages"
      }
      if (nameKey === "geometryOptions") {
        actualIds = actualIds.map((i: any) => {
          return {
            id: i.id,
            label: i.label,
            [nameId]: i.optionId,
            proxyName: i.proxyName,
          };
        });
      }
     
      console.log("actualIds: ", actualIds);
      const newAttr = await createAttributeApi({
        values: {
          [nameKey]: actualIds,
        },
        urlParam: `${attrId}/${nameKey}`,
        typeSend: "json",
        url: optionsTag["products"].create.url,
        request: optionsTag["products"].create.request,
      });
      if (newAttr) {
        loadItemsProductInState({ dispatch });
        popUp({
          type: "success",
          message: "products has been successfully created",
        });
      }

      closeFunc();
    }
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>{textData[type].title}</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.content}>
        {type === "options" ? (
          <SelectOptionProxy
            name={textData[type].label}
            setValue={setlistValue}
            value={listValue}
            list={list}
            setListType={setListType}
            isRequired={true}
            placeholder={textData[type].placeholder}
          />
        ) : (
          <SelectWrapper
            name={textData[type].label}
            setValue={setlistValue}
            value={listValue}
            list={list}
            isRequired={true}
            placeholder={textData[type].placeholder}
          />
        )}

        <ButtonWrapper
          clickEvent={addOrEditAttribute}
          text="Connect"
          type="primary"
        />
      </div>
    </div>
  );
};
