import { FC } from "react";
import s from "./MobileHint.module.scss";
import { Logo } from "../../assets/svg/Logo";
export const MobileHint: FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.logo}>
        <Logo />
      </div>
      <div className={s.title}>Get the full experience on your Mac or PC</div>
      <div className={s.subtitle}>Designing on mobile is unavailable</div>
    </div>
  );
};
