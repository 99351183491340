import { FC, useEffect, useState } from "react";
import s from "./Index.module.scss";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import { useNavigate } from "react-router-dom";
import { checkTokenAndRefresh } from "../../features/functions/checkTokenAndRefresh";
import { MainLayout } from "../../widgets/MainLayout/MainLayout";
import { HeadProjectInfo } from "../../widgets/HeadProjectInfo/HeadProjectInfo";
import { AttributesTab } from "../../widgets/AttributesTab/AttributesTab";
import { OptionWidget } from "../../widgets/OptionWidget/OptionWidget";
import { useDispatch, useSelector } from "react-redux";
import { getActiveProject } from "../../redux/selectors/selectors";
import { changeShowModal } from "../../redux/actions";
import { OptionEditAttr } from "../../widgets/OptionEditAttr/OptionEditAttr";
import { StageWidget } from "../../widgets/StageWidget/StageWidget";
import { StageEditAttr } from "../../widgets/StageEditAttr/StageEditAttr";

export const Stages: FC = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(getActiveProject);
  const [editAttr, setEditAttr]: any = useState();
  return (
    <>
      <MainLayout>
        <div className={s.wrapper}>
          <HeadProjectInfo />
          {!editAttr ? (
            <div className={s.main}>
              <AttributesTab
                buttonName="Add new stage"
                createCollection={() =>
                  dispatch(
                    changeShowModal({
                      nameModal: "addStage",
                      stateModal: true,
                      otherParams: {
                        prjectId: projectId,
                        name: "",
                        path: "stages/",
                        id: undefined,
                      },
                    })
                  )
                }
              />
              <StageWidget projectId={projectId} setEditAttr={setEditAttr} />
            </div>
          ) : (
            <StageEditAttr setEditAttr={setEditAttr} editAttr={editAttr} />
          )}
        </div>
      </MainLayout>
      <MobileHint />
    </>
  );
};
