import { projectsListT } from "../../redux/reducers/types";

export const addOrUpdateAttr = ({
  projectsList,
  attrName,
  attrValue,
  attrId,
  projectId,
  type,
}: any) => {
  const newProjects: projectsListT[] = [];
  if (type === "update") {
    projectsList.map((project: projectsListT) => {
      if (project.id === projectId) {
        const newCurentOption: any = [];
        //@ts-ignore
        const allCurentOptions = project.attributesList[attrName];
        allCurentOptions.rows.map((attr: any) => {
          if (attr.id === attrId) {
            newCurentOption.push({
              ...attr,
              ...attrValue,
            });
          } else {
            newCurentOption.push(attr);
          }
        });
        newProjects.push({
          ...project,
          attributesList: {
            ...project.attributesList,
            [attrName]: {
              count: allCurentOptions.count,
              cache: true,
              rows: newCurentOption
            },
          },
        });
      } else {
        newProjects.push(project);
      }
    });
  } else if (type === "add") {
    projectsList.map((project: projectsListT) => {
      if (project.id === projectId) {
        //@ts-ignore
        const allCurentOptions = project.attributesList[attrName];
        newProjects.push({
          ...project,
          attributesList: {
            ...project.attributesList,
            [attrName]: {
              count: allCurentOptions.count + 1,
              cache: true,
              rows: [...allCurentOptions.rows, attrValue]
            },
          },
        });
      } else {
        newProjects.push(project);
      }
    });
  }

  return newProjects;
};
