import { FC } from "react";

export const BackArrow: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5448 5.35518C15.6007 5.41093 15.6451 5.47716 15.6754 5.55009C15.7057 5.62302 15.7213 5.70122 15.7213 5.78018C15.7213 5.85915 15.7057 5.93734 15.6754 6.01028C15.6451 6.08321 15.6007 6.14944 15.5448 6.20518L8.76981 12.9802L15.5458 19.7552C15.6585 19.8679 15.7219 20.0208 15.7219 20.1802C15.7219 20.3396 15.6585 20.4925 15.5458 20.6052C15.4331 20.7179 15.2802 20.7812 15.1208 20.7812C14.9614 20.7812 14.8085 20.7179 14.6958 20.6052L7.49581 13.4052C7.43987 13.3494 7.39549 13.2832 7.36521 13.2103C7.33492 13.1373 7.31934 13.0592 7.31934 12.9802C7.31934 12.9012 7.33492 12.823 7.36521 12.7501C7.39549 12.6772 7.43987 12.6109 7.49581 12.5552L14.6958 5.35518C14.7516 5.29925 14.8178 5.25487 14.8907 5.22458C14.9636 5.1943 15.0418 5.17871 15.1208 5.17871C15.1998 5.17871 15.278 5.1943 15.3509 5.22458C15.4238 5.25487 15.4891 5.29925 15.5448 5.35518Z"
        fill="#6C757D"
      />
    </svg>
  );
};
