import { addOrUpdateAttr } from "../../features/functions/addOrUpdateAttribute";
import { addOrUpdateItemAttribute } from "../../features/functions/addOrUpdateItemAttribute";
import { deleteAttributeInState } from "../../features/functions/deleteAttributeInState";
import { deleteItemsAttributeInState } from "../../features/functions/deleteItemsAttributeInState";
import { TYPE_REDUCER } from "../../utils/constants";
import { mergeArrays } from "../functions/mergeObj";
import {
  ModalStatusT,
  attributesNamesT,
  defaultAttributes,
  initialUiStateT,
  projectsListT,
} from "./types";

const initialState: initialUiStateT = {
  activeProject: undefined,
  activeTab: undefined,
  projectsList: [],
  renderJobs: {},
  categories: [],
  modalsList: {
    loader: {
      isShow: false,
    },
    addOption: {
      isShow: false,
    },
    addProject: {
      isShow: false,
    },
    addVariant: {
      isShow: false,
    },
    addStage: {
      isShow: false,
    },
    addCamera: {
      isShow: false,
    },
    deleteProject: {
      isShow: false,
    },
    addCollection: {
      isShow: false,
    },
    addCollectionItem: {
      isShow: false,
    },
    addProduct: {
      isShow: false,
    },
    addProductItem: {
      isShow: false,
    },
    addCategory: {
      isShow: false,
    },
    addEnvironment: {
      isShow: false,
    },
    addEnvironmentVariant: {
      isShow: false,
    },
    createRender: {
      isShow: false,
    },
  },
};

const reducer = (state = initialState, action: any): initialUiStateT => {
  switch (action.type) {
    case TYPE_REDUCER.SET_MODAL: {
      const { nameModal, stateModal, otherParams } = action.payload;
      const paramsModals: ModalStatusT = {
        isShow: stateModal,
        otherParams: otherParams,
      };

      return {
        ...state,
        modalsList: {
          ...state.modalsList,
          [nameModal]: paramsModals,
        },
      };
    }
    case TYPE_REDUCER.SET_ACTIVE_PROJECT: {
      const { id } = action.payload;

      return {
        ...state,
        activeProject: id,
      };
    }
    case TYPE_REDUCER.DELETE_PROJECT: {
      const { id } = action.payload;
      const newProjects: projectsListT[] = [];
      state.projectsList.map((item: any) => {
        if (item.id !== id) {
          newProjects.push(item);
        }
      });
      return {
        ...state,
        projectsList: newProjects,
      };
    }
    case TYPE_REDUCER.ADD_PROJECTS: {
      const { projects } = action.payload;
      const newProjects: projectsListT[] = [];
      projects.map((item: any) => {
        newProjects.push({
          id: item.id,
          name: item.name,
          attributesList: defaultAttributes,
        });
      });
      return {
        ...state,
        projectsList: newProjects,
      };
    }
    case TYPE_REDUCER.SET_CATEGORIES: {
      const { categories } = action.payload;

      return {
        ...state,
        categories,
      };
    }
    case TYPE_REDUCER.SET_JOBS: {
      const { jobs, productId } = action.payload;
      const oldJobs = state.renderJobs[productId]
        ? state.renderJobs[productId]
        : [];
      const newJobs = mergeArrays(oldJobs, jobs);

      return {
        ...state,
        renderJobs: {
          ...state.renderJobs,
          [productId]: newJobs,
        },
      };
    }
    case TYPE_REDUCER.ADD_NEW_PROJECT: {
      const { id, name } = action.payload;

      return {
        ...state,
        projectsList: [
          ...state.projectsList,
          {
            id,
            name,
            attributesList: defaultAttributes,
          },
        ],
      };
    }
    case TYPE_REDUCER.UPDATE_PROJECTS: {
      const { id, name } = action.payload;
      const newProjects: projectsListT[] = [];
      state.projectsList.map((project: projectsListT) => {
        if (project.id === id) {
          newProjects.push({
            id,
            name,
            attributesList: project.attributesList,
          });
        } else {
          newProjects.push(project);
        }
      });
      return {
        ...state,
        projectsList: newProjects,
      };
    }

    case TYPE_REDUCER.SET_ACTIVE_TAB: {
      const { id } = action.payload;

      return {
        ...state,
        activeTab: id,
      };
    }

    case TYPE_REDUCER.SET_ATTRIBUTE: {
      const { attrName, attrValues, projectId } = action.payload;

      const newProjects: projectsListT[] = [];
      state.projectsList.map((project: projectsListT) => {
        if (project.id === projectId) {
          newProjects.push({
            ...project,
            attributesList: {
              ...project.attributesList,
              [attrName]: attrValues,
            },
          });
        } else {
          newProjects.push(project);
        }
      });

      return {
        ...state,
        projectsList: newProjects,
      };
    }
    case TYPE_REDUCER.ADD_OR_UPDATE_ATTRIBUTE: {
      const { attrName, attrValue, attrId, type } = action.payload;
      const projectId = state.activeProject;
      const projectsList = state.projectsList;

      const newProjects = addOrUpdateAttr({
        projectsList,
        attrName,
        attrValue,
        projectId,
        attrId,
        type,
      });

      return {
        ...state,
        projectsList: newProjects,
      };
    }
    case TYPE_REDUCER.DELETE_ATTRIBUTE: {
      const { attrName, attrId } = action.payload;
      const projectId = state.activeProject;
      const projectsList = state.projectsList;

      const newProjects = deleteAttributeInState({
        projectsList,
        attrName,
        attrId,
        projectId,
      });

      return {
        ...state,
        projectsList: newProjects,
      };
    }

    case TYPE_REDUCER.SET_ITEMS_ATTRIBUTE: {
      type dataT = {
        attrName: attributesNamesT;
        attrValues: any;
        itemId: number;
        typeValue?: any;
      };
      const projectId = state.activeProject;
      const projectsList = state.projectsList;

      const { attrName, attrValues, itemId, typeValue }: dataT = action.payload;

      const newProjects: projectsListT[] = [];
      projectsList.map((project: projectsListT) => {
        if (project.id === projectId) {
          const actualAttrList = project.attributesList[attrName];
          const actualRows: any = [];
          actualAttrList.rows.map((i: any) => {
            if (i.id === itemId) {
              if (attrName === "products") {
                actualRows.push({
                  ...attrValues,
                  cache: true,
                });
              } else if (attrName === "options") {
                if (typeValue && typeValue === i.typeValue) {
                  actualRows.push({
                    ...i,
                    cache: true,
                    items: attrValues,
                  });
                } else {
                  actualRows.push(i);
                }
              } else {
                actualRows.push({
                  ...i,
                  cache: true,
                  items: attrValues,
                });
              }
            } else {
              actualRows.push(i);
            }
          });
          newProjects.push({
            ...project,
            attributesList: {
              ...project.attributesList,
              [attrName]: {
                ...actualAttrList,
                rows: actualRows,
              },
            },
          });
        } else {
          newProjects.push(project);
        }
      });

      return {
        ...state,
        projectsList: newProjects,
      };
    }

    case TYPE_REDUCER.DELETE_ITEM_ATTRIBUTE: {
      const { attrName, attrId, itemId } = action.payload;
      const projectId = state.activeProject;
      const projectsList = state.projectsList;

      const newProjects = deleteItemsAttributeInState({
        projectsList,
        attrName,
        attrId,
        itemId,
        projectId,
      });

      return {
        ...state,
        projectsList: newProjects,
      };
    }
    case TYPE_REDUCER.ADD_OR_UPDATE_ITEMS_ATTRIBUTE: {
      const { attrName, attrValue, attrId, itemId, type, typeValue } =
        action.payload;
      const projectId = state.activeProject;
      const projectsList = state.projectsList;

      const newProjects = addOrUpdateItemAttribute({
        projectsList,
        attrName,
        attrValue,
        projectId,
        attrId,
        itemId,
        type,
        typeValue,
      });

      return {
        ...state,
        projectsList: newProjects,
      };
    }
    default:
      return state;
  }
};

export default reducer;
