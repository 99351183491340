import axios from "axios";
import { apiRoutes, requestT } from "../../../utils/constants";
import { popUp } from "../../../features/popUp/PopUp";
import { getAuthToken } from "../../sessionStorage/getAuthToken";

type getAttributesApiT = {
  filter: string;
  url: string;
  request: requestT;
};
const getAttributesApi = async ({
  filter,
  url,
  request,
}: getAttributesApiT) => {
  const authInfo: any = getAuthToken();
  const data: any = JSON.parse(authInfo);

  return await axios[request](`${url}${filter}`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${data.token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response: any) => {
      if (response.data) {
        return response.data;
      } else {
        popUp({
          type: "failure",
          message: "Oops! There was a problem in getAttributesApi",
        });
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      const dataError = error.response.data.errors;
      if (dataError) {
        dataError.map((err: any) => {
          popUp({ type: "failure", message: err.message });
        });
      } else {
        popUp({ type: "failure", message: "Oh, something went wrong!" });
      }
    });
};

export default getAttributesApi;
