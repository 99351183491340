import { FC } from "react";
import s from "./CollectionWidget.module.scss";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeByName } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { changeShowModal } from "../../redux/actions";
import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import { toggleEnableAttribute } from "../../features/functions/toggleEnableAttribute";
import { deleteAttributeEvent } from "../../features/functions/deleteAttributeEvent";
import { duplicateAttributeEvent } from "../../features/functions/duplicateAttributeEvent";
import { optionsTag } from "../../utils/constants";

export const CollectionWidget: FC<any> = ({ projectId, setEditAttr }) => {
  const dispatch = useDispatch();
  const stageData = useSelector(getAttributeByName("collections"));
  return (
    <div className={`${s.wrapper} customScroll`}>
      {stageData && stageData.rows.length ? (
        <>
          {stageData.rows.map((item: any, index: number) => {
            return (
              <ItemStandart
                key={`collections_${index}`}
                id={item.id}
                eneble={item.enabled} 
                mainEvent={() => setEditAttr(item)}
                setEneble={() => {
                  toggleEnableAttribute({
                    id: item.id,
                    name: item.name,
                    nameAttr: "collections",
                    enabled: !item.enabled,
                    dispatch,
                  });
                }}
                title={item.name}
                deleteEvent={() => {
                  deleteAttributeEvent({
                    id: item.id,
                    name: item.name,
                    nameAttr: "collections",
                    enabled: !item.enabled,
                    dispatch,
                  });
                }}
                duplicateEvent={() => {
                  duplicateAttributeEvent({
                    name: item.name,
                    count: stageData.count,
                    prjectId: projectId,
                    attrId: item.id,
                    nameAttr: "collections",
                    url: optionsTag["collections"].create.url,
                    request: optionsTag["collections"].create.request,
                    dispatch,
                  });
                }}
                editEvent={() => {
                  dispatch(
                    changeShowModal({
                      nameModal: "addCollection",
                      stateModal: true,
                      otherParams: {
                        prjectId: projectId,
                        name: item.name,
                        id: item.id,
                      },
                    })
                  );
                }}
              />
            );
          })}
        </>
      ) : (
        <NoneData text="/// No collections available yet ///" />
      )}
    </div>
  );
};
