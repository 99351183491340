import { FC } from "react";

export const FileIcon: FC = () => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9001 24.7996C18.9001 25.0383 18.8053 25.2672 18.6365 25.436C18.4677 25.6048 18.2388 25.6996 18.0001 25.6996C17.7614 25.6996 17.5325 25.6048 17.3637 25.436C17.1949 25.2672 17.1001 25.0383 17.1001 24.7996V17.9746L15.0376 20.0401C14.9539 20.1238 14.8545 20.1902 14.7451 20.2355C14.6357 20.2808 14.5185 20.3042 14.4001 20.3042C14.2817 20.3042 14.1645 20.2808 14.0551 20.2355C13.9457 20.1902 13.8463 20.1238 13.7626 20.0401C13.6789 19.9564 13.6125 19.857 13.5672 19.7476C13.5219 19.6382 13.4985 19.521 13.4985 19.4026C13.4985 19.2842 13.5219 19.167 13.5672 19.0576C13.6125 18.9482 13.6789 18.8488 13.7626 18.7651L17.3626 15.1651C17.4462 15.0812 17.5456 15.0146 17.655 14.9692C17.7644 14.9238 17.8816 14.9004 18.0001 14.9004C18.1185 14.9004 18.2358 14.9238 18.3452 14.9692C18.4546 15.0146 18.554 15.0812 18.6376 15.1651L22.2376 18.7651C22.4067 18.9342 22.5017 19.1635 22.5017 19.4026C22.5017 19.6417 22.4067 19.871 22.2376 20.0401C22.0685 20.2092 21.8392 20.3042 21.6001 20.3042C21.361 20.3042 21.1317 20.2092 20.9626 20.0401L18.9001 17.9716V24.7996Z"
        fill="#343A40"
      />
      <path
        d="M28.8002 29.2996V12.1996L20.7002 4.09961H10.8002C9.84542 4.09961 8.92974 4.47889 8.25461 5.15402C7.57948 5.82916 7.2002 6.74483 7.2002 7.69961V29.2996C7.2002 30.2544 7.57948 31.1701 8.25461 31.8452C8.92974 32.5203 9.84542 32.8996 10.8002 32.8996H25.2002C26.155 32.8996 27.0707 32.5203 27.7458 31.8452C28.4209 31.1701 28.8002 30.2544 28.8002 29.2996ZM20.7002 9.49961C20.7002 10.2157 20.9847 10.9024 21.491 11.4088C21.9974 11.9151 22.6841 12.1996 23.4002 12.1996H27.0002V29.2996C27.0002 29.777 26.8106 30.2348 26.473 30.5724C26.1354 30.91 25.6776 31.0996 25.2002 31.0996H10.8002C10.3228 31.0996 9.86497 30.91 9.5274 30.5724C9.18984 30.2348 9.0002 29.777 9.0002 29.2996V7.69961C9.0002 7.22222 9.18984 6.76438 9.5274 6.42682C9.86497 6.08925 10.3228 5.89961 10.8002 5.89961H20.7002V9.49961Z"
        fill="#343A40"
      />
    </svg>
  );
};
