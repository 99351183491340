import React from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./ModalFrame.module.scss";
import { ModalStatusT, NameModals } from "../../../redux/reducers/types";
import { changeShowModal } from "../../../redux/actions";
import { getStateModal } from "../../../redux/selectors/selectors";
import { Loader } from "../Loader/Loader";
import { ModalAddProject } from "../ModalAddProject/ModalAddProject";
import { ModalAddOption } from "../ModalAddOption/ModalAddOption";
import { ModalAddVariant } from "../ModalAddVariant/ModalAddVariant";
import { ModalAddStage } from "../ModalAddStage/ModalAddStage";
import { ModalDeleteProject } from "../ModalDeleteProject/ModalDeleteProject";
import { ModalAddCamera } from "../ModalAddCamera/ModalAddCamera";
import { ModalAddCollection } from "../ModalAddCollection/ModalAddCollection";
import { ModalAddCollectionItem } from "../ModalAddCollectionItem/ModalAddCollectionItem";
import { ModalAddProduct } from "../ModalAddProduct/ModalAddProduct";
import { ModalAddProductItem } from "../ModalAddProductItem/ModalAddProductItem";
import { ModalAddCategory } from "../ModalAddCategory/ModalAddCategory";
import { ModalAddEnvironment } from "../ModalAddEnvironment/ModalAddEnvironment";
import { ModalAddEnvironmentVariant } from "../ModalAddEnvironmentVariant/ModalAddEnvironmentVariant";
import { ModalCreateRender } from "../ModalCreateRender/ModalCreateRender";

type stateModalT = {
  isShow: boolean;
  otherParams?: any;
};
export const ModalFrame = () => {
  const dispatch = useDispatch();

  const closeFunc = (name: NameModals) => {
    dispatch(changeShowModal({ nameModal: name, stateModal: false }));
  };

  const loader: stateModalT = useSelector(getStateModal("loader"));
  const addProject: stateModalT = useSelector(getStateModal("addProject"));
  const addOption: stateModalT = useSelector(getStateModal("addOption"));
  const addVariant: stateModalT = useSelector(getStateModal("addVariant"));
  const addStage: stateModalT = useSelector(getStateModal("addStage"));
  const addCategory: stateModalT = useSelector(getStateModal("addCategory"));
  const deleteProject: stateModalT = useSelector(
    getStateModal("deleteProject")
  );
  const addCamera: stateModalT = useSelector(getStateModal("addCamera"));
  const addCollection: stateModalT = useSelector(
    getStateModal("addCollection")
  );
  const addCollectionItem: stateModalT = useSelector(
    getStateModal("addCollectionItem")
  );
  const addProduct: stateModalT = useSelector(getStateModal("addProduct"));
  const addProductItem: stateModalT = useSelector(
    getStateModal("addProductItem")
  );
  const addEnvironment: stateModalT = useSelector(
    getStateModal("addEnvironment")
  );
  const addEnvironmentVariant: stateModalT = useSelector(
    getStateModal("addEnvironmentVariant")
  );

  const createRender: stateModalT = useSelector(getStateModal("createRender"));

  return (
    <>
      {loader["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 999 }}>
          <Loader />
        </div>
      )}
      {addProject["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddProject
            closeFunc={() => closeFunc("addProject")}
            data={addProject.otherParams}
          />
        </div>
      )}
      {addOption["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddOption
            closeFunc={() => closeFunc("addOption")}
            data={addOption.otherParams}
          />
        </div>
      )}
      {addVariant["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddVariant
            closeFunc={() => closeFunc("addVariant")}
            data={addVariant.otherParams}
          />
        </div>
      )}
      {addStage["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddStage
            closeFunc={() => closeFunc("addStage")}
            data={addStage.otherParams}
          />
        </div>
      )}{" "}
      {deleteProject["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalDeleteProject
            closeFunc={() => closeFunc("deleteProject")}
            data={deleteProject.otherParams}
          />
        </div>
      )}
      {addCamera["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddCamera
            closeFunc={() => closeFunc("addCamera")}
            data={addCamera.otherParams}
          />
        </div>
      )}
      {addCollection["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddCollection
            closeFunc={() => closeFunc("addCollection")}
            data={addCollection.otherParams}
          />
        </div>
      )}
      {addCollectionItem["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddCollectionItem
            closeFunc={() => closeFunc("addCollectionItem")}
            data={addCollectionItem.otherParams}
          />
        </div>
      )}
      {addProduct["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddProduct
            closeFunc={() => closeFunc("addProduct")}
            data={addProduct.otherParams}
          />
        </div>
      )}
      {addProductItem["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddProductItem
            closeFunc={() => closeFunc("addProductItem")}
            data={addProductItem.otherParams}
          />
        </div>
      )}
      {addCategory["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddCategory
            closeFunc={() => closeFunc("addCategory")}
            data={addCategory.otherParams}
          />
        </div>
      )}
      {addEnvironment["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddEnvironment
            closeFunc={() => closeFunc("addEnvironment")}
            data={addEnvironment.otherParams}
          />
        </div>
      )}
      {addEnvironmentVariant["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalAddEnvironmentVariant
            closeFunc={() => closeFunc("addEnvironmentVariant")}
            data={addEnvironmentVariant.otherParams}
          />
        </div>
      )}
      {createRender["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 99 }}>
          <ModalCreateRender
            closeFunc={() => closeFunc("createRender")}
            data={createRender.otherParams}
          />
        </div>
      )}
    </>
  );
};
