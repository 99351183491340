import { FC } from "react";
import s from "./StageWidget.module.scss";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { useDispatch, useSelector } from "react-redux";
import { getAttributeByName } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { changeShowModal } from "../../redux/actions";
import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import { toggleEnableAttribute } from "../../features/functions/toggleEnableAttribute";
import { deleteAttributeEvent } from "../../features/functions/deleteAttributeEvent";
import { duplicateAttributeEvent } from "../../features/functions/duplicateAttributeEvent";
import { optionsTag } from "../../utils/constants";
import { loadItemsProductInState } from "../../features/functions/loadItemsProductInState";

export const StageWidget: FC<any> = ({ projectId, setEditAttr }) => {
  const dispatch = useDispatch();
  const stageData = useSelector(getAttributeByName("stages"));
  return (
    <div className={`${s.wrapper} customScroll`}>
      {stageData && stageData.rows.length ? (
        <>
          {stageData.rows.map((item: any, index: number) => {
            return (
              <ItemStandart
                key={`stage_${index}`}
                id={item.id}
                eneble={item.enabled}
                mainEvent={() => setEditAttr(item)}
                setEneble={async () => {
                  await toggleEnableAttribute({
                    id: item.id,
                    name: item.name,
                    nameAttr: "stages",
                    enabled: !item.enabled,
                    dispatch,
                  });
                  loadItemsProductInState({ dispatch });
                }}
                title={item.name}
                deleteEvent={async () => {
                  await deleteAttributeEvent({
                    id: item.id,
                    name: item.name,
                    nameAttr: "stages",
                    enabled: !item.enabled,
                    dispatch,
                  });
                  loadItemsProductInState({ dispatch });
                }}
                duplicateEvent={() => {
                  duplicateAttributeEvent({
                    name: item.name,
                    count: stageData.count,
                    prjectId: projectId,
                    attrId: item.id,
                    nameAttr: "stages",
                    url: optionsTag["stages"].create.url,
                    request: optionsTag["stages"].create.request,
                    dispatch,
                  });
                }}
                editEvent={() => {
                  dispatch(
                    changeShowModal({
                      nameModal: "addStage",
                      stateModal: true,
                      otherParams: {
                        prjectId: projectId,
                        name: item.name,
                        id: item.id,
                        file: item.resource,
                        mount: item.mountPoint,
                        path: item.relativePath,
                      },
                    })
                  );
                }}
              />
            );
          })}
        </>
      ) : (
        <NoneData text="/// No stages available yet ///" />
      )}
    </div>
  );
};
