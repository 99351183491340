import { FC } from "react";

export const Active: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        d="M8.71144 1.21219C8.88555 1.03449 9.12313 0.933244 9.37189 0.930713C9.62066 0.928181 9.86024 1.02457 10.0379 1.19869C10.2156 1.3728 10.3169 1.61038 10.3194 1.85914C10.3219 2.10791 10.2256 2.34749 10.0514 2.52519L5.06044 8.76219C4.97472 8.85462 4.87122 8.92881 4.75615 8.98031C4.64108 9.0318 4.5168 9.05955 4.39076 9.06188C4.26471 9.06422 4.13949 9.04109 4.0226 8.99389C3.9057 8.94669 3.79953 8.87639 3.71044 8.78719L0.40444 5.48019C0.315523 5.39362 0.24469 5.29024 0.196052 5.17607C0.147415 5.06189 0.121943 4.9392 0.121115 4.8151C0.120286 4.691 0.144118 4.56797 0.191226 4.45316C0.238335 4.33835 0.307782 4.23404 0.395535 4.14629C0.483288 4.05853 0.587599 3.98909 0.702411 3.94198C0.817224 3.89487 0.94025 3.87104 1.06435 3.87186C1.18845 3.87269 1.31114 3.89817 1.42532 3.9468C1.53949 3.99544 1.64287 4.06627 1.72944 4.15519L4.34644 6.77119L8.68944 1.24019C8.69644 1.23019 8.70344 1.22019 8.71244 1.21219H8.71144Z"
        fill="#37CC8F"
      />
    </svg>
  );
};
