import axios from "axios";
import { apiRoutes } from "../../../utils/constants";
import { popUp } from "../../../features/popUp/PopUp";
import { getAuthToken } from "../../sessionStorage/getAuthToken";

const deleteProjectApi = async (id: number) => {
  const authInfo: any = getAuthToken();
  const data: any = JSON.parse(authInfo);

  return await axios[apiRoutes.DELETE_PROJECT.request](
    `${apiRoutes.DELETE_PROJECT.url}/${id}`,
    {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${data.token}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response: any) => {
      if (response.data) {
        return response.data;
      } else {
        popUp({
          type: "failure",
          message: "Oops! There was a problem in deleteProjectApi",
        });
      }
    })
    .catch((error: any) => {
      console.log("error: ", error);
      const dataError = error.response.data.errors;
      if (dataError) {
        dataError.map((err: any) => {
          popUp({ type: "failure", message: err.message });
        });
      } else {
        popUp({ type: "failure", message: "Oh, something went wrong!" });
      }
    });
};

export default deleteProjectApi;
