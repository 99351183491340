import { FC } from "react";

export const LogoWithText: FC = () => {
  return (
    <svg
      width="104"
      height="36"
      viewBox="0 0 104 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0093 26.9289C14.0093 24.2569 15.7669 22.0909 18.5093 22.0909C21.2517 22.0909 23.0093 24.2569 23.0093 26.9289C23.0093 29.6008 18.8196 36 18.5093 36C18.1989 36 14.0093 29.6008 14.0093 26.9289Z"
        fill="#2A8EFA"
      />
      <path
        d="M22.1912 9.07115C22.1912 11.7431 20.4335 13.9091 17.6912 13.9091C14.9488 13.9091 13.1912 11.7431 13.1912 9.07115C13.1912 6.39922 17.3808 0 17.6912 0C18.0015 0 22.1912 6.39922 22.1912 9.07115Z"
        fill="#FF4F79"
      />
      <path
        d="M27.029 22.0908C24.357 22.0908 22.191 20.3332 22.191 17.5908C22.191 14.8484 24.357 13.0908 27.029 13.0908C29.7009 13.0908 36.1001 17.2805 36.1001 17.5908C36.1001 17.9012 29.7009 22.0908 27.029 22.0908Z"
        fill="#37CC8F"
      />
      <path
        d="M9.17124 13.9092C11.8432 13.9092 14.0092 15.6668 14.0092 18.4092C14.0092 21.1516 11.8432 22.9092 9.17124 22.9092C6.49932 22.9092 0.100098 18.7195 0.100098 18.4092C0.100098 18.0988 6.49932 13.9092 9.17124 13.9092Z"
        fill="#EAC159"
      />
      <path
        d="M8.39755 5.85475C9.27638 6.754 9.43041 8.05437 8.55917 8.94588C7.68792 9.83738 6.4171 9.67977 5.53828 8.78051C4.65946 7.88125 3.88573 4.36555 3.98432 4.26466C4.08292 4.16377 7.51873 4.95549 8.39755 5.85475Z"
        fill="#2A8EFA"
      />
      <path
        d="M8.39755 29.3279C9.27638 28.4286 9.43041 27.1282 8.55917 26.2367C7.68792 25.3452 6.4171 25.5029 5.53828 26.4021C4.65946 27.3014 3.88573 30.8171 3.98432 30.918C4.08292 31.0188 7.51873 30.2271 8.39755 29.3279Z"
        fill="#37CC8F"
      />
      <path
        d="M26.9844 5.85475C26.1056 6.754 25.9515 8.05437 26.8228 8.94588C27.694 9.83738 28.9649 9.67977 29.8437 8.78051C30.7225 7.88125 31.4962 4.36555 31.3976 4.26466C31.299 4.16377 27.8632 4.95549 26.9844 5.85475Z"
        fill="#EAC159"
      />
      <path
        d="M26.9844 29.3279C26.1056 28.4286 25.9515 27.1282 26.8228 26.2367C27.694 25.3452 28.9649 25.5029 29.8437 26.4021C30.7225 27.3014 31.4962 30.8171 31.3976 30.918C31.299 31.0188 27.8632 30.2271 26.9844 29.3279Z"
        fill="#FF4F79"
      />
      <path
        d="M51.7186 14.5703H55.9631L52.0938 26.6317H47.1693L43.3 14.5703H47.6149L49.5378 22.6266H49.7957L51.7186 14.5703Z"
        fill="#343A40"
      />
      <path
        d="M61.6099 13.3043C61.0159 13.3043 60.5 13.0972 60.0622 12.6829C59.6245 12.2532 59.4056 11.7468 59.4056 11.1637C59.4056 10.5806 59.6245 10.0742 60.0622 9.6445C60.5 9.21483 61.0159 9 61.6099 9C62.204 9 62.7199 9.21483 63.1576 9.6445C63.5954 10.0742 63.8142 10.5806 63.8142 11.1637C63.8142 11.7468 63.5954 12.2532 63.1576 12.6829C62.7199 13.0972 62.204 13.3043 61.6099 13.3043ZM60.0622 26.6317V17.7928H58.1862V14.5703H63.8142V26.6317H60.0622Z"
        fill="#343A40"
      />
      <path
        d="M75.394 14.5703H79.6384L75.7692 26.6317H70.8447L66.9754 14.5703H71.2902L73.2131 22.6266H73.4711L75.394 14.5703Z"
        fill="#343A40"
      />
      <path
        d="M85.2853 13.3043C84.6912 13.3043 84.1753 13.0972 83.7376 12.6829C83.2999 12.2532 83.081 11.7468 83.081 11.1637C83.081 10.5806 83.2999 10.0742 83.7376 9.6445C84.1753 9.21483 84.6912 9 85.2853 9C85.8794 9 86.3953 9.21483 86.833 9.6445C87.2707 10.0742 87.4896 10.5806 87.4896 11.1637C87.4896 11.7468 87.2707 12.2532 86.833 12.6829C86.3953 13.0972 85.8794 13.3043 85.2853 13.3043ZM83.7376 26.6317V17.7928H81.8616V14.5703H87.4896V26.6317H83.7376Z"
        fill="#343A40"
      />
      <path
        d="M100.148 9.36829H103.9V26.6317H100.148V25.2967H99.9839C99.64 25.8184 99.1866 26.2327 98.6238 26.5396C98.061 26.8465 97.3888 27 96.6071 27C95.5909 27 94.6842 26.7391 93.8869 26.2174C93.1052 25.6803 92.4877 24.9284 92.0343 23.9616C91.5966 22.9949 91.3777 21.8747 91.3777 20.601C91.3621 19.312 91.5732 18.1918 92.0109 17.2404C92.4643 16.2737 93.0896 15.5294 93.8869 15.0077C94.6842 14.4706 95.5909 14.202 96.6071 14.202C97.3731 14.202 98.0297 14.3478 98.5769 14.6394C99.1241 14.9309 99.5696 15.3223 99.9135 15.8133H100.148V9.36829ZM97.6623 23.5473C98.4127 23.5473 99.0146 23.2788 99.468 22.7417C99.9214 22.2046 100.148 21.491 100.148 20.601C100.148 19.711 99.9214 18.9974 99.468 18.4604C99.0146 17.9233 98.4127 17.6547 97.6623 17.6547C96.9276 17.6547 96.3335 17.9233 95.8801 18.4604C95.4268 18.9974 95.2001 19.711 95.2001 20.601C95.2001 21.491 95.4268 22.2046 95.8801 22.7417C96.3335 23.2788 96.9276 23.5473 97.6623 23.5473Z"
        fill="#343A40"
      />
    </svg>
  );
};
