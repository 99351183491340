import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import {
  addOrUpdateItemsAttribute,
  changeShowModal,
} from "../../redux/actions";
import {
  attributeItemsNamesT,
  attributesNamesT,
} from "../../redux/reducers/types";
import { optionsTag } from "../../utils/constants";

type toggleEnableItemsAttributeT = {
  id: number;
  attrId: number;
  name: string;
  enabled: boolean;
  dispatch: any;
  nameAttr: attributesNamesT;
  tagName: attributesNamesT | attributeItemsNamesT;
  otherValues?: any;
};
export const toggleEnableItemsAttribute = async ({
  id,
  attrId,
  name,
  enabled,
  dispatch,
  nameAttr,
  tagName,
  otherValues = {},
}: toggleEnableItemsAttributeT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));

  const updatedOption = await updateAttributeApi({
    id: id,
    values: { name, enabled: enabled, ...otherValues },
    url: optionsTag[tagName].update.url,
    request: optionsTag[tagName].update.request,
    // typeSend: "formData",
  });
  if (updatedOption) {
    dispatch(
      addOrUpdateItemsAttribute({
        itemId: updatedOption.id,
        attrId: attrId,
        attrName: nameAttr,
        attrValue: updatedOption,
        type: "update",
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};
