import { FC } from "react";

export const Duplicate: FC = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4142 1.7998H7.11416C6.63677 1.7998 6.17893 1.98945 5.84137 2.32701C5.5038 2.66458 5.31416 3.12242 5.31416 3.5998C4.83677 3.5998 4.37893 3.78945 4.04137 4.12701C3.7038 4.46458 3.51416 4.92241 3.51416 5.3998V14.3998C3.51416 14.8772 3.7038 15.335 4.04137 15.6726C4.37893 16.0102 4.83677 16.1998 5.31416 16.1998H11.6142C12.0915 16.1998 12.5494 16.0102 12.887 15.6726C13.2245 15.335 13.4142 14.8772 13.4142 14.3998C13.8916 14.3998 14.3494 14.2102 14.687 13.8726C15.0245 13.535 15.2142 13.0772 15.2142 12.5998V3.5998C15.2142 3.12242 15.0245 2.66458 14.687 2.32701C14.3494 1.98945 13.8916 1.7998 13.4142 1.7998ZM13.4142 13.4998V5.3998C13.4142 4.92241 13.2245 4.46458 12.887 4.12701C12.5494 3.78945 12.0915 3.5998 11.6142 3.5998H6.21416C6.21416 3.36111 6.30898 3.13219 6.47776 2.96341C6.64655 2.79463 6.87547 2.6998 7.11416 2.6998H13.4142C13.6529 2.6998 13.8818 2.79463 14.0506 2.96341C14.2193 3.13219 14.3142 3.36111 14.3142 3.5998V12.5998C14.3142 12.8385 14.2193 13.0674 14.0506 13.2362C13.8818 13.405 13.6529 13.4998 13.4142 13.4998ZM4.41416 5.3998C4.41416 5.16111 4.50898 4.93219 4.67776 4.76341C4.84655 4.59463 5.07547 4.4998 5.31416 4.4998H11.6142C11.8529 4.4998 12.0818 4.59463 12.2506 4.76341C12.4193 4.93219 12.5142 5.16111 12.5142 5.3998V14.3998C12.5142 14.6385 12.4193 14.8674 12.2506 15.0362C12.0818 15.205 11.8529 15.2998 11.6142 15.2998H5.31416C5.07547 15.2998 4.84655 15.205 4.67776 15.0362C4.50898 14.8674 4.41416 14.6385 4.41416 14.3998V5.3998Z"
        fill="#7D7D8A"
      />
    </svg>
  );
};
