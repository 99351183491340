import { FC, useState } from "react";
import s from "./ItemStandart.module.scss";
import { ToggleBtn } from "../ToggleBtn/ToggleBtn";
import { IdPlace } from "../IdPlace/IdPlace";
import { EventBtn } from "../EventBtn/EventBtn";
import { ArrowBottom } from "../../assets/svg/ArrowBottom";

type ItemStandartT = {
  title: string;
  eneble: boolean;
  setEneble?: any;
  mainEvent?: any;
  img?: any;
  id?: any;
  editEvent?: any;
  duplicateEvent?: any;
  deleteEvent?: any;
  child?: any;
  dropdown?: any;
  type?: any;
  isMini?: boolean;
  subtitle?: any;
};

export const ItemStandart: FC<ItemStandartT> = ({ ...props }) => {
  const {
    img,
    title,
    id,
    editEvent,
    eneble,
    setEneble,
    duplicateEvent,
    deleteEvent,
    mainEvent,
    child,
    dropdown,
    isMini,
    subtitle,
    type,
  } = props;
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${s.wrapper} ${dropdown && s.children} ${
        eneble && s.eneble
      } ${mainEvent && s.pointer} ${isMini && s.isMini} ${open && s.active}`}
      onClick={() => {
        if (mainEvent) {
          mainEvent();
        }
      }}
    >
      <div
        className={s.item}
        onClick={() => {
          if (dropdown) {
            setOpen(!open);
          }
        }}
      >
        <div className={s.content}>
          {img && (
            <div className={s.img}>
              <img src={img} alt="" />
            </div>
          )}
          <div className={s.grouped}>
            <div className={s.title}>{title}</div>
            {type && <div className={`${s.type} ${s[type]}`}>{type}</div>}
            {subtitle && <div className={s.subtitle}>{subtitle}</div>}
            {id && (
              <div className={s.id}>
                <IdPlace id={id} />
              </div>
            )}
          </div>
          {setEneble && (
            <div className={s.toggle}>
              <ToggleBtn
                hideText={isMini}
                eneble={eneble}
                setEneble={setEneble}
              />
            </div>
          )}
        </div>
        <div className={s.events}>
          {editEvent && (
            <div className={s.edit}>
              <EventBtn type="edit" clickEvent={editEvent} />
            </div>
          )}
          {duplicateEvent && (
            <div className={s.duplicate}>
              <EventBtn type="duplicate" clickEvent={duplicateEvent} />
            </div>
          )}
          {deleteEvent && (
            <div className={s.delete}>
              <EventBtn type="delete" clickEvent={deleteEvent} />
            </div>
          )}
          <div className={s.dropdown}>
            <ArrowBottom />
          </div>
        </div>
      </div>
      {child && <div className={`${s.children} customScroll`}>{child()}</div>}
    </div>
  );
};
