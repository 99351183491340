import { apiRoutesValueT } from "../constants";

export type optionsRoutesNameT =
  | "GET_OPTIONS"
  | "UPDATE_OPTION"
  | "CREATE_OPTION"
  | "DELETE_OPTION"
  | "RETRIEVE_OPTION"
  | "GET_GEOMETRY_OPTIONS"
  | "UPDATE_GEOMETRY_OPTION"
  | "CREATE_GEOMETRY_OPTION"
  | "DELETE_GEOMETRY_OPTION"
  | "RETRIEVE_GEOMETRY_OPTION";
type optionsRoutesT = Record<optionsRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const optionsRoutes: optionsRoutesT = {
  GET_OPTIONS: {
    url: `${api}/options`,
    request: "get",
  },
  CREATE_OPTION: {
    url: `${api}/options`,
    request: "post",
  },
  UPDATE_OPTION: {
    url: `${api}/options`,
    request: "put",
  },
  DELETE_OPTION: {
    url: `${api}/options`,
    request: "delete",
  },
  RETRIEVE_OPTION: {
    url: `${api}/options`,
    request: "get",
  },

  GET_GEOMETRY_OPTIONS: {
    url: `${api}/geometryOptions`,
    request: "get",
  },
  CREATE_GEOMETRY_OPTION: {
    url: `${api}/geometryOptions`,
    request: "post",
  },
  UPDATE_GEOMETRY_OPTION: {
    url: `${api}/geometryOptions`,
    request: "put",
  },
  DELETE_GEOMETRY_OPTION: {
    url: `${api}/geometryOptions`,
    request: "delete",
  },
  RETRIEVE_GEOMETRY_OPTION: {
    url: `${api}/geometryOptions`,
    request: "get",
  },
};
