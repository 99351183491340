export function mergeArrays(oldArray: any, newArray: any) {
  const oldMap = new Map(oldArray.map((obj: any) => [obj.id, obj]));

  newArray.forEach((newObj: any) => {
    if (oldMap.has(newObj.id)) {
      oldMap.delete(newObj.id);
    }
    oldMap.set(newObj.id, newObj);
  });

  return Array.from(oldMap.values());
}
