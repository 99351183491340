import { FC, useEffect, useState } from "react";
import s from "./CollectionEditAttr.module.scss";
import { BackArrow } from "../../assets/svg/BackArrow";
import { ToggleBtn } from "../../shared/ToggleBtn/ToggleBtn";
import { toggleEnableAttribute } from "../../features/functions/toggleEnableAttribute";
import { useDispatch, useSelector } from "react-redux";
import { ButtonWrapper } from "../../shared/ButtonWrapper/ButtonWrapper";
import { getItemAttributeById } from "../../redux/selectors/selectors";
import { NoneData } from "../../shared/NoneData/NoneData";
import { changeShowModal, setItemsAttribute } from "../../redux/actions";
import getAttributesApi from "../../processes/api/attributes/getAttributesApi";
import { optionsTag } from "../../utils/constants";
import { ItemStandart } from "../../shared/ItemStandart/ItemStandart";
import { toggleEnableItemsAttribute } from "../../features/functions/toggleEnableItemsAttribute";
import { duplicateItemsAttributeEvent } from "../../features/functions/duplicateItemsAttributeEvent";
import { loadImageFromURL } from "../../features/functions/loadImageFromURL";
import { deleteItemsAttributeEvent } from "../../features/functions/deleteItemsAttributeEvent";

type CollectionEditAttrT = {
  setEditAttr: any;
  editAttr: any;
};

export const CollectionEditAttr: FC<CollectionEditAttrT> = ({ ...props }) => {
  const { setEditAttr, editAttr } = props;
  const dispatch = useDispatch();
  const optionData = useSelector(
    getItemAttributeById({ name: "collections", id: editAttr.id })
  );
  useEffect(() => {
    const getActualData = async () => {
      if (optionData && !optionData.cache) {
        const info = await getAttributesApi({
          url: optionsTag["cameras"].get.url,
          request: optionsTag["cameras"].get.request,
          filter: `?optionId=${optionData.id}&page=1&pageSize=500`,
        });
        if (info) {
          dispatch(
            setItemsAttribute({
              itemId: optionData.id,
              attrName: "collections",
              attrValues: info.rows,
            })
          );
        }
      }
    };
    getActualData();
  }, []);

  if (!optionData) {
    setEditAttr(false);
  }

  return (
    <>
      {optionData && (
        <div className={s.edit}>
          <div className={s.head}>
            <div className={s.back} onClick={() => setEditAttr(false)}>
              <BackArrow />
              Back to collections list
            </div>
            <div className={s.content}>
              <div className={s.name}>{optionData.name}</div>
              <div className={s.toggle}>
                <ToggleBtn
                  eneble={optionData.enabled}
                  setEneble={() => {
                    toggleEnableAttribute({
                      id: optionData.id,
                      name: optionData.name,
                      nameAttr: "collections",
                      enabled: !optionData.enabled,
                      dispatch,
                    });
                  }}
                />
              </div>
            </div>
            <div className={s.add}>
              <ButtonWrapper
                text="Add new camera"
                clickEvent={() => {
                  dispatch(
                    changeShowModal({
                      nameModal: "addCamera",
                      stateModal: true,
                      otherParams: {
                        attrId: optionData.id,
                        name: "",
                        itemId: "",
                        position: "",
                        rotation: "",
                        FOV: "",
                        steps: "",
                      },
                    })
                  );
                }}
                type="outlane"
                icon={true}
              />
            </div>
          </div>
          <div className={s.items}>
            {optionData.items.length >= 1 ? (
              <>
                {optionData.items.map((item: any, index: number) => {
                  console.log("item: ", item);
                  return (
                    <ItemStandart
                      key={`item_camera_attr_${index}`}
                      eneble={item.enabled}
                      img={item.image}
                      id={item.id}
                      setEneble={() => {
                        toggleEnableItemsAttribute({
                          id: item.id,
                          attrId: optionData.id,
                          name: item.name,
                          nameAttr: "collections",
                          tagName: "cameras",
                          enabled: !item.enabled,
                          dispatch,
                        });
                      }}
                      title={item.name}
                      deleteEvent={() => {
                        deleteItemsAttributeEvent({
                          id: optionData.id,
                          itemId: item.id,
                          attrName: "collections",
                          attrTag: "cameras",
                          dispatch,
                        });
                      }}
                      duplicateEvent={async () => {
                        // const loadPrevImg = async () => {
                        //   const file = await loadImageFromURL(linkImage);
                        //   setValue(file);
                        // };
                        // loadPrevImg();

                        duplicateItemsAttributeEvent({
                          name: item.name,
                          itemId: item.id,
                          attrId: optionData.id,
                          count: optionData.items.length,
                          otherValues: {
                            stageId: item.stageId,
                            fov: item.fov,
                            rotationSteps: item.rotationSteps,
                            position: item.position,
                            rotation: item.rotation,
                          },
                          nameAttr: "collections",
                          typeSend: "json",
                          url: optionsTag["cameras"].create.url,
                          request: optionsTag["cameras"].create.request,
                          dispatch,
                        });
                      }}
                      editEvent={() => {
                        dispatch(
                          changeShowModal({
                            nameModal: "addCollectionItem",
                            stateModal: true,
                            otherParams: {
                              attrId: item.stageId,
                              name: item.name,
                              itemId: item.id,
                            },
                          })
                        );
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <NoneData text="/// No options & stages available yet ///" />
            )}
          </div>
        </div>
      )}
    </>
  );
};
