import updateAttributeApi from "../../processes/api/attributes/updateAttributeApi";
import { addOrUpdateAttribute, changeShowModal } from "../../redux/actions";
import { attributesNamesT } from "../../redux/reducers/types";
import { optionsTag } from "../../utils/constants";

type toggleEnableAttributeT = {
  id: number;
  name: string;
  mount?: any;
  enabled: boolean;
  apiName?: attributesNamesT;
  dispatch: any;
  nameAttr: attributesNamesT;
};

export const toggleEnableAttribute = async ({
  id,
  name,
  enabled,
  mount,
  dispatch,
  nameAttr,
}: toggleEnableAttributeT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
  const values: any = { name, enabled: enabled };
  if (mount) {
    values.mountPoint = mount;
  }
  const updatedOption = await updateAttributeApi({
    id: id,
    values: values,
    url: optionsTag[nameAttr].update.url,
    request: optionsTag[nameAttr].update.request,
  });
  if (updatedOption) {
    dispatch(
      addOrUpdateAttribute({
        attrId: updatedOption.id,
        attrName: nameAttr,
        attrValue: updatedOption,
        type: "update",
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};

export const toggleEnableAttributeOption = async ({
  id,
  name,
  enabled,
  mount,
  dispatch,
  nameAttr,
  apiName
}: toggleEnableAttributeT) => {
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
  const values: any = { name, enabled: enabled };
  if (mount) {
    values.mountPoint = mount;
  }
  const updatedOption = await updateAttributeApi({
    id: id,
    values: values,
    url: optionsTag[apiName!].update.url,
    request: optionsTag[apiName!].update.request,
  });
  if (updatedOption) {
    dispatch(
      addOrUpdateAttribute({
        attrId: updatedOption.id,
        attrName: nameAttr,
        attrValue: updatedOption,
        type: "update",
      })
    );
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  }
};