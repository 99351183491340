export const loadImageFromURL = async (fileUrl: any) => {
    // const fileNamePrev = fileUrl.substring(fileUrl.lastIndexOf("/") + 1); // получаем имя файла из URL
    const fileExtension = fileUrl.split('.').pop(); // получаем расширение файла
    const fileName = `file.${fileExtension}`; // создаем имя файла с расширением
    return await fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], fileName);
        return file
      })
      .catch((error) => console.error("Error loading file:", error));
  };