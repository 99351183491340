import { apiRoutesValueT } from "../constants";

export type camerasRoutesNameT =
  | "GET_CAMERAS"
  | "UPDATE_CAMERA"
  | "CREATE_CAMERA"
  | "DELETE_CAMERA"
  | "RETRIEVE_CAMERA";
type camerasRoutesT = Record<camerasRoutesNameT, apiRoutesValueT>;

const api = process.env.REACT_APP_API_URL;

export const camerasRoutes: camerasRoutesT = {
  GET_CAMERAS: {
    url: `${api}/cameras`,
    request: "get",
  },
  CREATE_CAMERA: {
    url: `${api}/cameras`,
    request: "post",
  },
  UPDATE_CAMERA: {
    url: `${api}/cameras`,
    request: "put",
  },
  DELETE_CAMERA: {
    url: `${api}/cameras`,
    request: "delete",
  },
  RETRIEVE_CAMERA: {
    url: `${api}/cameras`,
    request: "get",
  },
};
