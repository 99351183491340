import { FC, useState, useEffect, useRef } from "react";
import s from "./ButtonWrapper.module.scss";
import { Plus } from "../../assets/svg/Plus";
import { ArrowBottom } from "../../assets/svg/ArrowBottom";

type ButtonWrapperT = {
  text: string;
  clickEvent: any;
  type: "primary" | "outlane" | "transparent" | "warning";
  icon?: boolean;
  selectList?: any;
};
export const ButtonWrapper: FC<ButtonWrapperT> = ({ ...props }) => {
  const { clickEvent, text, icon = false, type, selectList } = props;
  let styleWrapper = `${s.btn} ${s[type]}`;
  const [open, setOpen] = useState(false);
  const wrapperRef: any = useRef(null);
  function closeFunc() {
    setOpen(false);
  }

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);
  return (
    <div
      className={`${styleWrapper} ${open && s.active}`}
      ref={wrapperRef}
      onClick={() => {
        if (selectList) {
          setOpen(!open);
        } else {
          clickEvent();
        }
      }}
    >
      {icon && <Plus />}
      <span>{text}</span>
      {!!selectList && (
        <>
          <div className={s.arrow}>
            <ArrowBottom />
          </div>
          <div className={`${s.content}`}>
            {selectList.map((item: any, index: number) => {
              return (
                <div
                  className={`${s.item} ${item.disable && s.disable}`}
                  key={`selectlist_${index}`}
                  onClick={() => {
                    clickEvent(item.value);
                  }}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
