import getAttributesApi from "../../processes/api/attributes/getAttributesApi";
import { addOrUpdateAttribute, setAttribute } from "../../redux/actions";
import { rowsT } from "../../redux/reducers/types";
import { optionsTag } from "../../utils/constants";

type loadItemProductInStateT = {
  dispatch: any;
  productId: any;
};
export const loadItemProductInState = async ({
  dispatch,
  productId,
}: loadItemProductInStateT) => {
  const pathname = window.location.pathname;
  const parts: any = pathname.split("/");
  const idProject = parts[1];
  const info = await getAttributesApi({
    url: optionsTag["products"].get.url,
    request: optionsTag["products"].get.request,
    filter: `?projectId=${idProject}&productId=${productId}&page=1&pageSize=500`,
  });
  if (info) {
    const updatedRows: rowsT[] = [];
    info.map((row: any) => {
      updatedRows.push({
        ...row,
        cache: true,
      });
    });
    const activeProduct = info.find((item: any) => item.id === productId);
    dispatch(
      addOrUpdateAttribute({
        attrId: productId,
        attrName: "products",
        attrValue: {
          ...activeProduct,
          cache: true,
        },
        type: "update",
      })
    );
  }
};
