import tokenRefreshApi from "../../processes/api/login/tokenRefreshApi";
import { deleteAuthToken } from "../../processes/sessionStorage/deleteAuthToken";
import { getAuthToken } from "../../processes/sessionStorage/getAuthToken";
import { setAuthToken } from "../../processes/sessionStorage/setAuthToken";
import { addSecondsToCurrentTime } from "./addSecondsToCurrentTime";
import { secondsToMilis } from "./secondsToMilis";


export const checkTokenAndRefresh = async () => {
  const authInfo: any = getAuthToken();

  if (authInfo) {
    const data: any = JSON.parse(authInfo);
    const tokenLifeTime = data.tokenLifeTime;
    const refreshTokenLifeTime = data.refreshTokenLifeTime;

    const currentTimestamp = new Date().getTime();

    // Проверка времени жизни refreshToken
    if (refreshTokenLifeTime > currentTimestamp) {
      console.log("RefreshToken действителен.");
      // Проверка времени жизни токена
      if (tokenLifeTime > currentTimestamp) {
        console.log("Токен действителен.");
      } else {
        console.log("Токен истек.");
        const newTokenData = await tokenRefreshApi(data.refreshToken);
        const newToken = newTokenData.data.authData.token;
        const newTokenLife = newTokenData.data.authData.tokenExpiresIn - 30;
        const newTokenLifeTime = addSecondsToCurrentTime(
          secondsToMilis(newTokenLife)
        );

        const newAuthInfo = {
          ...data,
          token: newToken,
          tokenLifeTime: newTokenLifeTime,
        };
        setAuthToken(JSON.stringify(newAuthInfo));
      }
    } else {
      console.log("RefreshToken истек.");
      deleteAuthToken();
      window.location.href = "/login";
    }
  }
};
