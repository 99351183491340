import { FC } from "react";

export const ArrowBottom: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92337 6.34164C2.96518 6.29968 3.01485 6.2664 3.06955 6.24369C3.12425 6.22097 3.18289 6.20928 3.24212 6.20928C3.30135 6.20928 3.35999 6.22097 3.41469 6.24369C3.46939 6.2664 3.51906 6.29968 3.56087 6.34164L8.64212 11.4229L13.7234 6.34089C13.8079 6.25635 13.9226 6.20886 14.0421 6.20886C14.1617 6.20886 14.2763 6.25635 14.3609 6.34089C14.4454 6.42543 14.4929 6.54008 14.4929 6.65964C14.4929 6.77919 14.4454 6.89385 14.3609 6.97839L8.96087 12.3784C8.91906 12.4203 8.86939 12.4536 8.81469 12.4763C8.75999 12.4991 8.70135 12.5107 8.64212 12.5107C8.58289 12.5107 8.52425 12.4991 8.46955 12.4763C8.41485 12.4536 8.36518 12.4203 8.32337 12.3784L2.92337 6.97839C2.88142 6.93658 2.84813 6.8869 2.82542 6.83221C2.80271 6.77751 2.79102 6.71886 2.79102 6.65964C2.79102 6.60041 2.80271 6.54177 2.82542 6.48707C2.84813 6.43237 2.88142 6.38344 2.92337 6.34164Z"
        fill="#343A40"
      />
    </svg>
  );
};
