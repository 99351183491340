import { useEffect } from "react";
import getProjectsApi from "../../../processes/api/projects/getProjectsApi";
import { getAuthToken } from "../../../processes/sessionStorage/getAuthToken";
import { setAllProjects } from "../../../redux/actions";
import { useSelector } from "react-redux";
import { getAllProjects } from "../../../redux/selectors/selectors";

export const useLoadProjects = (dispatch: any) => {
  const projectList: any[] = useSelector(getAllProjects);

  useEffect(() => {
    async function getProjects() {
      //@ts-ignore
      if (projectList && projectList.length === 0 && !window.loadProjects) {
        //@ts-ignore
        window.loadProjects = true;
        const projects = await getProjectsApi();
        //@ts-ignore
        dispatch(setAllProjects({ projects: projects.rows }));
      }
    }

    const authInfo: any = getAuthToken();

    setTimeout(() => {
      if (authInfo) {
        getProjects();
      }
    }, 0);
  }, [projectList, dispatch]);
};
